import React from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

export default class Editor extends React.Component {
	constructor(props) {
		super(props)
		this.state = { editorHtml: '', theme: 'snow' }
		this.handleChange = this.handleChange.bind(this)
	}

	handleChange(html) {
		this.setState({ editorHtml: html }, () => {
			this.props.setFieldsValue(this.props.field, this.state.editorHtml);
		});
	}

	handleThemeChange(newTheme) {
		if (newTheme === "core") newTheme = null;
		this.setState({ theme: newTheme })
	}

	render() {
		return (
			<ReactQuill
				style={{ height: '200px', width: '100%'}}
				theme={this.state.theme}
				ref={this.props.theRef}
				onChange={this.handleChange}
				value={this.state.editorHtml}
				modules={Editor.modules}
				formats={Editor.formats}
				placeholder={this.props.placeholder}
			/>
		)
	}
}

Editor.modules = {
	toolbar: [
		[{ 'header': '1' }, { 'header': '2' }],
		['bold', 'italic', 'underline', 'strike', 'blockquote'],
		[{ 'list': 'ordered' }, { 'list': 'bullet' },
		{ 'indent': '-1' }, { 'indent': '+1' }],
		['link'],
	],
	clipboard: {
		matchVisual: false,
	}
}

Editor.formats = [
	'header', 'size',
	'bold', 'italic', 'underline', 'strike', 'blockquote',
	'list', 'bullet', 'indent',
	'link'
]
