import React from 'react';
import { Divider, notification, Table, Modal, Button, Drawer, Tag, Popover, Switch } from "antd";

import config from '../config';
import Slider from '../modals/slider';

export default class SlideTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			confirmLoading: false
		}
	}

	getForm = (childForm) => {
		this.setState({
			childForm: childForm
		});
	}

	showModal = (id, slider) => {
		this.setState({
			id: id,
			modalTitle: "Edit a Slider",
			modalContent: <Slider id={id} slider={slider} handleOk={this.handleOk} setForm={this.getForm} />
		}, () => {
			this.setState({ visible: true });
		});
	};

	handleOk = () => {
		const { childForm } = this.state;
		this.setState({
			confirmLoading: true
		});
		childForm.validateFields((err, values) => {
			if (err) {
				this.setState({
					confirmLoading: false,
					visible: true
				});
				return;
			} else {
				var payload = {
					sid: this.state.id,
					name: values.name,
					tag: values.tag,
					url: values.url,
					title: values.title,
					color: values.colorcode,
					image: values.imgpath,
					productId: values.product,
					buttonText: values.btext,
					productImage: values.proimgpath
				}
				fetch(`${config.api.url}/slider/put`, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payload),
				}).then((response) => response.json()).then((data) => {
					if (data.suc) {
						this.setState({
							confirmLoading: false,
							visible: false
						});
						notification['success']({
							message: data.msg
						});
						this.props.fetch();
					} else {
						this.setState({
							confirmLoading: false,
							visible: false
						});
						notification['error']({
							message: data.msg
						});
					}
				}).catch((error) => {
					console.error('Error:', error);
				});
			}
		});
	};

	handleCancel = () => {
		this.setState({
			visible: false
		});
	};

	onClose = () => {
		this.setState({
			visible: false,
		});
	};

	onChange = (id, checked) => {
 if (checked) {
		fetch(`${config.api.url}/slider/featured?sid=${id}&val=1`,
		{
			method: 'put'
		}).then((response) => response.json()).then((data) => {
			if (data.suc) {
				this.props.fetch();
				notification['success']({
					message: data.msg
				});
			} else {
				notification['error']({
					message: data.msg
				});
			}
		}).catch((error) => {
			console.error('Error:', error);
		});
	}
	if (!checked) {
		fetch(`${config.api.url}/slider/featured?sid=${id}&val=0`,
		{
			method: 'put'
		}).then((response) => response.json()).then((data) => {
			if (data.suc) {
				this.props.fetch();
				notification['success']({
					message: data.msg
				});
			} else {
				notification['error']({
					message: data.msg
				});
			}
		}).catch((error) => {
			console.error('Error:', error);
		});
	}
}

	delSlide(id) {
		fetch(`${config.api.url}/slider/dlt?sid=${id}`,
			{
				method: 'delete'
			}).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.props.fetch();
					notification['success']({
						message: data.msg
					});
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
	}

	slider = [
		{
			title: "Product",
			dataIndex: "proCode",
			key: "proCode",
			align: "center",
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			align: "center",
		},
		{
			title: "Tag",
			dataIndex: "tagName",
			key: "url",
			align: "center",
		},
		{
			title: "Color",
			dataIndex: "color",
			key: "color",
			align: "center",
			render: (text, record) => (
				<Tag style={{minHeight: 10, minWidth: 20}} color={text}></Tag>
			)
		},
		{
			title: "Button",
			dataIndex: "buttonText",
			key: "buttonText",
			align: "center",
		},
		{
			title: "Title",
			dataIndex: "title",
			key: "title",
			align: "center",
			render: (text, record) => (text && (
				<Popover content={text}>
					<a>View</a>
				</Popover>)
			)
		},
		{
			title: "Url",
			dataIndex: "url",
			key: "url",
			align: "center",
			render: (text, record) => (text && (
				<Popover content={text}>
					<a>View</a>
				</Popover>)
			)
		},
		{
			title: "Image",
			dataIndex: "image",
			key: "image",
			align: "center",
			render: (text, record) => (text && (
				<a target='_blank' href={text}>View Image</a>)
			)
		},
		{
			title: "Featured",
			dataIndex: "featured",
			key: "featured",
			align: "center",
			render: (text, record) => (<>
				{ text === '1' ? (
					<Switch defaultChecked onChange={(e)=>{this.onChange(record.id, e)}} />
				) : (
					<Switch onChange={(e)=>{this.onChange(record.id, e)}} />
				)}
			</>)
		},
		{
			title: "Actions",
			key: "actions",
			align: "center",
			render: (text, record) => (
				<span>
					<>
						<a onClick={() => { this.showModal(record.id, record) }}>edit</a>
						<Divider type="vertical" />
						<a onClick={() => { this.delSlide(record.id) }}>delete</a>
					</>
				</span>
			)
		}
	];

	render = () => {
		const { visible, confirmLoading, modalContent } = this.state;
		return (
			<>
				<Drawer
					title={<b>Edit a Slider</b>}
					width={"30vw"}
					onClose={this.onClose}
					visible={visible}
					style={{ borderRadius: "10px" }}
				>
					{modalContent}
				</Drawer>
				<Table rowKey={record => record.id} bordered size="middle" columns={this.slider} dataSource={this.props.sliders} />
			</>
		);
	}
}
