import React from 'react';
import { Divider, notification, Table, Modal, Button, Drawer, Popconfirm, Popover, Switch } from "antd";

import config from '../config';
import Category from '../modals/category';

export default class CatTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			confirmLoading: false
		}
	}

	getForm = (childForm) => {
		this.setState({
			childForm: childForm
		});
	}

	getState = (childState) => {
		this.setState({
			childState: childState
		});
	}

	getCats = (childCats) => {
		this.setState({
			childCats: childCats
		});
	}

	showModal = (id, name, slug, parent, title, description) => {
		this.setState({
			id: id,
			modalTitle: "Edit a Category",
			modalContent: <Category id={id} name={name} slug={slug} parent={parent} title={title} description={description} setState={this.getState} handleOk={this.handleOk} setCats={this.getCats} setForm={this.getForm} />
		}, () => {
			this.setState({ visible: true });
		});
	};

	handleOk = () => {
		const { childForm } = this.state;
		this.setState({
			confirmLoading: true
		});
		childForm.validateFields((err, values) => {
			if (err) {
				this.setState({
					confirmLoading: false,
					visible: true
				});
				return;
			} else {
				var payload = {
					cid: this.state.id,
					name: values.name,
					slug: values.slug,
					parent: values.parent,
					title: values.title,
					main: values.img1path,
					featured: values.img2path,
					description: values.description
				}
				fetch(`${config.api.url}/category/put`, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payload),
				}).then((response) => response.json()).then((data) => {
					if (data.suc) {
						this.setState({
							confirmLoading: false,
							visible: false
						});
						notification['success']({
							message: data.msg
						});
						childForm.resetFields();
						this.state.childCats();
						this.state.childState.setState({fileList1: [], fileList2: []});
						this.props.fetch();
					} else {
						this.setState({
							confirmLoading: false,
							visible: false
						});
						notification['error']({
							message: data.msg
						});
					}
				}).catch((error) => {
					console.error('Error:', error);
				});
			}
		});
	};

	handleCancel = () => {
		this.setState({
			visible: false
		});
	};

	onClose = () => {
		this.setState({
			visible: false,
		});
	};

	onChange = (id, checked) => {
		if (checked) {
			fetch(`${config.api.url}/category/featured?cid=${id}&val=1`,
			{
				method: 'put'
			}).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.props.fetch();
					notification['success']({
						message: data.msg
					});
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
		}
		if (!checked) {
			fetch(`${config.api.url}/category/featured?cid=${id}&val=0`,
			{
				method: 'put'
			}).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.props.fetch();
					notification['success']({
						message: data.msg
					});
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
		}
	}

	delCat(id) {
		fetch(`${config.api.url}/category/dlt?cid=${id}`,
			{
				method: 'delete'
			}).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.props.fetch();
					notification['success']({
						message: data.msg
					});
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
	}

	category = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			align: "left",
			defaultSortOrder: 'ascend',
			sorter: (a, b) => a.name.localeCompare(b.name),
			sortDirections: ['descend', 'ascend'],
		},
		{
			title: "Slug",
			dataIndex: "slug",
			key: "slug",
			align: "center"
		},
		{
			title: "Title",
			dataIndex: "title",
			key: "title",
			align: "center",
			render: (text, record) => (text && (
				<Popover content={text}>
					<a>View</a>
				</Popover>)
			)
		},
		{
			title: "Description",
			dataIndex: "description",
			key: "description",
			align: "center",
			render: (text, record) => (text && (
				<Popover content={text}>
					<a>View</a>
				</Popover>)
			)
		},
		{
			title: "Main",
			dataIndex: "main",
			key: "main",
			align: "center",
			render: (text, record) => (text && (
				<a target='_blank' href={config.api.url + text}>View Image</a>)
			)
		},
		{
			title: "Featured",
			dataIndex: "featured",
			key: "featured",
			align: "center",
			render: (text, record) => (text && (
				<a target='_blank' href={config.api.url + text}>View Image</a>)
			)
		},
		{
			title: "Count",
			dataIndex: "count",
			key: "count",
			align: "center",
			render: (text, record) => (
				<a onClick={() => { this.props.history.push({ pathname: '/products', state: { category: record.id } }) }}>{text}</a>
			)
		},
		{
			title: "Featured",
			dataIndex: "homeFeatured",
			key: "homeFeatured",
			align: "center",
			render: (text, record) => (<>
				{ text === 1 ? (
					<Switch defaultChecked onChange={(e)=>{this.onChange(record.id, e)}} />
				) : (
					<Switch onChange={(e)=>{this.onChange(record.id, e)}} />
				)}
			</>)
		},
		{
			title: "Actions",
			key: "actions",
			align: "center",
			render: (text, record) => (
				<span>
					{(record.id !== 1 && record.id !== 2) && (
						<>
							<a onClick={() => { this.showModal(record.id, record.name, record.slug, record.parent, record.title, record.description) }}>edit</a>
							<Divider type="vertical" />
							<Popconfirm
								title="Are you sure, you want to delete this Category?"
								onConfirm={() => { this.delCat(record.id) }}
								okText="Yes"
								cancelText="No">
								<a>delete</a>
							</Popconfirm>
						</>
					)}
				</span>
			)
		}
	];

	render = () => {
		const { visible, confirmLoading, modalContent } = this.state;
		return (
			<>
				<Drawer
					title={<b>Edit a Category</b>}
					width={"30vw"}
					onClose={this.onClose}
					visible={visible}
					style={{ borderRadius: "10px" }}
				>
					{modalContent}
				</Drawer>
				<Table id="someTable" defaultExpandAllRows={true} expandRowByClick={true} rowKey={record => record.id} bordered size="middle" columns={this.category} dataSource={this.props.categories} />
			</>
		);
	}
}
