import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import {
	Row,
	Col,
	Tabs,
	Card,
	notification,
	Modal,
	Button,
	Dropdown,
	Avatar,
	Menu,
	Drawer,
} from 'antd';

import AdTable from '../tables/admin';
import Admin from '../modals/admin';
import config from '../config';

const { TabPane } = Tabs;

export default class Settings extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			admins: [],
			visible: false,
			confirmLoading: false
		}
	}

	componentDidMount = () => {
		this.fetchAdmins();
	}

	fetchAdmins = () => {
		fetch(`${config.api.url}/admin/get?all=true`).then((response) => response.json()).then((data) => {
			if (data.suc) {
				this.setState({ admins: data.dat.admins });
			} else {
				notification['error']({
					message: data.msg
				});
			}
		}).catch((error) => {
			console.error('Error:', error);
		});
	}

	getForm = (childForm) => {
		this.setState({
			childForm: childForm
		});
	}

	showModal = () => {
		this.setState({
			modalTitle: "Add a User",
			modalContent: <Admin confirmLoading={this.state.confirmLoading} handleOk={this.handleOk} setForm={this.getForm} />
		}, () => {
			this.setState({ visible: true });
		});
	};

	handleOk = () => {
		const { childForm } = this.state;
		this.setState({
			confirmLoading: true
		});
		childForm.validateFields((err, values) => {
			if (err) {
				this.setState({
					confirmLoading: false,
					visible: true
				});
				return;
			} else {
				var payload = {
					name: values.name,
					email: values.email,
					role: values.role,
					password: values.password,
					proPer: JSON.stringify(values.proPer),
					catPer: JSON.stringify(values.catPer)
				}
				fetch(`${config.api.url}/admin/pst`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payload),
				}).then((response) => response.json()).then((data) => {
					if (data.suc) {
						this.setState({
							confirmLoading: false,
							visible: false
						});
						notification['success']({
							message: data.msg
						});
						this.fetchAdmins();
					} else {
						this.setState({
							confirmLoading: false,
							visible: false
						});
						notification['error']({
							message: data.msg
						});
					}
				}).catch((error) => {
					console.error('Error:', error);
				});
			}
		});
	};

	handleCancel = () => {
		this.setState({
			visible: false
		});
	};

	logout = () => {
		localStorage.clear();
		this.props.history.push("/login");
	}

	render = () => {
		const { visible, confirmLoading, modalContent } = this.state;
		return <>
			<Drawer
				title={this.state.modalTitle}
				visible={visible}
				onOk={this.handleOk}
				onClose={this.handleCancel}
				width={"30vw"}
				style={{ borderRadius: "10px" }}
			>
				{modalContent}
			</Drawer>
			<Row type="flex" justify="space-between">
				<Col>
					<h1 style={{ fontWeight: "bold" }}>Manage Settings</h1>
				</Col>
				<Col>
					<Dropdown overlay={
						<Menu>
							<Menu.Item key="0" onClick={this.logout}>
								<a>Log Out</a>
							</Menu.Item>
						</Menu>
					} trigger={['click']}>
						<a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
							<Avatar size={32} icon={<UserOutlined style={{ marginRight: 0 }} />} />
						</a>
					</Dropdown>
				</Col>
			</Row>
			<br />
			<Row type="flex" justify="center">
				<Col span={24}>
					<Card className="product-card">
						<Row justify="space-between" type="flex">
							<Col>
								<h3 style={{ fontSize: 24 }}>Users</h3>
							</Col>
							<Col>
								<Button className='submit' type="primary" style={{ width: 150, height: 50, border: 0 }} onClick={this.showModal}><b>Add New</b></Button>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			<br />
			<Row type="flex" justify="center">
				<Col span={24}>
				<Card className="product-card">
					<AdTable admins={this.state.admins} fetch={this.fetchAdmins} />
					</Card>
				</Col>
			</Row>
		</>;
	}
}
