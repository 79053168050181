import React from 'react';
import { DollarOutlined, PercentageOutlined, PictureOutlined, UserOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
	Row,
	Col,
	Input,
	Card,
	Menu,
	Button,
	Select,
	DatePicker,
	notification,
	Upload,
	message,
	Rate,
	Modal,
	TreeSelect,
	Dropdown,
	Avatar,
	Collapse,
	Divider
} from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as slugify from 'slugify';

import associates from '../images/associates.jpg';
import bestbuy from '../images/bestbuy.jpg';
import others from '../images/others.png';

import config from '../config';
import Editor from './editor';

const { Option } = Select;
const { Panel } = Collapse;

class Product extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			disAmazon: true,
			disBest: false,
			disOth: false,
			loading: false,
			categories: [],
			cBoxCategories: [],
			retailers: [],
			fileList: [],
			gfileList: [],
			ModalText: '',
			visible: false,
			confirmLoading: false,
			value: '',
			copied: false,
			description: '',
			brands: [],
			tags: [],
			coupons: [],
			attributes: [],
			selAtt: [],
			gallery: [],
			createText: '',
			rate: 5,
			newAtt: '',
			newTerm: '',
			newBrand: '',
			newTag: '',
			newCoupon: '',
			finalAtts: [],
			invalidAsin: false,
			invalidSku: false,
			selTerms: [],
			finalAtts: []
		}
	}

	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	handleOk = () => {
		if (this.state.fetchVariants) {
			this.setState({
				ModalText: 'Searching for Variants...',
				confirmLoading: true,
			}, () => {
				if (this.state.asin) {
					this.setState({ loading: true });
					fetch(config.api.url + "/product/getAmazonVariations", {
						method: "post",
						headers: { "content-type": "application/json" },
						body: JSON.stringify({ asin: this.state.asin })
					}).then(resp => resp.json())
						.then(async (data) => {
							if (data.length > 0) {
								var variants = [];
								await data.forEach(variation => {
									if (variation.VariationAttributes.length > 0) {
										variants.push(variation);
									}
								});
								// console.log(variants);
								this.setState({
									ModalText: <>
										{variants.map((variant, index) => {
											return (
												<>
													<Card style={{ cursor: "pointer" }} onClick={() => { this.getAmazon2(variant.ASIN) }} key={index}>
														<Row type="flex" justify="space-between">
															<Col>
																{variant.VariationAttributes.map((attribute, index) => {
																	var name = attribute.Name.toString().split('_name');
																	return (<>{name[0]} : <b>{attribute.Value}</b><br /></>);
																})}
															</Col>
															<Col>
																{variant.ASIN}
															</Col>
														</Row>
													</Card> <br />
												</>
											);
										})} </>,
									confirmLoading: false
								})
							} else {
								this.setState({ fetchVariants: false, confirmLoading: false, ModalText: 'No Variants Found.' });
							}
						})
				}
			});
		} else {
			this.handleCancel();
		}
	};

	handleCancel = () => {
		this.setState({
			visible: false
		});
	};

	componentDidMount = () => {
		this.fetchCategories();
		this.fetchRetailers();
		this.fetchAttributes();
		this.fetchBrands();
		this.fetchTags();
		this.fetchCoupons(1);
	}

	fetchCategories = () => {
		fetch(`${config.api.url}/category/get?all=true`).then((response) => response.json()).then((data) => {
			if (data.suc) {
				this.setState({ categories: data.dat.categories });
			} else {
				notification['error']({
					message: data.msg
				});
			}
		}).catch((error) => {
			console.error('Error:', error);
		});
		fetch(`${config.api.url}/category/getNodes`).then((response) => response.json()).then((data) => {
			if (data.suc) {
				this.setState({ cBoxCategories: data.dat.categories });
			} else {
				notification['error']({
					message: data.msg
				});
			}
		}).catch((error) => {
			console.error('Error:', error);
		});
	}

	fetchAttributes = () => {
		return new Promise((resolve, reject) => {
			fetch(`${config.api.url}/attribute/get?all=true`).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.setState({ attributes: data.dat.attributes }, () => { resolve(); });
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
		});
	}

	fetchBrands = () => {
		return new Promise((resolve, reject) => {
			fetch(`${config.api.url}/brand/get?all=true`).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.setState({ brands: data.dat.brands }, () => { resolve(); });
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
		});
	}

	fetchCoupons = (r) => {
		var retailer = r;
		return new Promise((resolve, reject) => {
			fetch(`${config.api.url}/coupon/get?rid=${retailer}`).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.setState({ coupons: data.dat.coupons }, () => { resolve(); });
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
		});
	}

	fetchTags = () => {
		return new Promise((resolve, reject) => {
			fetch(`${config.api.url}/tag/get?all=true`).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.setState({ tags: data.dat.tags }, () => { resolve(); });
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
		})
	}

	fetchRetailers = () => {
		fetch(`${config.api.url}/retailer/get?all=true`).then((response) => response.json()).then((data) => {
			if (data.suc) {
				this.setState({ retailers: data.dat.retailers });
			} else {
				notification['error']({
					message: data.msg
				});
			}
		}).catch((error) => {
			console.error('Error:', error);
		});
	}

	asin = (e) => {
		this.setState({ asin: e.target.value, invalidAsin: false });
	}

	metaTitleChange = (e) => {
		this.setState({ metaTitle: e.target.value });
	}
	metaKeywordsChange = (e) => {
		this.setState({ metaKeywords: e.target.value });
	}
	metaRobotsChange = (e) => {
		this.setState({ metaRobots: e.target.value });
	}
	metaCanonicalChange = (e) => {
		this.setState({ metaCanonical: e.target.value });
	}
	metaDescriptionChange = (e) => {
		this.setState({ metaDescription: e.target.value });
	}

	sku = (e) => {
		this.setState({ sku: e.target.value });
	}

	getAmazon = () => {
		if (this.state.asin) {
			this.setState({ loading: true });
			fetch(config.api.url + "/product/getAmazon", {
				method: "post",
				headers: { "content-type": "application/json" },
				body: JSON.stringify({ asin: this.state.asin })
			})
				.then(data => {
					if (data.ok) {
						data = data.json().then((data) => {
							// console.log(data);
							if (data.Offers) {
								this.setState({
									loading: false,
									fileList: [
										{
											uid: -1,
											name: 'image.png',
											status: 'done',
											url: data.Images.Primary.Large.URL,
											thumbUrl: data.Images.Primary.Large.URL
										}],
									imgPth: data.Images.Primary.Large.URL
								});
								if (data.Offers.Listings[0].Price.Savings) {
									this.props.form.setFieldsValue({
										discounted: data.Offers.Listings[0].Price.Savings.Amount,
										percent: data.Offers.Listings[0].Price.Savings.Percentage
									});
								}
								this.props.form.setFieldsValue({
									url: data.DetailPageURL,
									title: data.ItemInfo.Title.DisplayValue,
									slug: slugify(data.ItemInfo.Title.DisplayValue),
									price: data.Offers.Listings[0].Price.Amount
								});
							} else {
								this.setState({ fetchVariants: true, ModalText: 'This ASIN is a Parent ASIN, Do you want to Search it\'s Variants?' }, () => {
									this.showModal();
								});
							}
						});
					} else {
						this.setState({ fetchVariants: false, ModalText: 'This ASIN is invalid', invalidAsin: true }, () => {
							//this.showModal();
						});
					}
				})
		}
	}

	getBestBuy = () => {
		if (this.state.sku) {
			this.setState({ loading: true });
			fetch(config.api.url + "/product/getBestBuy", {
				method: "post",
				headers: { "content-type": "application/json" },
				body: JSON.stringify({ sku: this.state.sku })
			}).then(data => {
				if (data.ok) {
					data = data.json().then((data) => {
						// console.log(data.products);
						if (data.products && data.products.length > 0) {
							this.setState({
								loading: false,
								fileList: [
									{
										uid: -1,
										name: 'image.png',
										status: 'done',
										url: data.products[0].image,
										thumbUrl: data.products[0].image
									}],
								imgPth: data.products[0].image
							});
							if (data.products[0].percentSavings !== "0.0") {
								this.props.form.setFieldsValue({
									discounted: data.products[0].salePrice,
									percent: data.products[0].percentSavings
								});
							}
							this.props.form.setFieldsValue({
								url: data.products[0].url,
								title: data.products[0].name,
								slug: slugify(data.products[0].name),
								price: data.products[0].regularPrice
							});
						} else {
							this.setState({ ModalText: 'No Such Product', invalidSku: true }, () => {
								// this.showModal();
							});
						}
					});
				} else {
					this.setState({ ModalText: 'Invalid SKU', invalidSku: true }, () => {
						//this.showModal();
					});
				}
			})
		}
	}

	getAmazon2 = (ASIN) => {
		if (ASIN) {
			this.props.form.setFieldsValue({
				asin: ASIN
			});
			this.setState({ asin: ASIN, loading: true, visible: false });
			fetch(config.api.url + "/product/getAmazon", {
				method: "post",
				headers: { "content-type": "application/json" },
				body: JSON.stringify({ asin: ASIN })
			}).then(resp => resp.json())
				.then(data => {
					if (data.Offers) {
						this.setState({
							loading: false,
							fileList: [
								{
									uid: -1,
									name: 'image.png',
									status: 'done',
									url: data.Images.Primary.Large.URL,
									thumbUrl: data.Images.Primary.Large.URL,
								}],
							imgPth: data.Images.Primary.Large.URL
						});
						if (data.Offers.Listings[0].Price.Savings) {
							this.props.form.setFieldsValue({
								discounted: data.Offers.Listings[0].Price.Savings.Amount,
								percent: data.Offers.Listings[0].Price.Savings.Percentage
							});
						}
						this.props.form.setFieldsValue({
							url: data.DetailPageURL,
							title: data.ItemInfo.Title.DisplayValue,
							slug: slugify(data.ItemInfo.Title.DisplayValue),
							price: data.Offers.Listings[0].Price.Amount
						});
					} else {
						this.setState({ ModalText: 'No Offers for this Variant' }, () => {
							this.showModal();
						});
					}
				})
		}
	}

	submitProduct = () => {
		const { form } = this.props;
		this.setState({
			loading: true
		});
		form.validateFields([
			"asin",
			"sku",
			"slug",
			"url",
			"image",
			"title",
			"gridTitle",
			"description",
			"price",
			"discounted",
			"percent",
			"categories",
			"rating"
		], async (err, values) => {
			if (err) {
				this.setState({
					loading: false,
				});
				var result = Object.keys(err).map(function(key) {
					return [String(key), err[key]];
				});
				result.forEach(result => {
					notification['error']({
						message: result[1].errors[0].message
					})
				});
				return;
			} else {
				var identifier;
				var retailer;
				if (this.state.disAmazon) {
					identifier = values.asin;
					retailer = 1;
				}
				if (this.state.disBest) {
					identifier = values.sku;
					retailer = 2;
				}
				if (this.state.disOth) {
					identifier = Math.floor((Math.random() * 10000) + 1);
					retailer = this.props.form.getFieldValue('retailer');
				}
				var finalAtts = [];
				await this.state.selAtt.forEach(async (att, index) => {
					var attTerms = this.props.form.getFieldValue(`${att}-terms`);
					if(attTerms) finalAtts.push({ attId: att, terms: attTerms })
					else finalAtts.push({ attId: att, terms: [] })
				});
				var payload = {
					identifier: identifier,
					url: values.url,
					title: values.title,
					slug: values.slug,
					name: values.gridTitle,
					video: this.props.form.getFieldValue('video'),
					addCart: this.props.form.getFieldValue('addCart'),
					categories: JSON.stringify(values.categories),
					description: this.state.description,
					price: values.price,
					discount: values.discounted,
					percent: values.percent,
					rating: values.rating,
					expiry: this.state.expiry,
					retailer: retailer,
					brands: JSON.stringify(this.props.form.getFieldValue('brands')),
					tags: JSON.stringify(this.props.form.getFieldValue('tags')),
					coupons: JSON.stringify(this.props.form.getFieldValue('coupons')),
					attributes: JSON.stringify([...new Map(finalAtts.map(item => [item['attId'], item])).values()]),
					image: this.state.imgPth,
					gallery: JSON.stringify(this.state.gallery),
					metaTitle: this.state.metaTitle,
					metaKeywords: this.state.metaKeywords,
					metaRobots: this.state.metaRobots,
					metaCanonical: this.state.metaCanonical,
					metaDescription: this.state.metaDescription
				}
				fetch(`${config.api.url}/product/pst`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payload),
				}).then((response) => response.json()).then((data) => {
					if (data.suc) {
						this.setState({
							loading: false,
						});
						notification['success']({
							message: data.msg
						});
						this.props.history.push('/products');
					} else {
						this.setState({
							loading: false,
						});
						notification['error']({
							message: data.msg
						});
					}
				}).catch((error) => {
					console.error('Error:', error);
				});
			}
		});
	}

	showAmazon = () => {
		this.props.form.resetFields();
		this.fetchCoupons(1);
		this.setState({ disAmazon: true, disBest: false, disOth: false });
	}

	showBestBuy = () => {
		this.props.form.resetFields();
		this.fetchCoupons(2);
		this.setState({ disAmazon: false, disBest: true, disOth: false });
	}

	showOthers = () => {
		this.props.form.resetFields();
		this.setState({ disAmazon: false, disBest: false, disOth: true });
	}

	onDateChange = (value, dateString) => {
		this.setState({ expiry: dateString });
	}

	setTextValue = (a, b) => {
		this.state[a] = b;
	}

	onAttChange = (e) => {
		this.setState({
			newAtt: e.target.value,
		});
	}

	attAdded = (e) => {
		this.setState({ selAtt: e });
	}

	addAtt = async () => {
		const { attributes, newAtt } = this.state;
		if (new RegExp('^[a-zA-Z0-9].*').test(newAtt)) {
			var payload = {
				name: newAtt,
				slug: newAtt
			}
			fetch(`${config.api.url}/attribute/pst`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			}).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.fetchAttributes().then(async () => {
						var id = await this.state.attributes.find(x => x.name === newAtt).id
						this.setState({
							attributes: [...attributes, { name: newAtt, id: id }],
							newAtt: '',
						}, () => {
							this.setState({ selAtt: [...this.state.selAtt, id] });
							var vals = this.props.form.getFieldValue('attributes');
							if (vals !== undefined && vals.length > 0) {
								this.props.form.setFieldsValue({ attributes: [...this.props.form.getFieldValue('attributes'), id] });
							} else {
								this.props.form.setFieldsValue({ attributes: [id] });
							}
						});
						notification['success']({
							message: data.msg
						});
					})
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
		} else {
			notification['error']({
				message: 'can only start with a letter or number'
			});
		}
	}

	onTermChange = (e) => {
		this.setState({
			newTerm: e.target.value,
		});
	}

	termAdded = (e, att) => {
		this.setState({ selTerms: [...this.state.selTerms, { attId: att, terms: e }]})
	}

	addTerm = async (att) => {
		const { newTerm } = this.state;
		if (new RegExp('^[a-zA-Z0-9].*').test(newTerm)) {
			var payload = {
				name: newTerm,
				slug: newTerm,
				attributeId: att
			}
			fetch(`${config.api.url}/term/pst`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			}).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.fetchAttributes().then(async () => {
						const { attributes } = this.state;
						var id = await attributes[attributes.findIndex(x => x.id === att)].terms.find(x => x.name === newTerm).id;
						this.setState({
							[attributes[attributes.findIndex(x => x.id === att)].terms]: [...attributes[attributes.findIndex(x => x.id === att)].terms, { name: newTerm, id: id }],
							newTerm: '',
						}, () => {
							// this.setState({ finalAtts: [...this.state.finalAtts, { attId: att, terms: id }] });
							var vals = this.props.form.getFieldValue(`${att}-terms`);
							if (vals !== undefined && vals.length > 0) {
								this.props.form.setFieldsValue({ [`${att}-terms`]: [...this.props.form.getFieldValue(`${att}-terms`), id] });
							} else {
								this.props.form.setFieldsValue({ [`${att}-terms`]: [id] });
							}
						});
						notification['success']({
							message: data.msg
						});
					})
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
		} else {
			notification['error']({
				message: 'can only start with a letter or number'
			});
		}
	}

	onBrandChange = (e) => {
		this.setState({
			newBrand: e.target.value,
		});
	}

	addBrand = async () => {
		const { brands, newBrand } = this.state;
		if (new RegExp('^[a-zA-Z0-9].*').test(newBrand)) {
			var payload = {
				name: newBrand,
				slug: newBrand
			}
			fetch(`${config.api.url}/brand/pst`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			}).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.fetchBrands().then(async () => {
						var id = await this.state.brands.find(x => x.name === newBrand).id
						this.setState({
							brands: [...brands, { name: newBrand, id: id }],
							newBrand: '',
						}, () => {
							var vals = this.props.form.getFieldValue('brands');
							if (vals !== undefined && vals.length > 0) {
								this.props.form.setFieldsValue({ brands: [...this.props.form.getFieldValue('brands'), id] })
							} else {
								this.props.form.setFieldsValue({ brands: [id] })
							}
						});
						notification['success']({
							message: data.msg
						});
					})
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
		} else {
			notification['error']({
				message: 'can only start with a letter or number'
			});
		}
	}

	onCouponChange = (e) => {
		this.setState({
			newCoupon: e.target.value,
		});
	}

	addCoupon = async () => {
		const { coupons, newCoupon } = this.state;
		if (new RegExp('^[a-zA-Z0-9].*').test(newCoupon)) {
			var retailer;
			if (this.state.disAmazon) retailer = 1;
			if (this.state.disBest) retailer = 2;
			if (this.state.disOth) retailer = this.props.form.getFieldValue('retailer');
			var payload = {
				name: newCoupon,
				code: newCoupon,
				retailer: retailer
			}
			fetch(`${config.api.url}/coupon/pst`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			}).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.fetchCoupons(retailer).then(async () => {
						var id = await this.state.coupons.find(x => x.name === newCoupon).id
						this.setState({
							coupons: [...coupons, { name: newCoupon, id: id }],
							newCoupon: '',
						}, () => {
							var vals = this.props.form.getFieldValue('coupons');
							if (vals !== undefined && vals.length > 0) {
								this.props.form.setFieldsValue({ coupons: [...this.props.form.getFieldValue('coupons'), id] })
							} else {
								this.props.form.setFieldsValue({ coupons: [id] })
							}
						});
						notification['success']({
							message: data.msg
						});
					})
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
		} else {
			notification['error']({
				message: 'can only start with a letter or number'
			});
		}
	}

	onTagChange = (e) => {
		this.setState({
			newTag: e.target.value,
		});
	}

	addTag = async () => {
		const { tags, newTag } = this.state;
		if (new RegExp('^[a-zA-Z0-9].*').test(newTag)) {
			var payload = {
				name: newTag,
				color: '#000',
				icon: 'fa fa-camera'
			}
			fetch(`${config.api.url}/tag/pst`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			}).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.fetchTags().then(async () => {
						var id = await this.state.tags.find(x => x.name === newTag).id
						this.setState({
							tags: [...tags, { name: newTag, id: id }],
							newTag: '',
						}, () => {
							var vals = this.props.form.getFieldValue('tags');
							if (vals !== undefined && vals.length > 0) {
								this.props.form.setFieldsValue({ tags: [...this.props.form.getFieldValue('tags'), id] })
							} else {
								this.props.form.setFieldsValue({ tags: [id] })
							}
						});
						notification['success']({
							message: data.msg
						});
					})
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
		} else {
			notification['error']({
				message: 'can only start with a letter or number'
			});
		}
	}

	handleChange = info => {
		let fileList = [...info.fileList];
		fileList = fileList.slice(-1);

		fileList = fileList.map(file => {
			if (file.response) {
				file.url = file.response.url;
			}
			return file;
		});

		const { status } = info.file;

		if (status !== 'uploading') {
			// console.log(info.file, info.fileList);
		}
		if (status === 'done') {
			this.setState({ imgPth: info.file.response.data })
			message.success(`${info.file.name} file uploaded successfully.`);
		} else if (status === 'error') {
			message.error(`${info.file.name} file upload failed.`);
		}

		this.setState({ fileList });
	};

	ghandleChange = info => {
		let fileList = [...info.fileList];
		//fileList = fileList.slice(-1);

		fileList = fileList.map(file => {
			if (file.response) {
				file.url = file.response.url;
			}
			return file;
		});

		const { status } = info.file;

		if (status !== 'uploading') {
			// console.log(info.file, info.fileList);
		}
		if (status === 'done') {
			var gallery = this.state.gallery;
			gallery.push(info.file.response.data);
			this.setState({ gallery: gallery });
			message.success(`${info.file.name} file uploaded successfully.`);
		} else if (status === 'error') {
			message.error(`${info.file.name} file upload failed.`);
		}

		this.setState({ gfileList: fileList });
	};

	logout = () => {
		localStorage.clear();
		this.props.history.push("/login");
	}

	setRate = (e) => {
		this.setState({ rate: e });
	}

	render = () => {
		const { form } = this.props;
		const { getFieldDecorator } = form;

		const { visible, confirmLoading, ModalText, newBrand, newCoupon, newTag, newAtt, newTerm } = this.state

		const props = {
			name: 'image',
			multiple: false,
			action: `${config.api.url}/product/upload`,
			onChange: this.handleChange,
			listType: 'picture-card',
			showUploadList: { showPreviewIcon: false, showRemoveIcon: true }
		};

		const gprops = {
			name: 'image',
			multiple: true,
			action: `${config.api.url}/product/upload`,
			onChange: this.ghandleChange,
			listType: 'picture-card',
			showUploadList: { showPreviewIcon: false, showRemoveIcon: true }
		};

		const tProps = {
			treeData: this.state.cBoxCategories,
			treeCheckable: false,
			multiple: true,
			allowClear: true,
			placeholder: 'Select Categories',
			style: {
				width: '95%',
				paddingTop: '20px'
			},
			showSearch: true,
			optionFilterProp: "children",
			filterTreeNode: (input, option) => option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
		};

		return <>
			<Modal
				title="Choose A Variant"
				visible={visible}
				onOk={this.handleOk}
				confirmLoading={confirmLoading}
				onCancel={this.handleCancel}
			>
				<p>{ModalText}</p>
			</Modal>
			<Row type="flex" justify="space-between">
				<Col>
					<h1 style={{ fontWeight: "bold" }}>Add a Product</h1>
				</Col>
				<Col>
					<Dropdown overlay={
						<Menu>
							<Menu.Item key="0" onClick={this.logout}>
								<a>Log Out</a>
							</Menu.Item>
						</Menu>
					} trigger={['click']}>
						<a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
							<Avatar size={32} icon={<UserOutlined style={{ marginRight: 0 }} />} />
						</a>
					</Dropdown>
				</Col>
			</Row>
			<br />
			<Row type="flex" justify="center">
				<Col span={24}>
					<Card className="product-card" title={<h3 style={{ fontSize: '17px', }}>CHOOSE A RETAILER</h3>} style={{ padding: '30px' }}>
						<Row type="flex" justify="space-around" gutter={32, 32}>
							<Col>
								<Card onClick={this.showAmazon} className={this.state.disAmazon ? "selApiPro" : "apiPro"} style={{ height: "180px", width: "200px" }}>
									<img src={associates} style={{ marginTop: 12, width: "100%", height: '100%', cursor: "pointer" }} />
								</Card>
							</Col>
							<Col>
								<Card onClick={this.showBestBuy} className={this.state.disBest ? "selApiPro" : "apiPro"} style={{ height: "180px", width: "200px" }}>
									<img src={bestbuy} style={{ marginTop: 14, width: "100%", height: '100%', cursor: "pointer" }} />
								</Card>
							</Col>
							<Col>
								<Card onClick={this.showOthers} className={this.state.disOth ? "selApiPro" : "apiPro"} style={{ height: "180px", width: "200px" }}>
									<img src={others} style={{ marginTop: 16, width: "100%", height: '100%', cursor: "pointer" }} />
								</Card>
							</Col>
						</Row>
						<br /> <br /> <br />
						<div style={{ height: 1, background: "#b9cbf6" }} ></div>
						<Row type="flex" justify="center">
							<Col span={22}>
								<Form ref="amForm" layout="horizontal">
									<br /><br /><h3 style={{ textAlign: 'left' }}>GENERAL INFORMATION</h3><br /> <br />
									<Row type="flex" justify="center" gutter={[32, 32]}>
										<Col span={12}>
											{this.state.disAmazon && (
												<Form.Item>
													<div className='form-control'>
														<span className='form-control-title'>ASIN ID</span>
														{getFieldDecorator('asin', {
															rules: [{ required: true, message: "ASIN is Required" }]
														})(
															<Input placeholder='ABC123XYZ' className='form-input-main' size="large" maxLength={10} onChange={this.asin} onBlur={this.getAmazon} style={{ width: '100%' }} type="text" />
														)}
													</div>
													{this.state.invalidAsin && (<span style={{color: 'red'}}>ASIN is invalid</span>)}
												</Form.Item>
											)}
											{this.state.disBest && (
												<Form.Item>
													<div className='form-control'>
														<span className='form-control-title'>SKU</span>
														{getFieldDecorator('sku', {
															rules: [{ required: true, message: "SKU is Required" }]
														})(
															<Input placeholder='ABC123XYZ' className='form-input-main' size="large" maxLength={10} onChange={this.sku} onBlur={this.getBestBuy} style={{ width: '100%' }} type="text" />
														)}
													</div>
													{this.state.invalidSku && (<span style={{color: 'red'}}>SKU is invalid</span>)}
												</Form.Item>
											)}
											{this.state.disOth && (
												<Form.Item>
													<div className='form-control'>
														<span className='form-control-title'>Retailer</span>
														{getFieldDecorator('retailer', {
															rules: [{ required: true, message: "Retailer is Required" }]
														})(
															<Select
																showSearch
																className='form-input-main'
																style={{ width: '95%', paddingTop: '20px' }}
																placeholder="Retailer"
																onChange={(r)=>{this.fetchCoupons(r)}}
																optionFilterProp="children"
																filterOption={(input, option) =>
																	option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
																}
															>
																{this.state.retailers.map((retailer) => {
																	if (retailer.id > 2) {
																		return (
																			<Option value={retailer.id}>{retailer.name}</Option>
																		);
																	}
																})}
															</Select>
														)}
													</div>
												</Form.Item>
											)}
										</Col>
										<Col span={12}>
											<Form.Item>
												<div className='form-control'>
													<span className='form-control-title'>Deal Expiry Date</span>
													{getFieldDecorator('expiry', {
														rules: [{ required: false, message: "Date is Required" }]
													})(
														<DatePicker placeholder='Pick a Date and Time' className='form-input-main' showTime={{ format: 'HH:mm' }}
															format="YYYY-MM-DD HH:mm" style={{ width: '100%' }} showTime onChange={this.onDateChange} />
													)}
												</div>
											</Form.Item>
										</Col>
									</Row>
									<Row type="flex" justify="center" gutter={[32, 32]}>
										<Col span={12}>
											{(this.state.disAmazon || this.state.disBest) && (
												<CopyToClipboard text={this.props.form.getFieldValue("url")}
													onCopy={() => { if (this.props.form.getFieldValue('url') !== undefined) this.setState({ copied: true }) }}>
													<Form.Item>
														<div className='form-control'>
															<span className='form-control-title'>URL (Click to Copy)</span>
															{getFieldDecorator('url', {
																rules: [{ required: true, message: "URL is Required" }]
															})(
																<Input className='form-input-main' size="large" style={{ cursor: 'not-allowed', width: '100%' }} readOnly type="text" />
															)} <br />
														</div>
														{this.state.copied ? <span style={{ color: 'red' }}>Copied</span> : null}
													</Form.Item>
												</CopyToClipboard>
											)}
											{this.state.disOth && (
												<Form.Item>
													<div className='form-control'>
														<span className='form-control-title'>URL (Click to Copy)</span>
														{getFieldDecorator('url', {
															rules: [{ required: true, message: "URL is Required" },
															{
																type: "url",
																message: "This field must be a valid url."
															}]
														})(
															<Input className='form-input-main' size="large" style={{ width: '100%' }} type="text" />
														)}
													</div>
												</Form.Item>
											)}
										</Col>
										<Col span={12}>
											<Form.Item>
												<div className='form-control'>
													<span className='form-control-title'>Categories</span>
													{getFieldDecorator('categories', {
														rules: [{ required: true, message: "Category is Required" }]
													})(
														<TreeSelect className='form-input-main' {...tProps} />
													)}
												</div>
											</Form.Item>
										</Col>
									</Row>
									<Row type="flex" justify="center" gutter={[32, 32]}>
										<Col span={12}>
											<Form.Item>
												<div className='form-control'>
													<span className='form-control-title'>Title</span>
													{getFieldDecorator('title', {
														rules: [{ required: true, message: "Title is Required" }]
													})(
														<Input onChange={(e)=>{this.props.form.setFieldsValue({slug: slugify(e.target.value)})}} placeholder='Main Title' className='form-input-main' size="large" style={{ width: '100%' }} type="text" />
													)}
												</div>
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item>
												<div className='form-control'>
													<span className='form-control-title'>Grid Title</span>
													{getFieldDecorator('gridTitle', {
														rules: [{ required: true, message: "Grid Title is Required" }]
													})(
														<Input placeholder='Grid Title' className='form-input-main' maxLength={60} size="large" style={{ width: '100%' }} type="text" />
													)}
												</div>
											</Form.Item>
										</Col>
									</Row>
									<Row type="flex" justify="center" gutter={[32, 32]}>
										<Col span={12}>
											<Form.Item>
												<div className='form-control'>
													<span className='form-control-title'>Slug</span>
													{getFieldDecorator('slug', {
														rules: [{ required: true, message: "Slug is Required" },
													{pattern: new RegExp('^[a-z0-9](-?[a-z0-9])*$'), message: 'Inappropriate Slug'}]
													})(
														<Input placeholder='Slug' className='form-input-main' size="large" style={{ width: '100%' }} type="text" />
													)}
												</div>
											</Form.Item>
										</Col>
									</Row>
									<Row type="flex" justify="center" gutter={[32, 32]}>
										<Col span={24}>
											<Form.Item>
												<div className='form-control-large'>
													<span className='form-control-title'>Description</span>
													{getFieldDecorator('edescription', {
														rules: [{ required: true, message: "Description is Required" }]
													})(
														<Editor className='form-input-main' field='description' setFieldsValue={this.setTextValue} placeholder={'Write from Here..'} />
													)}
												</div>
											</Form.Item>
											{getFieldDecorator('description', { initialValue: {} })(<Input hidden />)}
										</Col>
									</Row>
									<Row type="flex" justify="center" gutter={[32, 32]}>
										<Col span={8}>
											<Form.Item>
												<div className='form-control'>
													<span className='form-control-title'>Current Price</span>
													{getFieldDecorator('price', {
														rules: [{ required: true, message: "Price is Required" }]
													})(<Input className='form-input-main' size='large' disabled={this.state.disOth ? false : true} prefix={<DollarOutlined />} style={{ width: '100%' }} type="number" />)}
												</div>
											</Form.Item>
										</Col>
										<Col span={8}>
											<Form.Item>
												<div className='form-control'>
													<span className='form-control-title'>Discounted Amount</span>
													{getFieldDecorator('discounted', {
														rules: [{ required: false, message: "Discounted is Required" }]
													})(<Input className='form-input-main' size='large' disabled={this.state.disOth ? false : true} prefix={<DollarOutlined />} style={{ width: '100%' }} type="number" />)}
												</div>
											</Form.Item>
										</Col>
										<Col span={8}>
											<Form.Item>
												<div className='form-control'>
													<span className='form-control-title'>Discount Percentage</span>
													{getFieldDecorator('percent', {
														rules: [{ required: false, message: "Percent is Required" }]
													})(<Input className='form-input-main' size='large' disabled={this.state.disOth ? false : true} prefix={<PercentageOutlined />} style={{ width: '100%' }} type="number" />)}
												</div>
											</Form.Item>
										</Col>
									</Row>
									<div style={{ height: 1, background: "#b9cbf6" }} ></div><br /><br /><h3 style={{ textAlign: 'left' }}>PRODUCT SPECIFICATIONS</h3><br />
									<br /><Row type="flex" justify="center" gutter={[32, 32]}>
										<Col span={12}>
											<Form.Item>
												<div className='form-control'>
													<span className='form-control-title'>Specifications</span>
													{getFieldDecorator('attributes', {
														rules: [{ required: false, message: "Attributes are Required" }]
													})(
														<Select
															showSearch
															optionFilterProp="children"
															className='form-input-main'
															allowClear={true}
															style={{ width: '96%', paddingTop: '20px' }}
															placeholder="Select Attributes"
															mode='multiple'
															onChange={this.attAdded}
															dropdownRender={list => (
																<div>
																	{list}
																	<Divider style={{ margin: '10px' }} />
																	<Row type='flex' justify='space-around' style={{ padding: '5px' }}>
																		<Col span={20}>
																			<div className='form-control' style={{ height: '55px' }}>
																				<span className='form-control-title'>New Attribute</span>
																				<Input className='form-input' style={{ height: '50px', width: '100%' }} value={newAtt} onChange={this.onAttChange} />
																			</div>
																		</Col>
																		<Col span={3}>
																			<Button onClick={this.addAtt} type='primary' className='submit' style={{ width: '100%', height: '100%', border: 0 }}><b>ADD</b></Button>
																		</Col>
																	</Row>
																</div>
															)}
														>
															{this.state.attributes.map((att, index) => {
																return (
																	<Option key={index} value={att.id}>{att.name}</Option>
																);
															})}
														</Select>
													)}
												</div>
											</Form.Item>
										</Col>
									</Row>
									<Row type="flex" justify="center" gutter={[32, 32]}>
										<Col span={12}>
											{this.state.selAtt.length > 0 && (
												<div className='form-control'>
													<Collapse
														style={{ background: '#eef3ff', width: '100%' }}
														expandIconPosition={'right'}
													>
														{this.state.selAtt && this.state.selAtt.map((att, index) => {
															return (
																<Panel header={(this.state.attributes[this.state.attributes.findIndex(x => x.id === att)] && this.state.attributes[this.state.attributes.findIndex(x => x.id === att)].name) && (this.state.attributes[this.state.attributes.findIndex(x => x.id === att)].name)} key={index}>
																	<Row type='flex' justify='center'>
																		<Col span='20'>
																			<Form.Item style={{ marginTop: '20px' }}>
																				<div className='form-control'>
																					<span className='form-control-title'>Terms</span>
																					{getFieldDecorator(`${att}-terms`, {
																						rules: [{ required: false, message: "Terms are Required" }]
																					})(
																						<Select
																							showSearch
																							optionFilterProp="children"
																							className='form-input-main'
																							allowClear={true}
																							style={{ width: '96%', paddingTop: '20px' }}
																							placeholder="Select Terms"
																							mode='multiple'
																							onChange={(e) => { this.termAdded(e, att) }}
																							dropdownRender={list => (
																								<div>
																									{list}
																									<Divider style={{ margin: '10px' }} />
																									<Row type='flex' justify='space-around' style={{ padding: '5px' }}>
																										<Col span={20}>
																											<div className='form-control' style={{ height: '55px' }}>
																												<span className='form-control-title'>New Term</span>
																												<Input className='form-input' style={{ height: '50px', width: '100%' }} value={newTerm} onChange={this.onTermChange} />
																											</div>
																										</Col>
																										<Col span={3}>
																											<Button onClick={() => { this.addTerm(att) }} type='primary' className='submit' style={{ width: '100%', height: '100%', border: 0 }}><b>ADD</b></Button>
																										</Col>
																									</Row>
																								</div>
																							)}
																						>
																							{this.state.attributes[this.state.attributes.findIndex(x => x.id === att)].terms && this.state.attributes[this.state.attributes.findIndex(x => x.id === att)].terms.map((term) => {
																								return (
																									<Option value={term.id}>{term.name}</Option>
																								);
																							})}
																						</Select>
																					)}
																				</div>
																			</Form.Item>
																		</Col>
																	</Row>
																</Panel>
															)
														})}
													</Collapse>
												</div>)}
										</Col>
									</Row><br />
									<div style={{ height: 1, background: "#b9cbf6" }} ></div><br /> <br />
									<h3 style={{ textAlign: 'left' }}>OTHER INFORMATION</h3><br /> <br />
									<Row type="flex" justify="center" gutter={[32, 32]}>
										<Col span={12}>
											<Form.Item>
												<div className='form-control'>
													<span className='form-control-title'>Brands</span>
													{getFieldDecorator('brands', {
														rules: [{ required: false, message: "Brand is Required" }]
													})(
														<Select
															showSearch
															optionFilterProp="children"
															className='form-input-main'
															allowClear={true}
															style={{ width: '96%', paddingTop: '20px' }}
															placeholder="Select Brands"
															mode='multiple'
															dropdownRender={list => (
																<div>
																	{list}
																	<Divider style={{ margin: '10px' }} />
																	<Row type='flex' justify='space-around' style={{ padding: '5px' }}>
																		<Col span={20}>
																			<div className='form-control' style={{ height: '55px' }}>
																				<span className='form-control-title'>New Brand</span>
																				<Input className='form-input' style={{ height: '50px', width: '100%' }} value={newBrand} onChange={this.onBrandChange} />
																			</div>
																		</Col>
																		<Col span={3}>
																			<Button onClick={this.addBrand} type='primary' className='submit' style={{ width: '100%', height: '100%', border: 0 }}><b>ADD</b></Button>
																		</Col>
																	</Row>
																</div>
															)}
														>
															{this.state.brands.map((brand) => {
																return (
																	<Option value={brand.id}>{brand.name}</Option>
																);
															})}
														</Select>
													)}
												</div>
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item>
												<div className='form-control'>
													<span className='form-control-title'>Coupons</span>
													{getFieldDecorator('coupons', {
														rules: [{ required: false, message: "Coupon is Required" }]
													})(
														<Select
															showSearch
															optionFilterProp="children"
															className='form-input-main'
															allowClear={true}
															style={{ width: '96%', paddingTop: '20px' }}
															placeholder="Select Coupons"
															mode='multiple'
															dropdownRender={list => (
																<div>
																	{list}
																	<Divider style={{ margin: '10px' }} />
																	<Row type='flex' justify='space-around' style={{ padding: '5px' }}>
																		<Col span={20}>
																			<div className='form-control' style={{ height: '55px' }}>
																				<span className='form-control-title'>New Coupon</span>
																				<Input className='form-input' style={{ height: '50px', width: '100%' }} value={newCoupon} onChange={this.onCouponChange} />
																			</div>
																		</Col>
																		<Col span={3}>
																			<Button onClick={this.addCoupon} type='primary' className='submit' style={{ width: '100%', height: '100%', border: 0 }}><b>ADD</b></Button>
																		</Col>
																	</Row>
																</div>
															)}
														>
															{this.state.coupons.map((coupon) => {
																return (
																	<Option value={coupon.id}>{coupon.name}</Option>
																);
															})}
														</Select>
													)}
												</div>
											</Form.Item>
										</Col>
									</Row>
									<Row type="flex" justify="center" gutter={[32, 32]}>
										<Col span={12}>
											<Form.Item>
												<div className='form-control'>
													<span className='form-control-title'>Tags</span>
													{getFieldDecorator('tags', {
														rules: [{ required: false, message: "Tag is Required" }]
													})(
														<Select
															showSearch
															optionFilterProp="children"
															className='form-input-main'
															allowClear={true}
															style={{ width: '96%', paddingTop: '20px' }}
															placeholder="Select Tags"
															mode='multiple'
															dropdownRender={list => (
																<div>
																	{list}
																	<Divider style={{ margin: '10px' }} />
																	<Row type='flex' justify='space-around' style={{ padding: '5px' }}>
																		<Col span={20}>
																			<div className='form-control' style={{ height: '55px' }}>
																				<span className='form-control-title'>New Tag</span>
																				<Input className='form-input' style={{ height: '50px', width: '100%' }} value={newTag} onChange={this.onTagChange} />
																			</div>
																		</Col>
																		<Col span={3}>
																			<Button onClick={this.addTag} type='primary' className='submit' style={{ width: '100%', height: '100%', border: 0 }}><b>ADD</b></Button>
																		</Col>
																	</Row>
																</div>
															)}
														>
															{this.state.tags.map((tag) => {
																return (
																	<Option value={tag.id}>{tag.name}</Option>
																);
															})}
														</Select>
													)}
												</div>
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item>
												<div className='form-control'>
													<span className='form-control-title'>Cart Button Text</span>
													{getFieldDecorator('addCart', {
														rules: [{ required: false, message: "Grid Title is Required" }]
													})(
														<Input placeholder='Add to Cart Button Text' className='form-input-main' maxLength={60} size="large" style={{ width: '100%' }} type="text" />
													)}
												</div>
											</Form.Item>
										</Col>
									</Row>
									<br />
									<div style={{ height: 1, background: "#b9cbf6" }} ></div><br /><br />
									<h3 style={{ textAlign: 'left' }}>MEDIA GALLERY</h3><br /><br />
									<Row type="flex" justify="center" gutter={[32, 32]}>
										<Col span={24}>
											<Form.Item>
												<div className='form-control' style={{ borderStyle: 'dashed', borderWidth: '2px' }}>
													<span className='form-control-title'>Gallery</span>
													<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
														{getFieldDecorator('gallery', {
															rules: [{ required: false, message: "Image is Required" }]
														})(
															<Upload className='form-input-main' style={{ margin: 'auto' }} fileList={this.state.gfileList} {...gprops}>
																{this.state.gfileList.length >= 5 ? null : (<><PictureOutlined /><br />Click Here to Upload New</>)}
															</Upload>
														)}
													</div>
												</div>
											</Form.Item>
										</Col>
									</Row>
									<Row type="flex" justify="center" gutter={[32, 32]}>
										<Col span={12}>
											<Form.Item>
												<div className='form-control'>
													<span className='form-control-title'>Video</span>
													{getFieldDecorator('video', {
														rules: [{ required: false, message: "Title is Required" },
														/* { type: "url", message: "This field must be a valid url." } */]
													})(
														<Input placeholder='Embed Code' className='form-input-main' size="large" style={{ width: '100%' }} type="text" />
													)}
												</div>
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item>
												<div className='form-control' style={{ borderStyle: 'dashed', borderWidth: '2px' }}>
													<span className='form-control-title'>Featured</span>
													<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
														{getFieldDecorator('image', {
															rules: [{ required: false, message: "Image is Required" }]
														})(
															<Upload className='form-input-main' style={{ margin: 'auto' }} fileList={this.state.fileList} {...props}>
																{this.state.fileList.length >= 1 ? null : (<><PictureOutlined /><br />Click Here to Upload New</>)}
															</Upload>
														)}
													</div>
												</div>
											</Form.Item>
										</Col>
									</Row>
									<br />
									<div style={{ height: 1, background: "#b9cbf6" }} ></div><br /> <br />
									<h3 style={{ textAlign: 'left' }}>SEO INFORMATION</h3><br /> <br />
									<Row type="flex" justify="center" gutter={[32, 32]}>
										<Col span={12}>
											<Form.Item>
												<div className='form-control'>
													<span className='form-control-title'>Meta Title</span>
													{getFieldDecorator('metaTitle', {
														rules: [{ required: false, message: "Title is Required" }]
													})(
														<Input className='form-input-main' onChange={this.metaTitleChange} size="large" style={{ width: '100%' }} type="text" />
													)}
												</div>
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item>
												<div className='form-control'>
													<span className='form-control-title'>Meta Keywords</span>
													{getFieldDecorator('metaKeywords', {
														rules: [{ required: false, message: "Title is Required" }]
													})(
														<Input className='form-input-main' onChange={this.metaKeywordsChange} size="large" style={{ width: '100%' }} type="text" />
													)}
												</div>
											</Form.Item>
										</Col>
									</Row>
									<Row type="flex" justify="center" gutter={[32, 32]}>
										<Col span={12}>
											<Form.Item>
												<div className='form-control'>
													<span className='form-control-title'>Meta Robots </span>
													{getFieldDecorator('metaRobots', {
														rules: [{ required: false, message: "Title is Required" }]
													})(
														<Input className='form-input-main' onChange={this.metaRobotsChange} size="large" style={{ width: '100%' }} type="text" />
													)}
												</div>
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item>
												<div className='form-control'>
													<span className='form-control-title'>Meta Canonical</span>
													{getFieldDecorator('metaCanonical', {
														rules: [{ required: false, message: "Title is Required" }]
													})(
														<Input className='form-input-main' onChange={this.metaCanonicalChange} size="large" style={{ width: '100%' }} type="text" />
													)}
												</div>
											</Form.Item>
										</Col>
									</Row>
									<Row type="flex" justify="center" gutter={[32, 32]}>
										<Col span={24}>
											<Form.Item>
												<div className='form-control'>
													<span className='form-control-title'>Meta Description</span>
													{getFieldDecorator('metaDescription', {
														rules: [{ required: false, message: "Title is Required" }]
													})(
														<Input className='form-input-main' onChange={this.metaDescriptionChange} size="large" style={{ width: '100%' }} type="text" />
													)}
												</div>
											</Form.Item>
										</Col>
									</Row>
									<br />
									<Row type="flex" style={{ paddingTop: '10px', background: '#eef3ff' }} justify="space-around" gutter={[32, 32]}>
										<Col span={8}>
											<Row type='flex' justify='start'>
												<span style={{ fontSize: '18px', height: '24px', marginTop: '14px', marginLeft: '28px' }} className='form-control-title'>Admin Rating</span>
											</Row>
										</Col>
										<Col span={8}>
											<Row type='flex' justify='center'>
												<Form.Item>
													{getFieldDecorator('rating', { initialValue: 5 }, {
														rules: [{ required: false, message: "Rating is Required" }]
													})(<Rate onChange={this.setRate} style={{ width: '100%' }} />)}
												</Form.Item>
											</Row>
										</Col>
										<Col span={8}>
											<Row type='flex' justify='end'>
												<h1 style={{ marginTop: '8px', marginRight: '28px' }}>{this.state.rate}</h1>
											</Row>
										</Col>
									</Row> <br />
									<Row type="flex" justify="center" gutter={[32, 32]}>
										<Button type='primary' style={{ border: 'none' }} className='submit' onClick={this.submitProduct}><b>ADD PRODUCT</b></Button>
									</Row>
								</Form>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		</>;
	}
}


const ProductForm = Form.create({ name: 'productForm' })(Product);

export default ProductForm;
