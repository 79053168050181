import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, notification, Button, Row } from 'antd';
import * as slugify from 'slugify';

import config from '../config';

const { Option } = Select;

class Attribute extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			attributes: []
		}
	}

	componentDidMount() {
		this.props.form.setFieldsValue({
			name: this.props.name,
			slug: this.props.slug
		});
		this.props.setForm(this.props.form);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.id !== this.props.id) {
			this.props.form.setFieldsValue({
				name: nextProps.name,
				slug: nextProps.slug
			});
		}
	}

	render() {
		const { form } = this.props;
		const { getFieldDecorator } = form;

		return (
			<Form layout="vertical">
				{getFieldDecorator('attributeForm', { initialValue: 'true' })(<Input hidden />)}
				<Form.Item>
				<div className='form-control'>
						<span className='form-control-title'>Name</span>
					{getFieldDecorator('name', {
						rules: [{ required: true, message: "Name is Required" }]
					})(<Input onChange={(e)=>{this.props.form.setFieldsValue({slug: slugify(e.target.value)})}} className='form-input-main' style={{width: '100%'}} type="text" />)}
					</div>
				</Form.Item>
				<Form.Item>
				<div className='form-control'>
						<span className='form-control-title'>Slug</span>
					{getFieldDecorator('slug', {
						rules: [{ required: true, message: "Slug is Required" }]
					})(<Input className='form-input-main' style={{width: '100%'}} type="text" />)}
					</div>
				</Form.Item>
				<Form.Item>
					<Row type='flex' justify='center'>
					<Button className='submit' style={{border: 0}} key="submit" type="primary" onClick={this.props.handleOk}><b>SUBMIT</b></Button>
					</Row>
				</Form.Item>
			</Form>
		);
	}
}

const attributeForm = Form.create({ name: 'attributeForm' })(Attribute);

export default attributeForm;
