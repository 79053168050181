import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, notification, Button, Row } from 'antd';

import config from '../config';

const { Option } = Select;

class Coupon extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			coupons: [],
			retailers: [],
		}
	}

	fetchRetailers = () => {
		fetch(`${config.api.url}/retailer/get?all=true`).then((response) => response.json()).then((data) => {
			if (data.suc) {
				this.setState({ retailers: data.dat.retailers });
			} else {
				notification['error']({
					message: data.msg
				});
			}
		}).catch((error) => {
			console.error('Error:', error);
		});
	}

	componentDidMount() {
		this.fetchRetailers();
		this.props.form.setFieldsValue({
			name: this.props.name,
			code: this.props.code,
			retailer: this.props.retailer
		});
		this.props.setForm(this.props.form);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.id !== this.props.id) {
			this.props.form.setFieldsValue({
				name: nextProps.name,
				code: nextProps.code,
				retailer: nextProps.retailer
			});
		}
	}

	render() {
		const { form } = this.props;
		const { getFieldDecorator } = form;

		return (
			<Form layout="vertical">
				{getFieldDecorator('couponForm', { initialValue: 'true' })(<Input hidden />)}
				<Form.Item>
					<div className='form-control'>
						<span className='form-control-title'>Name</span>
						{getFieldDecorator('name', {
							rules: [{ required: true, message: "Name is Required" }]
						})(<Input className='form-input-main' style={{ width: '100%' }} type="text" />)}
					</div>
				</Form.Item>
				<Form.Item>
					<div className='form-control'>
						<span className='form-control-title'>Code</span>
						{getFieldDecorator('code', {
							rules: [{ required: true, message: "Code is Required" }]
						})(<Input className='form-input-main' style={{ width: '100%' }} type="text" />)}
					</div>
				</Form.Item>
				<Form.Item>
					<div className='form-control'>
						<span className='form-control-title'>Retailer</span>
						{getFieldDecorator('retailer', {
							rules: [{ required: true, message: "Retailer is Required" }]
						})(
							<Select
								showSearch
								className='form-input-main'
								style={{ width: '97%', paddingTop: '21px' }}
								placeholder="Retailer"
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							>
								{this.state.retailers.map((retailer) => {
									return (
										<Option value={retailer.id}>{retailer.name}</Option>
									);
								})}
							</Select>
						)}
					</div>
				</Form.Item>
				<Form.Item>
					<Row type='flex' justify='center'>
					<Button className='submit' style={{ border: 0 }} key="submit" type="primary" onClick={this.props.handleOk}><b>SUBMIT</b></Button>
					</Row>
				</Form.Item>
			</Form>
		);
	}
}

const couponForm = Form.create({ name: 'couponForm' })(Coupon);

export default couponForm;
