import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import {
    Row,
    Col,
    Button,
    notification,
    Modal,
    Card,
    Drawer,
    Dropdown,
    Menu,
    Avatar,
} from 'antd';

import CatTable from '../tables/category';
import Category from '../modals/category';
import config from '../config';

export default class CategoryPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			categories: [],
			visible: false,
			new: 1,
			expanded: false,
			confirmLoading: false
		}
	}

	componentDidMount = () => {
		this.fetchCategories();
	}

	componentWillUnmount = () => {
		this.setState({ expanded: false })
	}

	fetchCategories = () => {
		fetch(`${config.api.url}/category/getmNodes`).then((response) => response.json()).then((data) => {
			if (data.suc) {
				this.setState({ categories: data.dat.categories }, () => {
					var rows = Array.from(document.getElementsByTagName("table")[0].rows);
					if (!this.state.expanded) {
						rows.forEach(row => {
							row.click();
						});
						this.setState({ expanded: true });
					}
				});
			} else {
				notification['error']({
					message: data.msg
				});
			}
		}).catch((error) => {
			console.error('Error:', error);
		});
	}

	getForm = (childForm) => {
		this.setState({
			childForm: childForm
		});
	}

	getState = (childState) => {
		this.setState({
			childState: childState
		});
	}

	getCats = (childCats) => {
		this.setState({
			childCats: childCats
		});
	}

	showModal = () => {
		this.setState({
			modalTitle: "Add a Category",
			modalContent: <Category handleOk={this.handleOk} setCats={this.getCats} setForm={this.getForm} setState={this.getState} />
		}, () => {
			this.setState({ visible: true });
		});
	};

	handleOk = () => {
		const { childForm } = this.state;
		this.setState({
			confirmLoading: true
		});
		childForm.validateFields((err, values) => {
			if (err) {
				this.setState({
					confirmLoading: false,
					visible: true
				});
				return;
			} else {
				var payload = {
					name: values.name,
					slug: values.slug,
					title: values.title,
					description: values.description,
					main: values.img1path,
					featured: values.img2path,
					parent: values.parent
				}
				fetch(`${config.api.url}/category/pst`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payload),
				}).then((response) => response.json()).then((data) => {
					if (data.suc) {
						this.setState({
							confirmLoading: false,
							visible: false
						});
						notification['success']({
							message: data.msg
						});
						childForm.resetFields();
						this.state.childCats();
						this.state.childState.setState({fileList1: [], fileList2: []});
						this.fetchCategories();
					} else {
						this.setState({
							confirmLoading: false,
							visible: false
						});
						notification['error']({
							message: data.msg
						});
					}
				}).catch((error) => {
					console.error('Error:', error);
				});
			}
		});
	};

	handleCancel = () => {
		this.setState({
			visible: false
		});
	};

	logout = () => {
		localStorage.clear();
		this.props.history.push("/login");
	}

	render = () => {
		const { visible, confirmLoading, modalContent } = this.state;
		return <>
            <Drawer
                title={this.state.modalTitle}
                visible={visible}
                onClose={this.handleCancel}
                footer={null}
                width={'30vw'}
                style={{ borderRadius: "10px" }}
            >
                {modalContent}
            </Drawer>
            <Row type="flex" justify="space-between">
                <Col>
                    <h1 style={{ fontWeight: "bold" }}>Manage Categories</h1>
                </Col>
                <Col>
                    <Dropdown overlay={
                        <Menu>
                            <Menu.Item key="0" onClick={this.logout}>
                                <a>Log Out</a>
                            </Menu.Item>
                        </Menu>
                    } trigger={['click']}>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <Avatar size={32} icon={<UserOutlined style={{ marginRight: 0 }} />} />
                        </a>
                    </Dropdown>
                </Col>
            </Row>
            <br />
            <Row type="flex" justify="center">
                <Col span={24}>
                    <Card className="product-card">
                        <Row justify="space-between" type="flex">
                            <Col>
                                <h3 style={{fontSize: 24}}>Categories</h3>
                            </Col>
                            <Col>
                                <Button className='submit' type="primary" style={{ width: 150, height: 50, border: 0 }} onClick={this.showModal}><b>Add New</b></Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row type="flex" justify="center">
                <Col span={24}>
                    <Card className="product-card">
                        <CatTable history={this.props.history} categories={this.state.categories} fetch={this.fetchCategories} />
                    </Card>
                </Col>
            </Row>
        </>;
	}
}
