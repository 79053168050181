import React from 'react';
import { Layout, Row } from 'antd';
import bg from '../images/bg.svg';

export default class Blank extends React.Component {
	render = () => {
		return (
			<Layout style={{ height: "100vh", backgroundImage: `url(${bg})`, backgroundSize: 'cover' }}>
				<Row type="flex" justify="center" style={{ marginTop: "180px" }}>
					<h1 style={{ fontSize: "40px" }}>Affiliate Deals</h1>
				</Row> {this.props.children}
			</Layout>
		);
	}
}
