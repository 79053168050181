import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import {
    Row,
    Col,
    Button,
    notification,
    Modal,
    Card,
    Drawer,
    Dropdown,
    Menu,
    Avatar,
} from 'antd';

import CoupTable from '../tables/coupon';
import Coupon from '../modals/coupon';
import config from '../config';

export default class CouponPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			coupons: [],
			visible: false,
			confirmLoading: false
		}
	}

	componentDidMount = () => {
		this.fetchCoupons();
	}

	fetchCoupons = () => {
		fetch(`${config.api.url}/coupon/get/?all=true`).then((response) => response.json()).then((data) => {
			if (data.suc) {
				this.setState({ coupons: data.dat.coupons });
			} else {
				notification['error']({
					message: data.msg
				});
			}
		}).catch((error) => {
			console.error('Error:', error);
		});
	}

	getForm = (childForm) => {
		this.setState({
			childForm: childForm
		});
	}

	showModal = () => {
		this.setState({
			modalTitle: "Add a Coupon",
			modalContent: <Coupon handleOk={this.handleOk} setForm={this.getForm} />
		}, () => {
			this.setState({ visible: true });
		});
	};

	handleOk = () => {
		const { childForm } = this.state;
		this.setState({
			confirmLoading: true
		});
		childForm.validateFields((err, values) => {
			if (err) {
				this.setState({
					confirmLoading: false,
					visible: true
				});
				return;
			} else {
				var payload = {
					name: values.name,
					code: values.code,
					retailer: values.retailer
				}
				fetch(`${config.api.url}/coupon/pst`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payload),
				}).then((response) => response.json()).then((data) => {
					if (data.suc) {
						this.setState({
							confirmLoading: false,
							visible: false
						});
						notification['success']({
							message: data.msg
						});
						childForm.resetFields();
						this.fetchCoupons();
					} else {
						this.setState({
							confirmLoading: false,
							visible: false
						});
						notification['error']({
							message: data.msg
						});
					}
				}).catch((error) => {
					console.error('Error:', error);
				});
			}
		});
	};

	handleCancel = () => {
		this.setState({
			visible: false
		});
	};

	logout = () => {
		localStorage.clear();
		this.props.history.push("/login");
	}

	render = () => {
		const { visible, confirmLoading, modalContent } = this.state;
		return <>
            <Drawer
                title={this.state.modalTitle}
                visible={visible}
                onOk={this.handleOk}
                onClose={this.handleCancel}
                footer={null}
                width={'30vw'}
                style={{ borderRadius: "10px" }}
            >
                {modalContent}
            </Drawer>
            <Row type="flex" justify="space-between">
                <Col>
                    <h1 style={{ fontWeight: "bold" }}>Manage Coupons</h1>
                </Col>
                <Col>
                    <Dropdown overlay={
                        <Menu>
                            <Menu.Item key="0" onClick={this.logout}>
                                <a>Log Out</a>
                            </Menu.Item>
                        </Menu>
                    } trigger={['click']}>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <Avatar size={32} icon={<UserOutlined style={{ marginRight: 0 }} />} />
                        </a>
                    </Dropdown>
                </Col>
            </Row>
            <br />
            <Row type="flex" justify="center">
                <Col span={24}>
                    <Card className="product-card">
                        <Row justify="space-between" type="flex">
                            <Col>
                                <h3 style={{fontSize: 24}}>Coupons</h3>
                            </Col>
                            <Col>
                                <Button className='submit' type="primary" style={{ width: 150, height: 50, border: 0 }} onClick={this.showModal}><b>Add New</b></Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row type="flex" justify="center">
                <Col span={24}>
                    <Card className="product-card">
                        <CoupTable coupons={this.state.coupons} fetch={this.fetchCoupons} />
                    </Card>
                </Col>
            </Row>
        </>;
	}
}
