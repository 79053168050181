import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, notification, Checkbox, Button, Row } from 'antd';

import config from '../config';

const { Option } = Select;

const opts = [
	{ label: "Create", value: 1 },
	{ label: "Read", value: 2 },
	{ label: "Update", value: 3 },
	{ label: "Delete", value: 4 }
];

class Admin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			admins: [],
			admin: false,
			display: ""
		}
	}

	onChange = () => {
		if (this.state.admin) {
			this.setState({ admin: false, display: '' });
			this.props.form.resetFields(["role", "proPer", "catPer"]);
		} else {
			this.setState({ admin: true, display: 'none' });
			this.props.form.setFieldsValue({
				role: "admin",
				proPer: opts,
				catPer: opts
			})
		}
	}

	componentDidMount() {
		//this.fetchAdmins();
		if (this.props.id && this.props.name) {
			this.props.form.setFieldsValue({
				name: this.props.name,
				email: this.props.slug,
				role: this.props.role
			});
		}
		this.props.setForm(this.props.form);
	}

	fetchAdmins = () => {
		fetch(`${config.api.url}/admin/get?all=true`).then((response) => response.json()).then((data) => {
			if (data.suc) {
				this.setState({ admins: data.dat.admins });
			} else {
				notification['error']({
					message: data.msg
				});
			}
		}).catch((error) => {
			console.error('Error:', error);
		});
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.id !== this.props.id) {
			this.props.form.setFieldsValue({
				name: nextProps.name,
				email: nextProps.slug,
				role: nextProps.role
			});
		}
	}

	render() {
		const { form } = this.props;
		const { getFieldDecorator } = form;

		return (
			<Form layout="vertical">
				{getFieldDecorator('adminForm', { initialValue: 'true' })(<Input hidden />)}
				<Form.Item>
					<div className='form-control'>
						<span className='form-control-title'>Name</span>
						{getFieldDecorator('name', {
							rules: [{ required: true, message: "Name is Required" }]
						})(<Input className='form-input-main' style={{ width: '100%' }} type="text" />)}
					</div>
				</Form.Item>
				<Form.Item>
					<div className='form-control'>
						<span className='form-control-title'>Email</span>
						{getFieldDecorator('email', {
							rules: [{ required: true, message: "Email is Required" }]
						})(<Input className='form-input-main' style={{ width: '100%' }} type="text" />)}
					</div>
				</Form.Item>
				<Form.Item>
					<div className='form-control'>
						<span className='form-control-title'>Password</span>
						{getFieldDecorator('password', {
							rules: [{ required: true, message: "Password is Required" }]
						})(<Input className='form-input-main' style={{ width: '100%' }} type="password" />)}
					</div>
				</Form.Item>
				<Form.Item>
					<Checkbox onChange={this.onChange}>Make Super Admin?</Checkbox>
				</Form.Item>
				<div style={{ display: this.state.display }}>
					<Form.Item>
						<div className='form-control'>
							<span className='form-control-title'>Custom Role</span>
							{getFieldDecorator('role', {
								rules: [{ required: true, message: "Role is Required" }]
							})(<Input className='form-input-main' style={{ width: '100%' }} type="text" />)}
						</div>
					</Form.Item>
					<Form.Item label="Product Permissions"> <br/>
						{getFieldDecorator('proPer', {
							rules: [{ required: false, message: "Role is Required" }]
						})(<Checkbox.Group options={opts} onChange={this.onProChange} />)}
					</Form.Item>
					<Form.Item label="Category Permissions"> <br/>
						{getFieldDecorator('catPer', {
							rules: [{ required: false, message: "Role is Required" }]
						})(<Checkbox.Group options={opts} onChange={this.onCatChange} />)}
					</Form.Item>
				</div>
				<Form.Item>
					<Row type='flex' justify='center'>
						<Button className='submit' style={{ border: 0 }} key="submit" type="primary" loading={this.props.confirmLoading} onClick={this.props.handleOk}><b>SUBMIT</b></Button>
					</Row>
				</Form.Item>
			</Form>
		);
	}
}

const adminForm = Form.create({ name: 'adminForm' })(Admin);

export default adminForm;
