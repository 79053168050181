import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import {
	Row,
	Col,
	Button,
	notification,
	Modal,
	Card,
	Drawer,
	Dropdown,
	Menu,
	Avatar,
} from 'antd';

import SlideTable from '../tables/slider';
import Slider from '../modals/slider';
import config from '../config';

export default class SliderPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sliders: [],
			visible: false,
			confirmLoading: false
		}
	}

	componentDidMount = () => {
		this.fetchSliders();
	}

	fetchSliders = () => {
		fetch(`${config.api.url}/slider/get/?all=true`).then((response) => response.json()).then((data) => {
			if (data.suc) {
				this.setState({ sliders: data.dat.sliders });
			} else {
				notification['error']({
					message: data.msg
				});
			}
		}).catch((error) => {
			console.error('Error:', error);
		});
	}

	getForm = (childForm) => {
		this.setState({
			childForm: childForm
		});
	}

	showModal = () => {
		this.setState({
			modalTitle: "Add a Slider",
			modalContent: <Slider handleOk={this.handleOk} setForm={this.getForm} n={Math.random()} />
		}, () => {
			this.setState({ visible: true });
		});
	};

	handleOk = () => {
		const { childForm } = this.state;
		this.setState({
			confirmLoading: true
		});
		childForm.validateFields((err, values) => {
			if (err) {
				this.setState({
					confirmLoading: false,
					visible: true
				});
				return;
			} else {
				var payload = {
					name: values.name,
					tag: values.tag,
					url: values.url,
					title: values.title,
					color: values.colorcode,
					image: values.imgpath,
					productId: values.product,
					buttonText: values.btext,
					productImage: values.proimgpath
				}
				fetch(`${config.api.url}/slider/pst`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payload),
				}).then((response) => response.json()).then((data) => {
					if (data.suc) {
						this.setState({
							confirmLoading: false,
							visible: false
						});
						notification['success']({
							message: data.msg
						});
						childForm.resetFields();
						this.fetchSliders();
					} else {
						this.setState({
							confirmLoading: false,
							visible: false
						});
						notification['error']({
							message: data.msg
						});
					}
				}).catch((error) => {
					console.error('Error:', error);
				});
			}
		});
	};

	handleCancel = () => {
		this.setState({
			visible: false
		});
	};

	logout = () => {
		localStorage.clear();
		this.props.history.push("/login");
	}

	render = () => {
		const { visible, confirmLoading, modalContent } = this.state;
		return <>
			<Drawer
				title={this.state.modalTitle}
				visible={visible}
				onOk={this.handleOk}
				onClose={this.handleCancel}
				footer={null}
				width={'30vw'}
				style={{ borderRadius: "10px" }}
			>
				{modalContent}
			</Drawer>
			<Row type="flex" justify="space-between">
				<Col>
					<h1 style={{ fontWeight: "bold" }}>Manage Sliders</h1>
				</Col>
				<Col>
					<Dropdown overlay={
						<Menu>
							<Menu.Item key="0" onClick={this.logout}>
								<a>Log Out</a>
							</Menu.Item>
						</Menu>
					} trigger={['click']}>
						<a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
							<Avatar size={32} icon={<UserOutlined style={{ marginRight: 0 }} />} />
						</a>
					</Dropdown>
				</Col>
			</Row>
			<br />
			<Row type="flex" justify="center">
				<Col span={24}>
					<Card className="product-card">
						<Row justify="space-between" type="flex">
							<Col>
								<h3 style={{ fontSize: 24 }}>Sliders</h3>
							</Col>
							<Col>
								<Button className='submit' type="primary" style={{ width: 150, height: 50, border: 0 }} onClick={this.showModal}><b>Add New</b></Button>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			<br />
			<Row type="flex" justify="center">
				<Col span={24}>
					<Card className="product-card">
						<SlideTable sliders={this.state.sliders} fetch={this.fetchSliders} />
					</Card>
				</Col>
			</Row>
		</>;
	}
}
