import React from 'react';
import { Divider, notification, Table, Modal, Button, Drawer } from "antd";

import config from '../config';
import Retailer from '../modals/retailer';

export default class RetTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			confirmLoading: false
		}
	}

	getForm = (childForm) => {
		this.setState({
			childForm: childForm
		});
	}

	showModal = (id, name) => {
		this.setState({
			id: id,
			modalTitle: "Edit a Retailer",
			modalContent: <Retailer id={id} name={name} handleOk={this.handleOk} setForm={this.getForm} />
		}, () => {
			this.setState({ visible: true });
		});
	};

	handleOk = () => {
		const { childForm } = this.state;
		this.setState({
			confirmLoading: true
		});
		childForm.validateFields((err, values) => {
			if (err) {
				this.setState({
					confirmLoading: false,
					visible: true
				});
				return;
			} else {
				var payload = {
					rid: this.state.id,
					name: values.name,
				}
				fetch(`${config.api.url}/retailer/put`, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payload),
				}).then((response) => response.json()).then((data) => {
					if (data.suc) {
						this.setState({
							confirmLoading: false,
							visible: false
						});
						this.props.fetch();
					} else {
						this.setState({
							confirmLoading: false,
							visible: false
						});
						notification['error']({
							message: data.msg
						});
					}
				}).catch((error) => {
					console.error('Error:', error);
				});
			}
		});
	};

	handleCancel = () => {
		this.setState({
			visible: false
		});
	};

	onClose = () => {
		this.setState({
			visible: false,
		});
	};

	delRet(id) {
		fetch(`${config.api.url}/retailer/dlt?rid=${id}`,
			{
				method: 'delete'
			}).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.props.fetch();
					notification['success']({
						message: data.msg
					});
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
	}

	retailer = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			align: "center",
		},
		{
			title: "Actions",
			key: "actions",
			align: "center",
			render: (text, record) => (
				<span>
					{(record.id !== 1 && record.id !== 2) && (
						<>
							{/* <a onClick={() => { this.showModal(record.id, record.name, record.slug, record.parent) }}>edit</a>
                            <Divider type="vertical" /> */}
							<a onClick={() => { this.delRet(record.id) }}>delete</a>
						</>
					)}
				</span>
			)
		}
	];

	render = () => {
		const { visible, confirmLoading, modalContent } = this.state;
		return (
			<>
				<Drawer
					title={<b>Edit a Retailer</b>}
					width={"30vw"}
					onClose={this.onClose}
					visible={visible}
					style={{ borderRadius: "10px" }}
				>
					{modalContent}
				</Drawer>
				<Table rowKey={record => record.id} bordered size="middle" columns={this.retailer} dataSource={this.props.retailers} />
			</>
		);
	}
}
