import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { PictureOutlined } from '@ant-design/icons';
import { Input, Select, notification, Button, Row, Upload, message } from 'antd';
import { SketchPicker } from 'react-color';
import config from '../config';

const { Option } = Select;

class Slider extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sliders: [],
			products: [],
			tags: [],
			fileList: [],
			background: '#000',
		}
	}

	fetchProducts = () => {
		fetch(`${config.api.url}/product/get?all=true`).then((response) => response.json()).then((data) => {
			if (data.suc) {
				this.setState({ products: data.dat.products });
			} else {
				notification['error']({
					message: data.msg
				});
			}
		}).catch((error) => {
			console.error('Error:', error);
		});
	}

	fetchTags = () => {
		fetch(`${config.api.url}/tag/get?all=true`).then((response) => response.json()).then((data) => {
			if (data.suc) {
				this.setState({ tags: data.dat.tags });
			} else {
				notification['error']({
					message: data.msg
				});
			}
		}).catch((error) => {
			console.error('Error:', error);
		});
	}

	componentDidMount() {
		this.fetchProducts();
		this.fetchTags();
		if(this.props.slider) {
			this.props.form.setFieldsValue({
				product: this.props.slider.productId,
				tag: this.props.slider.tag,
				url: this.props.slider.url,
				name: this.props.slider.name,
				title: this.props.slider.title,
				btext: this.props.slider.buttonText,
				colorcode: this.props.slider.color,
				imgpath: this.props.slider.image,
				proimgpath: this.props.slider.productImage
			});
		}
		this.props.setForm(this.props.form);
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.n !== this.props.n) {
			this.setState({fileList: []})
		}
		if (nextProps.id !== this.props.id) {
			this.props.form.setFieldsValue({
				product: nextProps.slider.productId,
			tag: nextProps.slider.tag,
			url: nextProps.slider.url,
			name: nextProps.slider.name,
			title: nextProps.slider.title,
			btext: nextProps.slider.buttonText,
			colorcode: nextProps.slider.color,
			imgpath: nextProps.slider.image,
			proimgpath: nextProps.slider.productImage
			});
		}
	}

	handleChangeComplete = (color) => {
		this.setState({ background: color.hex })
		this.props.form.setFieldsValue({ colorcode: color.hex });
	};

	onChange = (i) => {
		this.props.form.setFieldsValue({
			url: this.state.products.find(x => x.id === i).url,
			proimgpath: this.state.products.find(x => x.id === i).image
		})
	}

	handleChange = info => {
		let fileList = [...info.fileList];
		fileList = fileList.slice(-1);

		fileList = fileList.map(file => {
			if (file.response) {
				file.url = file.response.url;
			}
			return file;
		});

		const { status } = info.file;

		if (status !== 'uploading') {
			// console.log(info.file, info.fileList);
		}
		if (status === 'done') {
			// console.log(info.file.response.data)
			this.props.form.setFieldsValue({
				imgpath: info.file.response.data
			});
			message.success(`${info.file.name} file uploaded successfully.`);
		} else if (status === 'error') {
			message.error(`${info.file.name} file upload failed.`);
		}

		this.setState({ fileList: fileList });
	};

	render() {
		const { form } = this.props;
		const { getFieldDecorator } = form;

		const props = {
			name: 'image',
			multiple: false,
			action: `${config.api.url}/slider/upload`,
			onChange: this.handleChange,
			listType: 'picture',
		};

		return (
			<Form layout="vertical">
				{getFieldDecorator('sliderForm', { initialValue: 'true' })(<Input hidden />)}
				<Form.Item>
					<div className='form-control'>
						<span className='form-control-title'>Select a Product</span>
						{getFieldDecorator('product', {
							rules: [{ required: true, message: "Product is Required" }]
						})(
							<Select
								showSearch
								className='form-input-main'
								style={{ width: '97%', paddingTop: '21px' }}
								placeholder="Product"
								onChange={this.onChange}
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							>
								{this.state.products.map((product) => {
									return (
										<Option value={product.id}>{product.name}</Option>
									);
								})}
							</Select>
						)}
					</div>
				</Form.Item>
				<Form.Item>
					<div className='form-control'>
						<span className='form-control-title'>Select a Tag</span>
						{getFieldDecorator('tag', {
							rules: [{ required: true, message: "Tag is Required" }]
						})(
							<Select
								showSearch
								className='form-input-main'
								style={{ width: '97%', paddingTop: '21px' }}
								placeholder="Tag"
								optionFilterProp="children"
								filterOption={(input, option) =>
									option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
								}
							>
								{this.state.tags.map((tag) => {
									return (
										<Option value={tag.id}>{tag.name}</Option>
									);
								})}
							</Select>
						)}
					</div>
				</Form.Item>
				<Form.Item>
					<div className='form-control'>
						<span className='form-control-title'>Url</span>
						{getFieldDecorator('url', {
							rules: [{ required: true, message: "Url is Required" }]
						})(<Input className='form-input-main' style={{ width: '100%' }} type="text" />)}
					</div>
				</Form.Item>
				<Form.Item>
					<div className='form-control'>
						<span className='form-control-title'>Name</span>
						{getFieldDecorator('name', {
							rules: [{ required: true, message: "Name is Required" }]
						})(<Input className='form-input-main' style={{ width: '100%' }} type="text" />)}
					</div>
				</Form.Item>
				<Form.Item>
					<div className='form-control'>
						<span className='form-control-title'>Title</span>
						{getFieldDecorator('title', {
							rules: [{ required: true, message: "title is Required" }]
						})(<Input className='form-input-main' style={{ width: '100%' }} type="text" />)}
					</div>
				</Form.Item>
				<Form.Item>
					<div className='form-control'>
						<span className='form-control-title'>Button Text</span>
						{getFieldDecorator('btext', {
							rules: [{ required: true, message: "Text is Required" }]
						})(<Input className='form-input-main' style={{ width: '100%' }} type="text" />)}
					</div>
				</Form.Item>
				<Form.Item label="Color">
						{getFieldDecorator('color')(<SketchPicker
							color={this.state.background}
							onChangeComplete={this.handleChangeComplete} />)}
					</Form.Item>
					{getFieldDecorator('colorcode', {
							rules: [{ required: true, message: "Color Code is Required" }]
						})(<Input hidden />)}
				<Form.Item>
				<Form.Item>
				<div className='form-control' style={{ cursor: 'pointer', borderStyle: 'dashed', borderWidth: '2px' }}>
						<span className='form-control-title'>Image</span>
						<div style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '20px', paddingBottom: 0 }}>
					{getFieldDecorator('image', {
						rules: [{ required: false, message: "Image is Required" }]
					})(
						<Upload className='form-input-main' style={{ margin: 'auto' }} fileList={this.state.fileList} {...props}>
						{this.state.fileList.length >= 1 ? null : (<><PictureOutlined ref={this.imgRef} /> Click Here to Upload New</>)}
						</Upload>
					)}
					</div>
					</div>
				</Form.Item>
				{getFieldDecorator('imgpath')(<Input hidden />)}
				{getFieldDecorator('proimgpath')(<Input hidden />)}
					<Row type='flex' justify='center'>
					<Button className='submit' style={{ border: 0 }} key="submit" type="primary" onClick={this.props.handleOk}><b>SUBMIT</b></Button>
					</Row>
				</Form.Item>
			</Form>
		);
	}
}

const sliderForm = Form.create({ name: 'sliderForm' })(Slider);

export default sliderForm;
