import React from 'react';
import { PictureOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, notification, Button, Upload, message, Row } from 'antd';
import * as slugify from 'slugify';

import config from '../config';

const { Option } = Select;
const { Dragger } = Upload;

class Category extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			categories: [],
			fileList1: [],
			fileList2: []
		}
	}

	mainRef = React.createRef();
	featRef = React.createRef();

	componentDidMount() {
		if (!this.props.parent) {
			this.fetchCategories(this.props.id, this.props.name, this.props.slug, null, this.props.title, this.props.description);
		} else {
			this.fetchCategories(this.props.id, this.props.name, this.props.slug, this.props.parent, this.props.title, this.props.description);
		}
		this.props.setForm(this.props.form);
		this.props.setState(this);
		this.props.setCats(this.fetchAllCategories);
	}

	fetchCategories = (id, name, slug, parent, title, description) => {
		fetch(`${config.api.url}/category/get/?all=true&parent=true`).then((response) => response.json()).then((data) => {
			if (data.suc) {
				this.setState({ categories: data.dat.categories }, () => {
					const index = this.state.categories.indexOf(this.state.categories.find(item => item.id === id));
					if (index > -1) {
						this.state.categories.splice(index, 1);
						this.setState({ categories: this.state.categories }, () => {
							this.props.form.setFieldsValue({
								name: name,
								slug: slug,
								title: title,
								description: description
							});
							if (parent) {
								this.props.form.setFieldsValue({
									parent: parent
								});
							} else {
								this.props.form.resetFields("parent");
							}
						});
					} else {
						this.props.form.setFieldsValue({
							name: name,
							slug: slug,
							title: title,
							description: description
						});
						if (parent) {
							this.props.form.setFieldsValue({
								parent: parent
							});
						} else {
							this.props.form.resetFields("parent");
						}
					}
				});
			} else {
				notification['error']({
					message: data.msg
				});
			}
		}).catch((error) => {
			console.error('Error:', error);
		});
	}

	fetchAllCategories = () => {
		fetch(`${config.api.url}/category/get/?all=true&parent=true`).then((response) => response.json()).then((data) => {
			if (data.suc) {
				this.props.form.resetFields();
				this.setState({ categories: data.dat.categories });
			} else {
				notification['error']({
					message: data.msg
				});
			}
		}).catch((error) => {
			console.error('Error:', error);
		});
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.id !== this.props.id) {
			if (!nextProps.parent) {
				this.fetchCategories(nextProps.id, nextProps.name, nextProps.slug, null, nextProps.title, nextProps.description);
			} else {
				this.fetchCategories(nextProps.id, nextProps.name, nextProps.slug, nextProps.parent, nextProps.title, nextProps.description);
			}
		}
	}

	handleChange1 = info => {
		let fileList = [...info.fileList];
		fileList = fileList.slice(-1);

		fileList = fileList.map(file => {
			if (file.response) {
				file.url = file.response.url;
			}
			return file;
		});

		const { status } = info.file;

		if (status !== 'uploading') {
			// console.log(info.file, info.fileList);
		}
		if (status === 'done') {
			this.setState({ imgPth1: info.file.response.data });
			// console.log(info.file.response.data)
			this.props.form.setFieldsValue({
				img1path: info.file.response.data
			});
			message.success(`${info.file.name} file uploaded successfully.`);
		} else if (status === 'error') {
			message.error(`${info.file.name} file upload failed.`);
		}

		this.setState({ fileList1: fileList });
	};

	handleChange2 = info => {
		let fileList = [...info.fileList];
		fileList = fileList.slice(-1);

		fileList = fileList.map(file => {
			if (file.response) {
				file.url = file.response.url;
			}
			return file;
		});

		const { status } = info.file;

		if (status !== 'uploading') {
			// console.log(info.file, info.fileList);
		}
		if (status === 'done') {
			this.props.form.setFieldsValue({
				img2path: info.file.response.data
			});
			message.success(`${info.file.name} file uploaded successfully.`);
		} else if (status === 'error') {
			message.error(`${info.file.name} file upload failed.`);
		}

		this.setState({ fileList2: fileList });
	};

	render() {
		const { form } = this.props;
		const { getFieldDecorator } = form;

		const props1 = {
			name: 'image',
			multiple: false,
			action: `${config.api.url}/category/upload`,
			onChange: this.handleChange1,
			listType: 'picture-card',
		};

		const props2 = {
			name: 'image',
			multiple: false,
			action: `${config.api.url}/category/upload`,
			onChange: this.handleChange2,
			listType: 'picture-card',
		};

		return (
			<Form layout="vertical">
				{getFieldDecorator('categoryForm', { initialValue: 'true' })(<Input hidden />)}
				<Form.Item>
					<div className='form-control'>
						<span className='form-control-title'>Name</span>
						{getFieldDecorator('name', {
							rules: [{ required: true, message: "Name is Required" }]
						})(<Input onChange={(e)=>{this.props.form.setFieldsValue({slug: slugify(e.target.value)})}} style={{ width: '100%' }} className='form-input-main' type="text" />)}
					</div>
				</Form.Item>
				<Form.Item>
					<div className='form-control'>
						<span className='form-control-title'>Slug</span>
						{getFieldDecorator('slug', {
							rules: [{ required: true, message: "Slug is Required" }]
						})(<Input style={{ width: '100%' }} className='form-input-main' type="text" />)}
					</div>
					<br /><p><b>Link</b>: www.igeeks.com/{this.props.form.getFieldValue("slug")}</p>
				</Form.Item>
				<Form.Item>
					<div className='form-control'>
						<span className='form-control-title'>Title</span>
						{getFieldDecorator('title', {
							rules: [{ required: true, message: "Title is Required" }]
						})(<Input style={{ width: '100%' }} className='form-input-main' type="text" />)}
					</div>
				</Form.Item>
				<Form.Item>
					<div className='form-control'>
						<span className='form-control-title'>Description</span>
						{getFieldDecorator('description', {
							rules: [{ required: true, message: "Description is Required" }]
						})(<Input style={{ width: '100%' }} className='form-input-main' type="text" />)}
					</div>
				</Form.Item>
				<Form.Item>
					<div className='form-control'>
						<span className='form-control-title'>Parent</span>
						{getFieldDecorator('parent', {
							rules: [{ required: false, message: "Parent is Required" }]
						})(
							<Select style={{ width: "97%", paddingTop: '21px' }} className='form-input-main' placeholder="Select a Category">
								{this.state.categories.map((category, index) => {
									return (
										<Option key={index} value={category.id}>{category.name}</Option>
									);
								})}
							</Select>
						)}
					</div>
				</Form.Item>
				<Form.Item>
					<div /* onClick={() => { this.mainRef.current.click() }} */ className='form-control' style={{ /* cursor: 'pointer', */ borderStyle: 'dashed', borderWidth: '2px' }}>
						<span className='form-control-title'>Main</span>
						<div style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '20px', paddingBottom: 0 }}>
							{getFieldDecorator('image1', {
								rules: [{ required: false, message: "Image is Required" }]
							})(
								<Upload className='form-input-main' style={{ margin: 'auto' }} fileList={this.state.fileList1} {...props1}>
									{this.state.fileList1.length >= 1 ? null : (<><PictureOutlined ref={this.mainRef} /> <br/> Click Here to Upload New</>)}
								</Upload>
							)}
						</div>
					</div>
				</Form.Item>
				{getFieldDecorator('img1path', { initialValue: '' })(<Input hidden />)}
				<Form.Item>
					<div /* onClick={() => { this.featRef.current.click() }} */ className='form-control' style={{ /* cursor: 'pointer', */ borderStyle: 'dashed', borderWidth: '2px' }}>
						<span className='form-control-title'>Featured</span>
						<div style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '20px', paddingBottom: 0 }}>
							{getFieldDecorator('image2', {
								rules: [{ required: false, message: "Image is Required" }]
							})(
								<Upload className='form-input-main' style={{ margin: 'auto' }} fileList={this.state.fileList2} {...props2}>
									{this.state.fileList2.length >= 1 ? null : (<><PictureOutlined ref={this.featRef} /><br/> Click Here to Upload New</>)}
								</Upload>
							)}
						</div>
					</div>
				</Form.Item>
				{getFieldDecorator('img2path', { initialValue: '' })(<Input hidden />)}
				<Form.Item>
					<Row type='flex' justify='center'>
						<Button style={{ border: 0 }} className='submit' key="submit" type="primary" onClick={this.props.handleOk}><b>SUBMIT</b></Button>
					</Row>
				</Form.Item>
			</Form>
		);
	}
}

const categoryForm = Form.create({ name: 'categoryForm' })(Category);

export default categoryForm;
