import React from 'react';
import { PictureOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, notification, Button, message, Upload, Row } from 'antd';
import * as slugify from 'slugify';

import config from '../config';

const { Option } = Select;
const { Dragger } = Upload;

class Brand extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			brands: [],
			fileList: []
		}
	}

	imgRef = React.createRef();

	componentDidMount() {
		this.props.form.setFieldsValue({
			name: this.props.name,
			slug: this.props.slug
		});
		this.props.setForm(this.props.form);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.id !== this.props.id) {
			this.props.form.setFieldsValue({
				name: nextProps.name,
				slug: nextProps.slug
			});
		}
	}

	handleChange = info => {
		let fileList = [...info.fileList];
		fileList = fileList.slice(-1);

		fileList = fileList.map(file => {
			if (file.response) {
				file.url = file.response.url;
			}
			return file;
		});

		const { status } = info.file;

		if (status !== 'uploading') {
			// console.log(info.file, info.fileList);
		}
		if (status === 'done') {
			// console.log(info.file.response.data)
			this.props.form.setFieldsValue({
				imgpath: info.file.response.data
			});
			message.success(`${info.file.name} file uploaded successfully.`);
		} else if (status === 'error') {
			message.error(`${info.file.name} file upload failed.`);
		}

		this.setState({ fileList: fileList });
	};

	render() {
		const { form } = this.props;
		const { getFieldDecorator } = form;

		const props = {
			name: 'image',
			multiple: false,
			action: `${config.api.url}/brand/upload`,
			onChange: this.handleChange,
			listType: 'picture',
		};

		return (
			<Form layout="vertical">
				{getFieldDecorator('brandForm', { initialValue: 'true' })(<Input hidden />)}
				<Form.Item>
					<div className='form-control'>
						<span className='form-control-title'>Name</span>
						{getFieldDecorator('name', {
							rules: [{ required: true, message: "Name is Required" }]
						})(<Input onChange={(e)=>{this.props.form.setFieldsValue({slug: slugify(e.target.value)})}} className='form-input-main' style={{ width: '100%' }} type="text" />)}
					</div>
				</Form.Item>
				<Form.Item>
					<div className='form-control'>
						<span className='form-control-title'>Slug</span>
						{getFieldDecorator('slug', {
							rules: [{ required: true, message: "Slug is Required" }]
						})(<Input className='form-input-main' style={{ width: '100%' }} type="text" />)}
					</div>
				</Form.Item>
				<Form.Item>
				<div /* onClick={() => { this.imgRef.current.click() }} */ className='form-control' style={{ /* cursor: 'pointer', */ borderStyle: 'dashed', borderWidth: '2px' }}>
						<span className='form-control-title'>Image</span>
						<div style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '20px', paddingBottom: 0 }}>
					{getFieldDecorator('image', {
						rules: [{ required: false, message: "Image is Required" }]
					})(
						<Upload className='form-input-main' style={{ margin: 'auto' }} fileList={this.state.fileList} {...props}>
						{this.state.fileList.length >= 1 ? null : (<><PictureOutlined ref={this.imgRef} /> Click Here to Upload New</>)}
						</Upload>
					)}
					</div>
					</div>
				</Form.Item>
				{getFieldDecorator('imgpath')(<Input hidden />)}
				<Form.Item>
					<Row type='flex' justify='center'>
						<Button className='submit' style={{ border: 0 }} key="submit" type="primary" onClick={this.props.handleOk}><b>SUBMIT</b></Button>
					</Row>
				</Form.Item>
			</Form>
		);
	}
}

const brandForm = Form.create({ name: 'brandForm' })(Brand);

export default brandForm;
