import React from "react";
import { UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, notification } from "antd";

import config from '../config';

class ForgotClass extends React.Component {
	state = {
		loading: false
	}
	handleSubmit = e => {
		this.setState({ loading: true });
		e.preventDefault();
		e.stopPropagation();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				fetch(config.api.url + '/admin/forgot', {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					}, method: 'post', body: JSON.stringify(values)
				}).then((resp) => resp.json())
					.then((data) => {
						this.setState({ loading: false });
						if (data.status !== 200) {
							notification['error']({
								message: 'Could not Send Link'
							});
						} else {
							notification['success']({
								message: 'Link Sent, Check your Inbox'
							});
						}
					}, (err) => {
						this.setState({ loading: false });
						notification['error']({
							message: 'Could not Send Link'
						});
					})
			}
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		return (
            <Form onSubmit={this.handleSubmit} className="forgot-form">
				<Form.Item>
					{getFieldDecorator("email", {
						rules: [{ required: true, message: "Provide your email" }, { type: "email", message: "Not a valid Mail Address" }]
					})(
						<Input
							prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
							placeholder="Your Account Email"
						/>
					)}
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						className="forgot-form-button"
						loading={this.state.loading}
					>
						Send Reset Link
          </Button>
				</Form.Item>
			</Form>
        );
	}
}

const Forgot = Form.create({ name: "forgot" })(ForgotClass);

export default Forgot;
