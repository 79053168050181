import React from "react";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, notification } from "antd";

import config from '../config';

class ResetClass extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: ''
		}
	}

	componentDidMount() {
		const {
			match: {
				params: { token },
			},
		} = this.props;
		// console.log(token);
		if (token) {
			this.setState({ token })
			fetch(config.api.url + '/admin/reset', {
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				}, method: 'post', body: JSON.stringify({ resetToken: token })
			}).then((resp) => resp.json())
				.then((data) => {
					if (data.status !== 200) {
						notification['error']({
							message: 'Can not Reset Password, Try Again Later.'
						});
					} else {
						this.setState({ email: data.data })
						this.props.form.setFieldsValue({
							email: data.data
						});
						notification['success']({
							message: 'You can update your Password Now'
						});
					}
				}, (err) => {
					notification['error']({
						message: 'Can not Reset Password, Try Again Later.'
					});
				})
		}
	}

	handleSubmit = e => {
		e.preventDefault();
		e.stopPropagation();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				let body = {
					email: values.email,
					password: values.password,
					resetToken: this.state.token
				}
				fetch(config.api.url + '/admin/viamail', {
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					}, method: 'post', body: JSON.stringify(body)
				}).then((resp) => resp.json())
					.then((data) => {
						if (data.status !== 200) {
							notification['error']({
								message: 'Could not Update Password'
							});
						} else {
							this.props.history.push("/login");
							notification['success']({
								message: 'Password Updated, Login Now.'
							});
						}
					}, (err) => {
						notification['error']({
							message: 'Could not Update Password'
						});
					})
			}
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		return (
            <Form onSubmit={this.handleSubmit} className="reset-form">
				<Form.Item>
					{getFieldDecorator("email", {
						rules: [{ required: true, message: "Provide your email" }]
					})(
						<Input
							prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
							placeholder="Email" readOnly disabled
						/>
					)}
				</Form.Item>
				<Form.Item>
					{getFieldDecorator("password", {
						rules: [{ required: true, message: "Provide your Password!" }]
					})(
						<Input
							prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
							type="password"
							placeholder="Password"
						/>
					)}
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						className="reset-form-button"
					> Update Password
          </Button>
				</Form.Item>
			</Form>
        );
	}
}

const Reset = Form.create({ name: "reset" })(ResetClass);

export default Reset;
