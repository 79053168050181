import React from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Select, notification, Button, Row } from 'antd';

import config from '../config';

const { Option } = Select;

class Retailer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			retailers: []
		}
	}

	componentDidMount() {
		this.props.setForm(this.props.form);
	}

	render() {
		const { form } = this.props;
		const { getFieldDecorator } = form;

		return (
			<Form layout="vertical">
				{getFieldDecorator('retailerForm', { initialValue: 'true' })(<Input hidden />)}
				<Form.Item>
				<div className='form-control'>
						<span className='form-control-title'>Name</span>
					{getFieldDecorator('name', {
						rules: [{ required: true, message: "Name is Required" }]
					})(<Input className='form-input-main' style={{ width: '100%' }} type="text" />)}
					</div>
				</Form.Item>
				<Form.Item>
				<Row type='flex' justify='center'>
					<Button className='submit' style={{ border: 0 }} key="submit" type="primary" onClick={this.props.handleOk}><b>SUBMIT</b></Button>
				</Row>
				</Form.Item>
			</Form>
		);
	}
}

const retailerForm = Form.create({ name: 'retailerForm' })(Retailer);

export default retailerForm;
