import React from 'react';

import {
	DeleteOutlined,
	DollarOutlined,
	EditOutlined,
	ExportOutlined,
	EyeInvisibleOutlined,
	EyeOutlined,
	PercentageOutlined,
	PictureOutlined,
	SearchOutlined,
	UserOutlined,
} from '@ant-design/icons';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import {
	Row,
	Col,
	Input,
	Modal,
	Menu,
	Card,
	Avatar,
	notification,
	Popconfirm,
	Drawer,
	Button,
	Select,
	Upload,
	Rate,
	message,
	Empty,
	DatePicker,
	TreeSelect,
	Dropdown,
	Collapse,
	Divider
} from 'antd';
import Meta from 'antd/lib/card/Meta';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as slugify from 'slugify';

import amazon from '../images/amazon.png';
import bestbuy from '../images/bb.png';
import others from '../images/others.png';
import config from '../config';

import Editor from './editor';

import moment from 'moment';

const dateFormat = 'YYYY-MM-DD HH:mm';

const { Option } = Select;
const { Panel } = Collapse;

class Products extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			disAmazon: true,
			disBest: false,
			disOth: false,
			searchCat: "All",
			orProducts: [],
			products: [],
			visible: false,
			loading: false,
			categories: [],
			retailers: [],
			cBoxCategories: [],
			fileList: [],
			gfileList: [],
			modalVisible: false,
			confirmLoading: false,
			copied: false,
			description: '',
			brands: [],
			tags: [],
			coupons: [],
			attributes: [],
			selAtt: [],
			gallery: [],
			createText: '',
			rate: 5,
			newAtt: '',
			newTerm: '',
			newBrand: '',
			newTag: '',
			newCoupon: '',
			selTerms: [],
			finalAtts: []
		}
	}

	attributeRef = React.createRef();
	termRef = React.createRef();
	brandRef = React.createRef();
	couponRef = React.createRef();
	tagRef = React.createRef();
	editorRef = React.createRef();
	galRef = React.createRef();
	imgRef = React.createRef();

	showDrawer = (id) => {
		this.getProduct(id);
		this.setState({
			copied: false,
			visible: true,
		});
	};

	onClose = () => {
		this.setState({
			visible: false,
		});
	};

	componentDidMount = () => {
		this.fetchRetailers();
		/* this.fetchAttributes(); */
		this.fetchAttributesMount();
		this.fetchBrands();
		this.fetchTags();
		this.fetchCoupons();
		this.fetchProducts();
		this.fetchCategories();
	}

	fetchCategories = () => {
		fetch(`${config.api.url}/category/get?all=true`).then((response) => response.json()).then((data) => {
			if (data.suc) {
				this.setState({ categories: data.dat.categories });
			} else {
				notification['error']({
					message: data.msg
				});
			}
		}).catch((error) => {
			console.error('Error:', error);
		});
		fetch(`${config.api.url}/retailer/get?all=true`).then((response) => response.json()).then((data) => {
			if (data.suc) {
				this.setState({ retailers: data.dat.retailers });
			} else {
				notification['error']({
					message: data.msg
				});
			}
		}).catch((error) => {
			console.error('Error:', error);
		});
		fetch(`${config.api.url}/category/getNodes`).then((response) => response.json()).then((data) => {
			if (data.suc) {
				this.setState({ cBoxCategories: data.dat.categories });
			} else {
				notification['error']({
					message: data.msg
				});
			}
		}).catch((error) => {
			console.error('Error:', error);
		});
	}

	fetchAttributesMount = () => {
		fetch(`${config.api.url}/attribute/get?all=true`).then((response) => response.json()).then((data) => {
			if (data.suc) {
				this.setState({ attributes: data.dat.attributes });
			} else {
				notification['error']({
					message: data.msg
				});
			}
		}).catch((error) => {
			console.error('Error:', error);
		});
	}

	fetchAttributes = () => {
		return new Promise((resolve, reject) => {
			fetch(`${config.api.url}/attribute/get?all=true`).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.setState({ attributes: data.dat.attributes }, () => { resolve(); });
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
		});
	}

	fetchBrands = () => {
		return new Promise((resolve, reject) => {
			fetch(`${config.api.url}/brand/get?all=true`).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.setState({ brands: data.dat.brands }, () => { resolve(); });
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
		});
	}

	fetchCoupons = () => {
		return new Promise((resolve, reject) => {
			fetch(`${config.api.url}/coupon/get?all=true`).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.setState({ coupons: data.dat.coupons }, () => { resolve(); });
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
		});
	}

	fetchTags = () => {
		return new Promise((resolve, reject) => {
			fetch(`${config.api.url}/tag/get?all=true`).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.setState({ tags: data.dat.tags }, () => { resolve(); });
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
		})
	}

	fetchRetailers = () => {
		fetch(`${config.api.url}/retailer/get?all=true`).then((response) => response.json()).then((data) => {
			if (data.suc) {
				this.setState({ retailers: data.dat.retailers });
			} else {
				notification['error']({
					message: data.msg
				});
			}
		}).catch((error) => {
			console.error('Error:', error);
		});
	}

	fetchProducts = () => {
		fetch(`${config.api.url}/product/get?all=true`).then((response) => response.json()).then((data) => {
			if (data.suc) {
				this.setState({ orProducts: data.dat.products, products: data.dat.products }, () => {
					if (this.props.location.state) {
						// console.log(this.props.location.state);
						this.setState({ filCat: this.props.location.state.category }, () => {
							this.filterProducts();
						});
					}
				});
			} else {
				notification['error']({
					message: data.msg
				});
			}
		}).catch((error) => {
			console.error('Error:', error);
		});
	}

	hideProduct = (id) => {
		fetch(`${config.api.url}/product/hid?pid=${id}`,
			{
				method: 'get'
			}).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.fetchProducts();
					notification['success']({
						message: data.msg
					});
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
	}

	unhideProduct = (id) => {
		fetch(`${config.api.url}/product/uhd?pid=${id}`,
			{
				method: 'get'
			}).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.fetchProducts();
					notification['success']({
						message: data.msg
					});
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
	}

	deleteProduct = (id) => {
		fetch(`${config.api.url}/product/dlt?pid=${id}`,
			{
				method: 'delete'
			}).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.fetchProducts();
					notification['success']({
						message: data.msg
					});
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
	}

	asin = (e) => {
		this.setState({ asin: e.target.value });
	}

	metaTitleChange = (e) => {
		this.setState({ metaTitle: e.target.value });
	}
	metaKeywordsChange = (e) => {
		this.setState({ metaKeywords: e.target.value });
	}
	metaRobotsChange = (e) => {
		this.setState({ metaRobots: e.target.value });
	}
	metaCanonicalChange = (e) => {
		this.setState({ metaCanonical: e.target.value });
	}
	metaDescriptionChange = (e) => {
		this.setState({ metaDescription: e.target.value });
	}

	sku = (e) => {
		this.setState({ sku: e.target.value });
	}

	getProduct = (id) => {
		fetch(`${config.api.url}/product/get?pid=${id}`).then((response) => response.json()).then(async (data) => {
			if (data.suc) {
				var identifier;
				if (data.dat.product[0].retailer === 1) {
					identifier = 'asin';
					this.setState({
						editId: id,
						disAmazon: true,
						disBest: false,
						disOth: false,
					});
				}

				if (data.dat.product[0].retailer === 2) {
					identifier = 'sku';
					this.setState({
						editId: id,
						disAmazon: false,
						disBest: true,
						disOth: false,
					});
				}

				if (data.dat.product[0].retailer > 2) {
					this.props.form.setFieldsValue({ retailer: data.dat.product[0].retailer });
					this.setState({
						editId: id,
						disAmazon: false,
						disBest: false,
						disOth: true,
					});
				}

				var date;
				if (data.dat.product[0].expiry) date = moment(data.dat.product[0].expiry, dateFormat)
				else date = '';

				var brands;
				if (data.dat.product[0].brands && JSON.parse(data.dat.product[0].brands).length > 0) brands = JSON.parse(data.dat.product[0].brands)
				else brands = [];

				var tags;
				if (data.dat.product[0].tags && JSON.parse(data.dat.product[0].tags).length > 0) tags = JSON.parse(data.dat.product[0].tags)
				else tags = [];

				var coupons;
				if (data.dat.product[0].coupons && JSON.parse(data.dat.product[0].coupons).length > 0) coupons = JSON.parse(data.dat.product[0].coupons)
				else coupons = [];

				this.props.form.setFieldsValue({
					brands: brands,
					coupons: coupons,
					tags: tags,
				});

				this.props.form.setFieldsValue({
					[identifier]: data.dat.product[0].identifier,
					gridTitle: data.dat.product[0].name,
					url: data.dat.product[0].url,
					title: data.dat.product[0].title,
					slug: data.dat.product[0].slug,
					price: data.dat.product[0].price,
					rating: data.dat.product[0].rating,
					discounted: data.dat.product[0].discount,
					percent: data.dat.product[0].percent,
					expiry: date,
					description: data.dat.product[0].description,
					categories: JSON.parse(data.dat.product[0].categories),
					addCart: data.dat.product[0].addCart,
					video: data.dat.product[0].video,
					attributes: [...new Set(JSON.parse(data.dat.product[0].attributes).map(item => item.attId))],
					metaTitle: data.dat.product[0].metaTitle,
					metaKeywords: data.dat.product[0].metaKeywords,
					metaRobots: data.dat.product[0].metaRobots,
					metaCanonical: data.dat.product[0].metaCanonical,
					metaDescription: data.dat.product[0].metaDescription
				});
				this.attAdded([...new Set(JSON.parse(data.dat.product[0].attributes).map(item => item.attId))]);
				JSON.parse(data.dat.product[0].attributes).forEach(att => {
					this.props.form.setFieldsValue({ [`${att.attId}-terms`]: att.terms });
					this.termAdded(att.terms, att.attId);
				});
				var gal = [];
				await JSON.parse(data.dat.product[0].gallery).forEach((image, index) => {
					gal.push(
						{
							uid: -1 + index,
							name: 'image.png',
							status: 'done',
							url: image
						}
					)
				});
				this.setState({
					editId: id,
					fileList: [
						{
							uid: -1,
							name: 'image.png',
							status: 'done',
							url: data.dat.product[0].image
						}],
					imgPth: data.dat.product[0].image,
					gfileList: gal,
					expiry: moment(data.dat.product[0].expiry, dateFormat),
					gallery: JSON.parse(data.dat.product[0].gallery),
					description: data.dat.product[0].description,
					rate: data.dat.product[0].rating,
					finalAtts: JSON.parse(data.dat.product[0].attributes),
					metaTitle: data.dat.product[0].metaTitle,
					metaKeywords: data.dat.product[0].metaKeywords,
					metaRobots: data.dat.product[0].metaRobots,
					metaCanonical: data.dat.product[0].metaCanonical,
					metaDescription: data.dat.product[0].metaDescription
				})
				this.editorRef.current.setEditorContents(this.editorRef.current.editor, data.dat.product[0].description);
			} else {
				notification['error']({
					message: data.msg
				});
			}
		}).catch((error) => {
			console.error('Error:', error);
		});
	}

	submitProduct = () => {
		const { form } = this.props;
		this.setState({
			loading: true
		});
		form.validateFields([
			"asin",
			"url",
			"sku",
			"slug",
			"image",
			"title",
			"gridTitle",
			"description",
			"price",
			"discounted",
			"percent",
			"categories",
			"rating"
		], async (err, values) => {
			if (err) {
				this.setState({
					loading: false,
				});
				var result = Object.keys(err).map(function (key) {
					return [String(key), err[key]];
				});
				result.forEach(result => {
					notification['error']({
						message: result[1].errors[0].message
					})
				});
				return;
			} else {
				var identifier;
				var retailer;
				if (this.state.disAmazon) {
					identifier = values.asin;
					retailer = 1;
				}
				if (this.state.disBest) {
					identifier = values.sku;
					retailer = 2;
				}
				if (this.state.disOth) {
					identifier = Math.floor((Math.random() * 10000) + 1);
					retailer = this.props.form.getFieldValue('retailer');
				}
				// this.setState({ finalAtts: [...this.state.finalAtts, { attId: att, terms: e }] }, () => {
				// 	console.log([...new Map(this.state.finalAtts.map(item => [item['attId'], item])).values()]);
				// })
				var finalAtts = [];
				await this.state.selAtt.forEach(async (att, index) => {
					var attTerms = this.props.form.getFieldValue(`${att}-terms`);
					if (attTerms) finalAtts.push({ attId: att, terms: attTerms })
					else finalAtts.push({ attId: att, terms: [] })
				});
				var exDate;
				if (this.state.expiry && this.state.expiry.length > 0) exDate = moment(this.state.expiry, dateFormat).toISOString().slice(0, 19).replace('T', ' ')
				else exDate = null;
				var payload = {
					pid: this.state.editId,
					identifier: identifier,
					url: values.url,
					title: values.title,
					slug: values.slug,
					name: values.gridTitle,
					video: this.props.form.getFieldValue('video'),
					addCart: this.props.form.getFieldValue('addCart'),
					categories: JSON.stringify(values.categories),
					description: this.state.description,
					price: values.price,
					discount: values.discounted,
					percent: values.percent,
					rating: values.rating,
					expiry: exDate,
					retailer: retailer,
					brands: JSON.stringify(this.props.form.getFieldValue('brands')),
					tags: JSON.stringify(this.props.form.getFieldValue('tags')),
					coupons: JSON.stringify(this.props.form.getFieldValue('coupons')),
					attributes: JSON.stringify([...new Map(finalAtts.map(item => [item['attId'], item])).values()]),
					image: this.state.imgPth,
					gallery: JSON.stringify(this.state.gallery),
					metaTitle: this.state.metaTitle,
					metaKeywords: this.state.metaKeywords,
					metaRobots: this.state.metaRobots,
					metaCanonical: this.state.metaCanonical,
					metaDescription: this.state.metaDescription
				}
				fetch(`${config.api.url}/product/put`, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payload),
				}).then((response) => response.json()).then((data) => {
					if (data.suc) {
						this.setState({
							loading: false,
							visible: false
						});
						notification['success']({
							message: data.msg
						});
						// this.props.history.push('/products');
					} else {
						this.setState({
							loading: false,
						});
						notification['error']({
							message: data.msg
						});
					}
				}).catch((error) => {
					console.error('Error:', error);
				});
			}
		});
	}

	showAmazon = () => {
		this.props.form.resetFields();
		this.setState({ disAmazon: true, disBest: false, disOth: false });
	}

	showBestBuy = () => {
		this.props.form.resetFields();
		this.setState({ disAmazon: false, disBest: true, disOth: false });
	}

	showOthers = () => {
		this.props.form.resetFields();
		this.setState({ disAmazon: false, disBest: false, disOth: true });
	}

	onDateChange = (value, dateString) => {
		this.setState({ expiry: dateString });
	}

	setTextValue = (a, b) => {
		this.state[a] = b;
	}

	onAttChange = (e) => {
		this.setState({
			newAtt: e.target.value,
		});
	}

	attAdded = (e) => {
		this.setState({ selAtt: e });
	}

	addAtt = async () => {
		const { attributes, newAtt } = this.state;
		if (new RegExp('^[a-zA-Z0-9].*').test(newAtt)) {
			var payload = {
				name: newAtt,
				slug: newAtt
			}
			fetch(`${config.api.url}/attribute/pst`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			}).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.fetchAttributes().then(async () => {
						var id = await this.state.attributes.find(x => x.name === newAtt).id
						this.setState({
							attributes: [...attributes, { name: newAtt, id: id }],
							newAtt: '',
						}, () => {
							this.setState({ selAtt: [...this.state.selAtt, id] });
							var vals = this.props.form.getFieldValue('attributes');
							if (vals !== undefined && vals.length > 0) {
								this.props.form.setFieldsValue({ attributes: [...this.props.form.getFieldValue('attributes'), id] });
							} else {
								this.props.form.setFieldsValue({ attributes: [id] });
							}
						});
						notification['success']({
							message: data.msg
						});
					})
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
		} else {
			notification['error']({
				message: 'can only start with a letter or number'
			});
		}
	}

	onTermChange = (e) => {
		this.setState({
			newTerm: e.target.value,
		});
	}

	termAdded = (e, att) => {
		this.setState({ selTerms: [...this.state.selTerms, { attId: att, terms: e }] });
	}

	addTerm = async (att) => {
		const { newTerm } = this.state;
		if (new RegExp('^[a-zA-Z0-9].*').test(newTerm)) {
			var payload = {
				name: newTerm,
				slug: newTerm,
				attributeId: att
			}
			fetch(`${config.api.url}/term/pst`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			}).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.fetchAttributes().then(async () => {
						const { attributes } = this.state;
						var id = await attributes[attributes.findIndex(x => x.id === att)].terms.find(x => x.name === newTerm).id;
						this.setState({
							[attributes[attributes.findIndex(x => x.id === att)].terms]: [...attributes[attributes.findIndex(x => x.id === att)].terms, { name: newTerm, id: id }],
							newTerm: '',
						}, () => {
							// this.setState({ finalAtts: [...this.state.finalAtts, { attId: att, terms: id }] });
							var vals = this.props.form.getFieldValue(`${att}-terms`);
							if (vals !== undefined && vals.length > 0) {
								this.props.form.setFieldsValue({ [`${att}-terms`]: [...this.props.form.getFieldValue(`${att}-terms`), id] });
							} else {
								this.props.form.setFieldsValue({ [`${att}-terms`]: [id] });
							}
						});
						notification['success']({
							message: data.msg
						});
					})
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
		} else {
			notification['error']({
				message: 'can only start with a letter or number'
			});
		}
	}

	onBrandChange = (e) => {
		this.setState({
			newBrand: e.target.value,
		});
	}

	addBrand = async () => {
		const { brands, newBrand } = this.state;
		if (new RegExp('^[a-zA-Z0-9].*').test(newBrand)) {
			var payload = {
				name: newBrand,
				slug: newBrand
			}
			fetch(`${config.api.url}/brand/pst`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			}).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.fetchBrands().then(async () => {
						var id = await this.state.brands.find(x => x.name === newBrand).id
						this.setState({
							brands: [...brands, { name: newBrand, id: id }],
							newBrand: '',
						}, () => {
							var vals = this.props.form.getFieldValue('brands');
							if (vals !== undefined && vals.length > 0) {
								this.props.form.setFieldsValue({ brands: [...this.props.form.getFieldValue('brands'), id] })
							} else {
								this.props.form.setFieldsValue({ brands: [id] })
							}
						});
						notification['success']({
							message: data.msg
						});
					})
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
		} else {
			notification['error']({
				message: 'can only start with a letter or number'
			});
		}
	}

	onCouponChange = (e) => {
		this.setState({
			newCoupon: e.target.value,
		});
	}

	addCoupon = async () => {
		const { coupons, newCoupon } = this.state;
		if (new RegExp('^[a-zA-Z0-9].*').test(newCoupon)) {
			var retailer;
			if (this.state.disAmazon) retailer = 1;
			if (this.state.disBest) retailer = 2;
			if (this.state.disOth) retailer = this.props.form.getFieldValue('retailer');
			var payload = {
				name: newCoupon,
				code: newCoupon,
				retailer: retailer
			}
			fetch(`${config.api.url}/coupon/pst`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			}).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.fetchCoupons().then(async () => {
						var id = await this.state.coupons.find(x => x.name === newCoupon).id
						this.setState({
							coupons: [...coupons, { name: newCoupon, id: id }],
							newCoupon: '',
						}, () => {
							var vals = this.props.form.getFieldValue('coupons');
							if (vals !== undefined && vals.length > 0) {
								this.props.form.setFieldsValue({ coupons: [...this.props.form.getFieldValue('coupons'), id] })
							} else {
								this.props.form.setFieldsValue({ coupons: [id] })
							}
						});
						notification['success']({
							message: data.msg
						});
					})
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
		} else {
			notification['error']({
				message: 'can only start with a letter or number'
			});
		}
	}

	onTagChange = (e) => {
		this.setState({
			newTag: e.target.value,
		});
	}

	addTag = async () => {
		const { tags, newTag } = this.state;
		if (new RegExp('^[a-zA-Z0-9].*').test(newTag)) {
			var payload = {
				name: newTag,
				color: '#000',
				icon: 'fa fa-camera'
			}
			fetch(`${config.api.url}/tag/pst`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(payload),
			}).then((response) => response.json()).then((data) => {
				if (data.suc) {
					this.fetchTags().then(async () => {
						var id = await this.state.tags.find(x => x.name === newTag).id
						this.setState({
							tags: [...tags, { name: newTag, id: id }],
							newTag: '',
						}, () => {
							var vals = this.props.form.getFieldValue('tags');
							if (vals !== undefined && vals.length > 0) {
								this.props.form.setFieldsValue({ tags: [...this.props.form.getFieldValue('tags'), id] })
							} else {
								this.props.form.setFieldsValue({ tags: [id] })
							}
						});
						notification['success']({
							message: data.msg
						});
					})
				} else {
					notification['error']({
						message: data.msg
					});
				}
			}).catch((error) => {
				console.error('Error:', error);
			});
		} else {
			notification['error']({
				message: 'can only start with a letter or number'
			});
		}
	}

	handleChange = info => {
		let fileList = [...info.fileList];
		fileList = fileList.slice(-1);

		fileList = fileList.map(file => {
			if (file.response) {
				file.url = file.response.url;
			}
			return file;
		});

		const { status } = info.file;

		if (status !== 'uploading') {
			// console.log(info.file, info.fileList);
		}
		if (status === 'done') {
			this.setState({ imgPth: info.file.response.data })
			message.success(`${info.file.name} file uploaded successfully.`);
		} else if (status === 'error') {
			message.error(`${info.file.name} file upload failed.`);
		}

		this.setState({ fileList });
	};

	ghandleChange = info => {
		let fileList = [...info.fileList];
		//fileList = fileList.slice(-1);

		fileList = fileList.map(file => {
			if (file.response) {
				file.url = file.response.url;
			}
			return file;
		});

		const { status } = info.file;

		if (status !== 'uploading') {
			// console.log(info.file, info.fileList);
		}
		if (status === 'done') {
			var gallery = this.state.gallery;
			gallery.push(info.file.response.data);
			this.setState({ gallery: gallery });
			message.success(`${info.file.name} file uploaded successfully.`);
		} else if (status === 'error') {
			message.error(`${info.file.name} file upload failed.`);
		}

		this.setState({ gfileList: fileList });
	};

	setRate = (e) => {
		this.setState({ rate: e });
	}

	onRetChange = (value) => {
		this.setState({ filRet: value });
	}

	onCatChange = (value) => {
		this.setState({ filCat: value });
	}

	onStaChange = (value) => {
		this.setState({ filSta: value });
	}

	onPriChange = (value) => {
		this.setState({ filPri: value });
	}

	filterProducts = async () => {
		this.setState({ products: this.state.orProducts }, () => {
			this.filterByRetailer();
		})
	}

	filterByRetailer = async () => {
		if (this.state.filRet) {
			if (this.state.filRet === 1) {
				let cloned = [...this.state.products];
				let pros = await cloned.filter((pro) => {
					return pro.retailer === 1;
				});
				this.setState({ products: pros }, () => { this.filterByCategory() });
			} else if (this.state.filRet === 2) {
				let cloned = [...this.state.products];
				let pros = await cloned.filter((pro) => {
					return pro.retailer === 2;
				});
				this.setState({ products: pros }, () => { this.filterByCategory() });
			}
			else if (this.state.filRet > 2) {
				let cloned = [...this.state.products];
				let pros = await cloned.filter((pro) => {
					return pro.retailer === this.state.filRet;
				});
				this.setState({ products: pros }, () => { this.filterByCategory() });
			}
		} else {
			this.filterByCategory();
		}
	}

	filterByCategory = async () => {
		if (this.state.filCat) {
			let cloned = [...this.state.products];
			let pros = await cloned.filter((pro) => {
				return pro.categories.includes(this.state.filCat);
			});
			this.setState({ products: pros }, () => { this.filterByStatus() });
		} else {
			this.filterByStatus();
		}
	}

	filterByStatus = async () => {
		if (this.state.filSta) {
			if (this.state.filSta === 1) {
				let cloned = [...this.state.products];
				let pros = await cloned.filter((pro) => {
					return pro.hidden === 0;
				});
				this.setState({ products: pros }, () => { this.filterByPrice() });
			} else if (this.state.filSta === 2) {
				let cloned = [...this.state.products];
				let pros = await cloned.filter((pro) => {
					return pro.hidden === 1;
				});
				this.setState({ products: pros }, () => { this.filterByPrice() });
			}
		} else {
			this.filterByPrice();
		}
	}

	filterByPrice = async () => {
		if (this.state.filPri) {
			if (this.state.filPri === 1) {
				let cloned = [...this.state.products];
				let pros = cloned.sort((a, b) => a.price - b.price);
				this.setState({ products: pros });
			} else {
				let cloned = [...this.state.products];
				let pros = cloned.sort((a, b) => b.price - a.price);
				this.setState({ products: pros });
			}
		}
	}

	onProSearch = async (val) => {
		if (val.target.value.length >= 1) {
			let cloned = [...this.state.products];
			let pros = await cloned.filter((pro) => {
				return pro.name.toLowerCase().includes(val.target.value.toLowerCase());
			});
			this.setState({ products: pros });
		} else {
			if (this.state.searchCat === "All") {
				this.setState({ products: this.state.orProducts });
			} else {
				let cloned = [...this.state.orProducts];
				let pros = await cloned.filter((pro) => {
					return pro.catNam === this.state.searchCat;
				});
				this.setState({ products: pros });
			}
		}
	}

	reset = () => {
		this.setState({ products: this.state.orProducts });
	}

	logout = () => {
		localStorage.clear();
		this.props.history.push("/login");
	}

	render = () => {
		const { products } = this.state;

		const { form } = this.props;
		const { getFieldDecorator } = form;

		const { newBrand, newCoupon, newTag, newAtt, newTerm } = this.state

		const props = {
			name: 'image',
			multiple: false,
			action: `${config.api.url}/product/upload`,
			onChange: this.handleChange,
			listType: 'picture-card',
			showUploadList: { showPreviewIcon: false, showRemoveIcon: true }
		};

		const gprops = {
			name: 'image',
			multiple: true,
			action: `${config.api.url}/product/upload`,
			onChange: this.ghandleChange,
			listType: 'picture-card',
			showUploadList: { showPreviewIcon: false, showRemoveIcon: true }
		};

		const tProps = {
			treeData: this.state.cBoxCategories,
			treeCheckable: false,
			multiple: true,
			allowClear: true,
			placeholder: 'Select Categories',
			style: {
				width: '95%',
				paddingTop: '20px'
			},
			showSearch: true,
			optionFilterProp: "children",
			filterTreeNode: (input, option) => option.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
		};

		return <>
			<Row type="flex" justify="space-between">
				<Col>
					<h1>Manage Products</h1>
				</Col>
				<Col>
					<Dropdown overlay={
						<Menu>
							<Menu.Item key="0" onClick={this.logout}>
								<a>Log Out</a>
							</Menu.Item>
						</Menu>
					} trigger={['click']}>
						<a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
							<Avatar size={32} icon={<UserOutlined style={{ marginRight: 0 }} />} />
						</a>
					</Dropdown>
				</Col>
			</Row>
			<br />
			<Row type="flex" justify="center">
				<Col span={24}>
					<Card className="product-card">
						<Row justify="space-around" type="flex" gutter={64}>
							<Col span={4}>
								<div className='form-control'>
									<span className='form-control-title'>Retailer</span>
									<Select
										style={{ width: '90%', paddingTop: '10px' }}
										placeholder="Retailer"
										onChange={this.onRetChange}
										className='form-input'
									>
										{this.state.retailers.map((retailer, index) => {
											return (
												<Option key={index} value={retailer.id}>{retailer.name}</Option>
											);
										})}
									</Select>
								</div>
							</Col>
							<Col span={4}>
								<div className='form-control'>
									<span className='form-control-title'>Category</span>
									<Select
										showSearch
										style={{ width: '90%', paddingTop: '10px' }}
										placeholder="Category"
										value={this.state.filCat}
										optionFilterProp="children"
										onChange={this.onCatChange}
										className='form-input'
										filterOption={(input, option) =>
											option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
									>
										{this.state.categories.map((category, index) => {
											return (
												<Option key={index} value={category.id}>{category.name}</Option>
											);
										})}
									</Select>
								</div>
							</Col>
							<Col span={4}>
								<div className='form-control'>
									<span className='form-control-title'>Status</span>
									<Select
										style={{ width: '90%', paddingTop: '10px' }}
										placeholder="Status"
										className='form-input'
										onChange={this.onStaChange}
									>
										<Option value={1}>Active</Option>
										<Option value={2}>Inactive</Option>
									</Select>
								</div>
							</Col>
							<Col span={4}>
								<div className='form-control'>
									<span className='form-control-title'>Price</span>
									<Select
										style={{ width: '90%', paddingTop: '10px' }}
										placeholder="Price"
										className='form-input'
										onChange={this.onPriChange}
									>
										<Option value={1}>Low to High</Option>
										<Option value={2}>High to Low</Option>
									</Select>
								</div>
							</Col>
							<Col span={4}>
								<div className='form-control'>
									<span className='form-control-title'>Title</span>
									<Input className='form-input-txt' onChange={this.onProSearch} style={{ width: '100%' }} placeholder="Search by Title" prefix={<SearchOutlined />} />
								</div>
							</Col>
							<Col span={4}>
								<Row type="flex" justify="space-around">
									<Col>
										<Button className='submit' type="primary" style={{ width: 90, height: 45, border: 'none' }} onClick={this.filterProducts}><b>APPLY</b></Button>
									</Col>
									<Col>
										<Button className='submit' type="danger" style={{ width: 90, height: 45, border: 'none' }} onClick={this.reset}><b>RESET</b></Button>
									</Col>
								</Row>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			<br /> <br />
			{this.state.products.length > 0 && (
				<Row type="flex" justify="center" gutter={[32, 32]}>
					{products.map((product, index) => {
						return (
							<Col key={index}>
								<div className="overlay">
								</div>
								<Card
									className={product.hidden === 0 ? "product-card" : "product-card-hidden"}
									style={{ width: 280 }}
									cover={<Row justify="center" type="flex"><img style={{ width: '200px', height: '200px' }} src={product.image} /></Row>}
									actions={[
										<a target="_new" href={product.url}><ExportOutlined key="export" /></a>,
										<EditOutlined key="edit" onClick={() => { this.showDrawer(product.id) }} />,
										product.hidden === 0 ? (
											<Popconfirm
												title="Are you sure, you want to hide this Product?"
												onConfirm={() => { this.hideProduct(product.id) }}
												okText="Yes"
												cancelText="No">
												<EyeOutlined key="eye" />
											</Popconfirm>
										) : (
												<Popconfirm
													title="Are you sure, you want to unhide this Product?"
													onConfirm={() => { this.unhideProduct(product.id) }}
													okText="Yes"
													cancelText="No">
													<EyeInvisibleOutlined key="eye" />
												</Popconfirm>
											),
										<Popconfirm
											title="Are you sure, you want to delete this Product?"
											onConfirm={() => { this.deleteProduct(product.id) }}
											okText="Yes"
											cancelText="No">
											<DeleteOutlined key="delete" />
										</Popconfirm>
									]}
								>
									<p className="product-card-title" style={{ textAlign: "center" }}><b>{product.name}</b></p><br />
									{product.retailer === 1 && (
										<Meta
											avatar={<img src={amazon} style={{ width: 80 }} />}
											title={
												<>
													<p style={{ float: "right", color: "#6252d6" }}><b>$ {product.price}</b></p>
													{product.discount && (
														<p style={{ float: "right", color: "grey", marginRight: "10px" }}><strike><b>$ {parseInt(product.price + product.discount)}</b></strike></p>
													)}
												</>
											}
										/>
									)}
									{product.retailer === 2 && (
										<Meta
											avatar={<img src={bestbuy} style={{ width: 80 }} />}
											title={
												<>
													<p style={{ float: "right", color: "#6252d6" }}><b>$ {product.price}</b></p>
													{product.discount && (
														<p style={{ float: "right", color: "grey", marginRight: "10px" }}><strike><b>$ {parseInt(product.price + product.discount)}</b></strike></p>
													)}
												</>
											}
										/>
									)}
									{product.retailer > 2 && (
										<Meta
											avatar={<img src={others} style={{ width: 80 }} />}
											title={
												<>
													<p style={{ float: "right", color: "#6252d6" }}><b>$ {product.price}</b></p>
													{product.discount && (
														<p style={{ float: "right", color: "grey", marginRight: "10px" }}><strike><b>$ {parseInt(product.price + product.discount)}</b></strike></p>
													)}
												</>
											}
										/>
									)}
								</Card>
							</Col>
						);
					})}
				</Row>
			)}
			{this.state.products.length < 1 && (
				<Row type="flex" justify="center">
					<Col>
						<Empty description={"No Products"} style={{ padding: 200 }} />
					</Col>
				</Row>
			)}
			<Drawer
				width={'50vw'}
				onClose={this.onClose}
				visible={this.state.visible}
				style={{ borderRadius: "10px" }}
			>
				<Row type="flex" justify="center">
					<Col span={24}>
						<Card bordered={false} title={<h3 style={{ fontSize: '17px', }}>EDIT A PRODUCT</h3>}>
							<Row type="flex" justify="center">
								<Col span={22}>
									<Form ref="amForm" layout="horizontal">
										<h3 style={{ textAlign: 'left' }}>GENERAL INFORMATION</h3><br /> <br />
										<Row type="flex" justify="center" gutter={[32, 32]}>
											<Col span={12}>
												{this.state.disAmazon && (
													<Form.Item>
														<div className='form-control'>
															<span className='form-control-title'>ASIN ID</span>
															{getFieldDecorator('asin', {
																rules: [{ required: true, message: "ASIN is Required" }]
															})(
																<Input readOnly placeholder='ABC123XYZ' className='form-input-main' size="large" maxLength={10} onChange={this.asin} onBlur={this.getAmazon} style={{ width: '100%', cursor: 'not-allowed' }} type="text" />
															)}
														</div>
													</Form.Item>
												)}
												{this.state.disBest && (
													<Form.Item>
														<div className='form-control'>
															<span className='form-control-title'>SKU</span>
															{getFieldDecorator('sku', {
																rules: [{ required: true, message: "SKU is Required" }]
															})(
																<Input readOnly placeholder='ABC123XYZ' className='form-input-main' size="large" maxLength={10} onChange={this.sku} onBlur={this.getBestBuy} style={{ width: '100%', cursor: 'not-allowed' }} type="text" />
															)}
														</div>
													</Form.Item>
												)}
												{this.state.disOth && (
													<Form.Item>
														<div className='form-control'>
															<span className='form-control-title'>Retailer</span>
															{getFieldDecorator('retailer', {
																rules: [{ required: true, message: "Retailer is Required" }]
															})(
																<Select
																	//showSearch
																	readOnly
																	className='form-input-main'
																	style={{ width: '95%', paddingTop: '20px', cursor: 'not-allowed' }}
																	placeholder="Retailer"
																	optionFilterProp="children"
																	filterOption={(input, option) =>
																		option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
																	}
																>
																	{this.state.retailers.map((retailer) => {
																		if (retailer.id > 2) {
																			return (
																				<Option value={retailer.id}>{retailer.name}</Option>
																			);
																		}
																	})}
																</Select>
															)}
														</div>
													</Form.Item>
												)}
											</Col>
											<Col span={12}>
												<Form.Item>
													<div className='form-control'>
														<span className='form-control-title'>Deal Expiry Date</span>
														{getFieldDecorator('expiry', {
															rules: [{ required: false, message: "Date is Required" }]
														})(
															<DatePicker placeholder='Pick a Date and Time' className='form-input-main' showTime={{ format: 'HH:mm' }}
																format="YYYY-MM-DD HH:mm" style={{ width: '100%' }} showTime onChange={this.onDateChange} />
														)}
													</div>
												</Form.Item>
											</Col>
										</Row>
										<Row type="flex" justify="center" gutter={[32, 32]}>
											<Col span={12}>
												{(this.state.disAmazon || this.state.disBest) && (
													<CopyToClipboard text={this.props.form.getFieldValue("url")}
														onCopy={() => this.setState({ copied: true })}>
														<Form.Item>
															<div className='form-control'>
																<span className='form-control-title'>URL (Click to Copy)</span>
																{getFieldDecorator('url', {
																	rules: [{ required: true, message: "URL is Required" }]
																})(
																	<Input className='form-input-main' size="large" style={{ cursor: 'not-allowed', width: '100%' }} readOnly type="text" />
																)} <br />
															</div>
															{this.state.copied ? <span style={{ color: 'red' }}>Copied</span> : null}
														</Form.Item>
													</CopyToClipboard>
												)}
												{this.state.disOth && (
													<Form.Item>
														<div className='form-control'>
															<span className='form-control-title'>URL (Click to Copy)</span>
															{getFieldDecorator('url', {
																rules: [{ required: true, message: "URL is Required" },
																{
																	type: "url",
																	message: "This field must be a valid url."
																}]
															})(
																<Input className='form-input-main' size="large" style={{ width: '100%' }} type="text" />
															)}
														</div>
													</Form.Item>
												)}
											</Col>
											<Col span={12}>
												<Form.Item>
													<div className='form-control'>
														<span className='form-control-title'>Categories</span>
														{getFieldDecorator('categories', {
															rules: [{ required: true, message: "Category is Required" }]
														})(
															<TreeSelect className='form-input-main' {...tProps} />
														)}
													</div>
												</Form.Item>
											</Col>
										</Row>
										<Row type="flex" justify="center" gutter={[32, 32]}>
											<Col span={12}>
												<Form.Item>
													<div className='form-control'>
														<span className='form-control-title'>Title</span>
														{getFieldDecorator('title', {
															rules: [{ required: true, message: "Title is Required" }]
														})(
															<Input onChange={(e)=>{this.props.form.setFieldsValue({slug: slugify(e.target.value)})}} placeholder='Main Title' className='form-input-main' size="large" style={{ width: '100%' }} type="text" />
														)}
													</div>
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item>
													<div className='form-control'>
														<span className='form-control-title'>Grid Title</span>
														{getFieldDecorator('gridTitle', {
															rules: [{ required: true, message: "Grid Title is Required" }]
														})(
															<Input placeholder='Grid Title' className='form-input-main' maxLength={60} size="large" style={{ width: '100%' }} type="text" />
														)}
													</div>
												</Form.Item>
											</Col>
										</Row>
										<Row type="flex" justify="center" gutter={[32, 32]}>
											<Col span={12}>
												<Form.Item>
													<div className='form-control'>
														<span className='form-control-title'>Slug</span>
														{getFieldDecorator('slug', {
															rules: [{ required: true, message: "Slug is Required" },
															{pattern: new RegExp('^[a-z0-9](-?[a-z0-9])*$'), message: 'Inappropriate Slug'}]
														})(
															<Input placeholder='Slug' className='form-input-main' size="large" style={{ width: '100%' }} type="text" />
														)}
													</div>
												</Form.Item>
											</Col>
										</Row>
										<Row type="flex" justify="center" gutter={[32, 32]}>
											<Col span={24}>
												<Form.Item>
													<div className='form-control-large'>
														<span className='form-control-title'>Description</span>
														{getFieldDecorator('edescription', {
															rules: [{ required: true, message: "Description is Required" }]
														})(
															<Editor theRef={this.editorRef} className='form-input-main' field='description' setFieldsValue={this.setTextValue} placeholder={'Write from Here..'} />
														)}
													</div>
												</Form.Item>
												{getFieldDecorator('description', { initialValue: {} })(<Input hidden />)}
											</Col>
										</Row>
										<Row type="flex" justify="center" gutter={[32, 32]}>
											<Col span={8}>
												<Form.Item>
													<div className='form-control'>
														<span className='form-control-title'>Current Price</span>
														{getFieldDecorator('price', {
															rules: [{ required: true, message: "Price is Required" }]
														})(<Input className='form-input-main' disabled prefix={<DollarOutlined />} readOnly style={{ width: '100%' }} type="number" />)}
													</div>
												</Form.Item>
											</Col>
											<Col span={8}>
												<Form.Item>
													<div className='form-control'>
														<span className='form-control-title'>Discounted Amount</span>
														{getFieldDecorator('discounted', {
															rules: [{ required: false, message: "Discounted is Required" }]
														})(<Input className='form-input-main' disabled readOnly prefix={<DollarOutlined />} style={{ width: '100%' }} type="number" />)}
													</div>
												</Form.Item>
											</Col>
											<Col span={8}>
												<Form.Item>
													<div className='form-control'>
														<span className='form-control-title'>Discount Percentage</span>
														{getFieldDecorator('percent', {
															rules: [{ required: false, message: "Percent is Required" }]
														})(<Input className='form-input-main' disabled prefix={<PercentageOutlined />} readOnly style={{ width: '100%' }} type="number" />)}
													</div>
												</Form.Item>
											</Col>
										</Row>
										<div style={{ height: 1, background: "#b9cbf6" }} ></div><br /><br /><h3 style={{ textAlign: 'left' }}>PRODUCT SPECIFICATIONS</h3><br />
										<br /><Row type="flex" justify="center" gutter={[32, 32]}>
											<Col span={12}>
												<Form.Item>
													<div className='form-control'>
														<span className='form-control-title'>Specifications</span>
														{getFieldDecorator('attributes', {
															rules: [{ required: false, message: "Attributes are Required" }]
														})(
															<Select
																showSearch
																optionFilterProp="children"
																className='form-input-main'
																allowClear={true}
																style={{ width: '96%', paddingTop: '20px' }}
																placeholder="Select Attributes"
																mode='multiple'
																onChange={this.attAdded}
																dropdownRender={list => (
																	<div>
																		{list}
																		<Divider style={{ margin: '10px' }} />
																		<Row type='flex' justify='space-around' style={{ padding: '5px' }}>
																			<Col span={18}>
																				<div className='form-control' style={{ height: '55px' }}>
																					<span className='form-control-title'>New Attribute</span>
																					<Input className='form-input' style={{ height: '50px', width: '100%' }} value={newAtt} onChange={this.onAttChange} />
																				</div>
																			</Col>
																			<Col span={4}>
																				<Button onClick={this.addAtt} type='primary' className='submit' style={{ width: '100%', height: '100%', border: 0 }}><b>ADD</b></Button>
																			</Col>
																		</Row>
																	</div>
																)}
															>
																{this.state.attributes.map((att, index) => {
																	return (
																		<Option key={index} value={att.id}>{att.name}</Option>
																	);
																})}
															</Select>
														)}
													</div>
												</Form.Item>
											</Col>
										</Row>
										<Row type="flex" justify="center" gutter={[32, 32]}>
											<Col span={12}>
												{this.state.selAtt.length > 0 && (
													<div className='form-control'>
														<Collapse
															style={{ background: '#eef3ff', width: '100%' }}
															expandIconPosition={'right'}
														>
															{this.state.selAtt && this.state.selAtt.map((att, index) => {
																return (
																	<Panel header={(this.state.attributes[this.state.attributes.findIndex(x => x.id === att)] && this.state.attributes[this.state.attributes.findIndex(x => x.id === att)].name) && (this.state.attributes[this.state.attributes.findIndex(x => x.id === att)].name)} key={index}>
																		<Row type='flex' justify='center'>
																			<Col span={24}>
																				<Form.Item style={{ marginTop: '20px' }}>
																					<div className='form-control'>
																						<span className='form-control-title'>Terms</span>
																						{getFieldDecorator(`${att}-terms`, {
																							rules: [{ required: false, message: "Terms are Required" }]
																						})(
																							<Select
																								showSearch
																								optionFilterProp="children"
																								className='form-input-main'
																								allowClear={true}
																								style={{ width: '96%', paddingTop: '20px' }}
																								placeholder="Select Terms"
																								mode='multiple'
																								onChange={(e) => { this.termAdded(e, att) }}
																								dropdownRender={list => (
																									<div>
																										{list}
																										<Divider style={{ margin: '10px' }} />
																										<Row type='flex' justify='space-around' style={{ padding: '5px' }}>
																											<Col span={18}>
																												<div className='form-control' style={{ height: '55px' }}>
																													<span className='form-control-title'>New Term</span>
																													<Input className='form-input' style={{ height: '50px', width: '100%' }} value={newTerm} onChange={this.onTermChange} />
																												</div>
																											</Col>
																											<Col span={4}>
																												<Button onClick={() => { this.addTerm(att) }} type='primary' className='submit' style={{ width: '100%', height: '100%', border: 0 }}><b>ADD</b></Button>
																											</Col>
																										</Row>
																									</div>
																								)}
																							>
																								{(this.state.attributes[this.state.attributes.findIndex(x => x.id === att)] && this.state.attributes[this.state.attributes.findIndex(x => x.id === att)].terms) && this.state.attributes[this.state.attributes.findIndex(x => x.id === att)].terms.map((term) => {
																									return (
																										<Option value={term.id}>{term.name}</Option>
																									);
																								})}
																							</Select>
																						)}
																					</div>
																				</Form.Item>
																			</Col>
																		</Row>
																	</Panel>
																)
															})}
														</Collapse>
													</div>)}
											</Col>
										</Row><br />
										<div style={{ height: 1, background: "#b9cbf6" }} ></div><br /> <br />
										<h3 style={{ textAlign: 'left' }}>OTHER INFORMATION</h3><br /> <br />
										<Row type="flex" justify="center" gutter={[32, 32]}>
											<Col span={12}>
												<Form.Item>
													<div className='form-control'>
														<span className='form-control-title'>Brands</span>
														{getFieldDecorator('brands', {
															rules: [{ required: false, message: "Brand is Required" }]
														})(
															<Select
																showSearch
																optionFilterProp="children"
																className='form-input-main'
																allowClear={true}
																style={{ width: '96%', paddingTop: '20px' }}
																placeholder="Select Brands"
																mode='multiple'
																dropdownRender={list => (
																	<div>
																		{list}
																		<Divider style={{ margin: '10px' }} />
																		<Row type='flex' justify='space-around' style={{ padding: '5px' }}>
																			<Col span={18}>
																				<div className='form-control' style={{ height: '55px' }}>
																					<span className='form-control-title'>New Brand</span>
																					<Input className='form-input' style={{ height: '50px', width: '100%' }} value={newBrand} onChange={this.onBrandChange} />
																				</div>
																			</Col>
																			<Col span={4}>
																				<Button onClick={this.addBrand} type='primary' className='submit' style={{ width: '100%', height: '100%', border: 0 }}><b>ADD</b></Button>
																			</Col>
																		</Row>
																	</div>
																)}
															>
																{this.state.brands.map((brand) => {
																	return (
																		<Option value={brand.id}>{brand.name}</Option>
																	);
																})}
															</Select>
														)}
													</div>
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item>
													<div className='form-control'>
														<span className='form-control-title'>Coupons</span>
														{getFieldDecorator('coupons', {
															rules: [{ required: false, message: "Coupon is Required" }]
														})(
															<Select
																showSearch
																optionFilterProp="children"
																className='form-input-main'
																allowClear={true}
																style={{ width: '96%', paddingTop: '20px' }}
																placeholder="Select Coupons"
																mode='multiple'
																dropdownRender={list => (
																	<div>
																		{list}
																		<Divider style={{ margin: '10px' }} />
																		<Row type='flex' justify='space-around' style={{ padding: '5px' }}>
																			<Col span={18}>
																				<div className='form-control' style={{ height: '55px' }}>
																					<span className='form-control-title'>New Coupon</span>
																					<Input className='form-input' style={{ height: '50px', width: '100%' }} value={newCoupon} onChange={this.onCouponChange} />
																				</div>
																			</Col>
																			<Col span={4}>
																				<Button onClick={this.addCoupon} type='primary' className='submit' style={{ width: '100%', height: '100%', border: 0 }}><b>ADD</b></Button>
																			</Col>
																		</Row>
																	</div>
																)}
															>
																{this.state.coupons.map((coupon) => {
																	return (
																		<Option value={coupon.id}>{coupon.name}</Option>
																	);
																})}
															</Select>
														)}
													</div>
												</Form.Item>
											</Col>
										</Row>
										<Row type="flex" justify="center" gutter={[32, 32]}>
											<Col span={12}>
												<Form.Item>
													<div className='form-control'>
														<span className='form-control-title'>Tags</span>
														{getFieldDecorator('tags', {
															rules: [{ required: false, message: "Tag is Required" }]
														})(
															<Select
																showSearch
																optionFilterProp="children"
																className='form-input-main'
																allowClear={true}
																style={{ width: '96%', paddingTop: '20px' }}
																placeholder="Select Tags"
																mode='multiple'
																dropdownRender={list => (
																	<div>
																		{list}
																		<Divider style={{ margin: '10px' }} />
																		<Row type='flex' justify='space-around' style={{ padding: '5px' }}>
																			<Col span={18}>
																				<div className='form-control' style={{ height: '55px' }}>
																					<span className='form-control-title'>New Tag</span>
																					<Input className='form-input' style={{ height: '50px', width: '100%' }} value={newTag} onChange={this.onTagChange} />
																				</div>
																			</Col>
																			<Col span={4}>
																				<Button onClick={this.addTag} type='primary' className='submit' style={{ width: '100%', height: '100%', border: 0 }}><b>ADD</b></Button>
																			</Col>
																		</Row>
																	</div>
																)}
															>
																{this.state.tags.map((tag) => {
																	return (
																		<Option value={tag.id}>{tag.name}</Option>
																	);
																})}
															</Select>
														)}
													</div>
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item>
													<div className='form-control'>
														<span className='form-control-title'>Cart Button Text</span>
														{getFieldDecorator('addCart', {
															rules: [{ required: false, message: "Grid Title is Required" }]
														})(
															<Input placeholder='Add to Cart Button Text' className='form-input-main' maxLength={60} size="large" style={{ width: '100%' }} type="text" />
														)}
													</div>
												</Form.Item>
											</Col>
										</Row>
										<br />
										<div style={{ height: 1, background: "#b9cbf6" }} ></div><br /><br />
										<h3 style={{ textAlign: 'left' }}>MEDIA GALLERY</h3><br /><br />
										<Row type="flex" justify="center" gutter={[32, 32]}>
											<Col span={24}>
												<Form.Item>
													<div /* onClick={() => { this.galRef.current.click(); }} */ className='form-control' style={{ /* cursor: 'pointer', */ borderStyle: 'dashed', borderWidth: '2px' }}>
														<span className='form-control-title'>Gallery</span>
														<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
															{getFieldDecorator('gallery', {
																rules: [{ required: false, message: "Image is Required" }]
															})(
																<Upload className='form-input-main' style={{ margin: 'auto' }} fileList={this.state.gfileList} {...gprops}>
																	{this.state.gfileList.length >= 5 ? null : (<><PictureOutlined ref={this.galRef} /><br />Click Here to Upload New</>)}
																</Upload>
															)}
														</div>
													</div>
												</Form.Item>
											</Col>
										</Row>
										<Row type="flex" justify="center" gutter={[32, 32]}>
											<Col span={12}>
												<Form.Item>
													<div className='form-control'>
														<span className='form-control-title'>Video</span>
														{getFieldDecorator('video', {
															rules: [{ required: false, message: "Title is Required" },
															/* {
																type: "url",
																message: "This field must be a valid url."
															} */]
														})(
															<Input placeholder='Embed Code' className='form-input-main' size="large" style={{ width: '100%' }} type="text" />
														)}
													</div>
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item>
													<div /* onClick={() => { this.imgRef.current.click(); }} */ className='form-control' style={{ /* cursor: 'pointer', */ borderStyle: 'dashed', borderWidth: '2px' }}>
														<span className='form-control-title'>Featured</span>
														<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
															{getFieldDecorator('image', {
																rules: [{ required: false, message: "Image is Required" }]
															})(
																<Upload className='form-input-main' style={{ margin: 'auto' }} fileList={this.state.fileList} {...props}>
																	{this.state.fileList.length >= 1 ? null : (<><PictureOutlined ref={this.imgRef} /><br />Click Here to Upload New</>)}
																</Upload>
															)}
														</div>
													</div>
												</Form.Item>
											</Col>
										</Row>
										<br />
										<div style={{ height: 1, background: "#b9cbf6" }} ></div><br /> <br />
										<h3 style={{ textAlign: 'left' }}>SEO INFORMATION</h3><br /> <br />
										<Row type="flex" justify="center" gutter={[32, 32]}>
											<Col span={12}>
												<Form.Item>
													<div className='form-control'>
														<span className='form-control-title'>Meta Title</span>
														{getFieldDecorator('metaTitle', {
															rules: [{ required: false, message: "Title is Required" }]
														})(
															<Input className='form-input-main' onChange={this.metaTitleChange} size="large" style={{ width: '100%' }} type="text" />
														)}
													</div>
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item>
													<div className='form-control'>
														<span className='form-control-title'>Meta Keywords</span>
														{getFieldDecorator('metaKeywords', {
															rules: [{ required: false, message: "Title is Required" }]
														})(
															<Input className='form-input-main' onChange={this.metaKeywordsChange} size="large" style={{ width: '100%' }} type="text" />
														)}
													</div>
												</Form.Item>
											</Col>
										</Row>
										<Row type="flex" justify="center" gutter={[32, 32]}>
											<Col span={12}>
												<Form.Item>
													<div className='form-control'>
														<span className='form-control-title'>Meta Robots </span>
														{getFieldDecorator('metaRobots', {
															rules: [{ required: false, message: "Title is Required" }]
														})(
															<Input className='form-input-main' onChange={this.metaRobotsChange} size="large" style={{ width: '100%' }} type="text" />
														)}
													</div>
												</Form.Item>
											</Col>
											<Col span={12}>
												<Form.Item>
													<div className='form-control'>
														<span className='form-control-title'>Meta Canonical</span>
														{getFieldDecorator('metaCanonical', {
															rules: [{ required: false, message: "Title is Required" }]
														})(
															<Input className='form-input-main' onChange={this.metaCanonicalChange} size="large" style={{ width: '100%' }} type="text" />
														)}
													</div>
												</Form.Item>
											</Col>
										</Row>
										<Row type="flex" justify="center" gutter={[32, 32]}>
											<Col span={24}>
												<Form.Item>
													<div className='form-control'>
														<span className='form-control-title'>Meta Description</span>
														{getFieldDecorator('metaDescription', {
															rules: [{ required: false, message: "Title is Required" }]
														})(
															<Input className='form-input-main' onChange={this.metaDescriptionChange} size="large" style={{ width: '100%' }} type="text" />
														)}
													</div>
												</Form.Item>
											</Col>
										</Row>
										<br />
										<Row type="flex" style={{ paddingTop: '10px', background: '#eef3ff' }} justify="space-around" gutter={[32, 32]}>
											<Col span={8}>
												<Row type='flex' justify='start'>
													<span style={{ fontSize: '18px', height: '24px', marginTop: '14px', marginLeft: '28px' }} className='form-control-title'>Admin Rating</span>
												</Row>
											</Col>
											<Col span={8}>
												<Row type='flex' justify='center'>
													<Form.Item>
														{getFieldDecorator('rating', { initialValue: 5 }, {
															rules: [{ required: false, message: "Rating is Required" }]
														})(<Rate onChange={this.setRate} style={{ width: '100%' }} />)}
													</Form.Item>
												</Row>
											</Col>
											<Col span={8}>
												<Row type='flex' justify='end'>
													<h1 style={{ marginTop: '8px', marginRight: '28px' }}>{this.state.rate}</h1>
												</Row>
											</Col>
										</Row> <br />
										<Row type="flex" justify="center" gutter={[32, 32]}>
											<Button type='primary' style={{ border: 'none' }} className='submit' onClick={this.submitProduct}><b>EDIT PRODUCT</b></Button>
										</Row>
									</Form>
								</Col>
							</Row>
						</Card>
					</Col>
				</Row>
			</Drawer>
		</>;
	}
}

const ProductsForm = Form.create({ name: 'productsForm' })(Products);

export default ProductsForm;
