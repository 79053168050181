import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'

import App from './app';

const options = {
	position: positions.TOP_RIGHT,
	timeout: 5000,
	offset: '30px',
	transition: transitions.SCALE
}

const app = (
	<BrowserRouter>
		<AlertProvider template={AlertTemplate} {...options}>
			<App />
		</AlertProvider>
	</BrowserRouter>
);

ReactDOM.render(app, document.getElementById("app"));
