import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import * as jwt from 'jsonwebtoken';

import './app.css';

import Login from "./components/login";
import Forgot from "./components/forgot";
import Reset from "./components/reset";
import Blank from "./layouts/blank";
import Default from "./layouts/default";
import Products from "./components/products";
import Product from "./components/product";
import Category from "./components/category";
import Slider from "./components/slider";
import Retailer from "./components/retailer";
import Tag from "./components/tag";
import Term from "./components/term";
import Brand from "./components/brand";
import Attribute from "./components/attribute";
import Coupon from "./components/coupon";
import Settings from "./components/settings";
import Profile from "./components/profile";
import HomePage from "./components/home";
import Dashboard from "./components/dashboard";

class App extends Component {

	loggedIn = () => {
		let token = localStorage.getItem('jwt');
		if (token !== null || token !== '' || token !== undefined) {
			let decoded = jwt.decode(token, { complete: true });
			if (decoded !== null/*  && decoded.payload.exp > Math.floor(Date.now() / 1000) */) {
				return true;
			} else {
				return false;
			}
		}
	}

	render = () => {
		return (
			<Route render={(props) => (
				!this.loggedIn() ? (
					<Route render={(props) => (
						<Blank>
							<Switch>
								<Route exact path="/login" component={Login} />
								<Route exact path="/forgot" component={Forgot} />
								<Route exact path="/reset/:token" component={Reset} />
								<Redirect from="*" to="/login" />
							</Switch>
						</Blank>
					)} />) : (<Route render={(props) => (
						<Route render={(props) => (
							<Default>
								<Switch>
									<Redirect exact from="/" to="/dashboard" />
									<Route exact path="/products" component={Products} />
									<Route exact path="/home" component={HomePage} />
									<Route exact path="/product" component={Product} />
									<Route exact path="/category" component={Category} />
									<Route exact path="/slider" component={Slider} />
									<Route exact path="/retailer" component={Retailer} />
									<Route exact path="/dashboard" component={Dashboard} />
									<Route exact path="/tag" component={Tag} />
									<Route exact path="/brand" component={Brand} />
									<Route exact path="/attribute" component={Attribute} />
									<Route exact path="/attribute/term" component={Term} />
									<Route exact path="/coupon" component={Coupon} />
									<Route exact path="/profile" component={Profile} />
									<Route exact path="/settings" component={Settings} />
									<Redirect from="*" to="/dashboard" />
								</Switch>
							</Default>
						)} />
					)} />)
			)} />
		);
	}

}

export default App;
