const env = process.env.REACT_APP_ENV;

const config = {
	api: {
		url: ({
			dev: "http://localhost:9000",
			pro: "https://admin.oscod.dev/backend",
		})[env]
	},
	app: {
		url: ({
			dev: "http://localhost:3000",
			pro: "https://admin.oscod.dev",
		})[env]
	}
}

export default config;
