import React from 'react';
import { DashboardOutlined, PlusCircleOutlined, SettingOutlined, UserOutlined, HomeOutlined } from '@ant-design/icons';
import { Layout, Menu, Select } from 'antd';
import { Link } from 'react-router-dom';

import scategories from '../images/scategories.png'
import sbrands from '../images/sbrands.png'
import stags from '../images/stags.png'
import sattributes from '../images/sattributes.png'
import scoupons from '../images/scoupons.png'
import sretailers from '../images/sretailers.png'

const { Content, Sider } = Layout;

const { Option } = Select;

export default class Default extends React.Component {
	render = () => {
		return (
			<Layout style={{ minHeight: '100vh', backgroundColor: "#eef3ff", height: 'fit-content' }}>
				<Sider style={{ marginLeft: "24px", marginTop: "24px", height: "90vh", position: 'fixed' }} className="sider" width='260px'>
					<Menu
						mode="inline"
						defaultSelectedKeys={['1']}
						style={{ height: '100%' }}
						className="sider-menu"
					>
						<Menu.Item className='menuItem' key="1"><Link to="/dashboard"><DashboardOutlined style={{ marginRight: '28px', fontSize: 20 }} /> Dashboard</Link></Menu.Item>
						<Menu.Item className='menuItem' key="2"><Link to="/products"><DashboardOutlined style={{ marginRight: '28px', fontSize: 20 }} /> Products</Link></Menu.Item>
						<Menu.Item className='menuItem' key="3"><Link to="/home"><HomeOutlined style={{ marginRight: '28px', fontSize: 20 }} /> Home Page</Link></Menu.Item>
						<Menu.Item className='menuItem' key="4"><Link to="/product"><PlusCircleOutlined style={{ marginRight: '28px', fontSize: 20 }} /> Add Product</Link></Menu.Item>
						<Menu.Item className='menuItem' key="5"><Link to="/category"><img style={{ marginRight: '28px', width: '20px' }} src={scategories} width='14px' /> Categories</Link></Menu.Item>
						<Menu.Item className='menuItem' key="6"><Link to="/slider"><img style={{ marginRight: '28px', width: '20px' }} src={scategories} width='14px' /> Slider</Link></Menu.Item>
						<Menu.Item className='menuItem' key="7"><Link to="/attribute"><img style={{ marginRight: '28px', width: '20px' }} src={sattributes} width='14px' /> Attributes</Link></Menu.Item>
						<Menu.Item className='menuItem' key="8"><Link to="/brand"><img style={{ marginRight: '28px', width: '20px' }} src={sbrands} width='14px' /> Brands</Link></Menu.Item>
						<Menu.Item className='menuItem' key="9"><Link to="/tag"><img style={{ marginRight: '28px', width: '20px' }} src={stags} width='14px' /> Tags</Link></Menu.Item>
						<Menu.Item className='menuItem' key="10"><Link to="/coupon"><img style={{ marginRight: '28px', width: '20px' }} src={scoupons} width='14px' /> Coupons</Link></Menu.Item>
						<Menu.Item className='menuItem' key="11"><Link to="/retailer"><img style={{ marginRight: '28px', width: '20px' }} src={sretailers} width='14px' /> Retailers</Link></Menu.Item>
						<Menu.Item className='menuItem' key="12"><Link to="/profile"><UserOutlined style={{ marginRight: '28px', fontSize: 20 }} /> Profile</Link></Menu.Item>
						<Menu.Item className='menuItem' key="13"><Link to="/settings"><SettingOutlined style={{ marginRight: '28px', fontSize: 20 }} /> Settings</Link></Menu.Item>
					</Menu>
				</Sider>
				<Content style={{ marginTop: '50px', padding: '30px', marginLeft: '300px' }}>
					{this.props.children}
				</Content>
			</Layout>
		);
	}
}
