import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Row, Col, Card, Avatar, Input, Button, notification, Modal, Dropdown, Menu, Drawer } from 'antd';
import jwt_decode from 'jwt-decode';
import ivalidator from 'validator';

import user from "../images/user.png";
import config from '../config';

class Profile extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			update: '',
			change: 'none',
			name: "unable to fetch",
			email: "unable to fetch",
			uloading: false,
			cloading: false,
			visible: false,
			confirmLoading: false,
		}
	}

	componentDidMount = () => {
		this.extractDetails();
	}

	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	handleOk = () => {
		this.setState({
			confirmLoading: true,
		});
		setTimeout(() => {
			this.setState({
				visible: false,
				confirmLoading: false,
			});
		}, 2000);
	};

	handleCancel = () => {
		this.setState({
			visible: false,
		});
	};

	extractDetails = () => {
		var token = localStorage.getItem('jwt');
		if (token) {
			if (ivalidator.isJWT(token)) {
				var user = jwt_decode(token);
				this.setState({ id: user.id, name: user.name, email: user.email })
				this.props.form.setFieldsValue({
					name: user.name,
					email: user.email
				});
			}
		}
	}

	showUpdate = () => {
		if (this.state.update === "none") {
			this.setState({ update: '' });
		} else {
			this.setState({ update: 'none' });
		}
	}

	showChange = () => {
		if (this.state.change === "none") {
			this.setState({ change: '' });
		} else {
			this.setState({ change: 'none' });
		}
	}

	updateProfile = () => {
		this.setState({ uloading: true });
		const { form } = this.props;
		form.validateFields(["name", "email"], (err, values) => {
			if (err) {
				this.setState({ uloading: false })
				return;
			} else {
				var payload = {
					id: this.state.id,
					name: values.name,
					email: values.email
				}
				fetch(`${config.api.url}/admin/update`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payload),
				}).then((response) => response.json()).then((data) => {
					if (data.suc) {
						this.setState({
							uloading: false,
						});
						notification['success']({
							message: data.msg
						});
					} else {
						this.setState({
							uloading: false,
						});
						notification['error']({
							message: data.msg
						});
					}
				}).catch((error) => {
					console.error('Error:', error);
				});
			}
		});
	}

	changePassword = () => {
		this.setState({ cloading: true });
		const { form } = this.props;
		form.validateFields(["pass", "cpass"], (err, values) => {
			if (err) {
				this.setState({ cloading: false })
				return;
			} else {
				var payload = {
					id: this.state.id,
					password: values.pass
				}
				fetch(`${config.api.url}/admin/change`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(payload),
				}).then((response) => response.json()).then((data) => {
					if (data.suc) {
						this.setState({
							cloading: false,
							visible: false
						});
						notification['success']({
							message: data.msg
						});
					} else {
						this.setState({
							cloading: false,
							visible: false
						});
						notification['error']({
							message: data.msg
						});
					}
				}).catch((error) => {
					console.error('Error:', error);
				});
			}
		});
	}

	logout = () => {
		localStorage.clear();
		this.props.history.push("/login");
	}

	render = () => {
		const { visible, confirmLoading } = this.state;
		const { form } = this.props;
		const { getFieldDecorator, getFieldValue } = form;
		return (<>
			<Drawer
				title="Change Password"
				visible={visible}
				footer={null}
				// onOk={this.handleOk}
				// confirmLoading={confirmLoading}
				width={'30vw'}
				onClose={this.handleCancel}
			>
				<Form layout="vertical">
					<Form.Item>
						<div className='form-control'>
							<span className='form-control-title'>New Password</span>
							{getFieldDecorator('pass', {
								rules: [{
									required: true, message: "Password is Required"
								}]
							})(
								<Input className='form-input-main' size="large" style={{ width: '100%' }} type="password" />
							)}
						</div>
					</Form.Item>
					<Form.Item hasFeedback>
						<div className='form-control'>
							<span className='form-control-title'>Confirm Password</span>
							{getFieldDecorator('cpass', {
								rules: [{
									required: true, message: "Confirmation is Required"
								}, {
									validator(rule, value) {
										if (!value || getFieldValue('pass') === value) {
											return Promise.resolve();
										}
										return Promise.reject('The two passwords that you entered do not match!');
									}
								}
								]
							})(
								<Input className='form-input-main' size="large" style={{ width: '100%' }} type="password" />
							)}
						</div>
					</Form.Item>
					<Row type="flex" justify="center">
						<Button className='submit' onClick={this.changePassword} style={{ border: 0 }} type="primary" loading={this.state.cloading}><b>CHANGE</b></Button>
					</Row>
					<br />
				</Form>
			</Drawer>
			<Row type="flex" justify="space-between">
				<Col>
					<h1 style={{ fontWeight: "bold" }}>Manage Profile</h1>
				</Col>
				<Col>
					<Dropdown overlay={
						<Menu>
							<Menu.Item key="0" onClick={this.logout}>
								<a>Log Out</a>
							</Menu.Item>
						</Menu>
					} trigger={['click']}>
						<a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
							<Avatar size={32} icon={<UserOutlined style={{ marginRight: 0 }} />} />
						</a>
					</Dropdown>
				</Col>
			</Row>
			<br />
			<Row type="flex" justify="center">
				<Col span={24}>
					<Card className="product-card" style={{ paddingTop: 0 }}>
						<Row type="flex" justify="space-between">
							<Col span={8}>
								<Row type="flex" justify="center">
									<Col>
										<Avatar src={user} size="large" style={{ height: "120px", width: "120px" }} />
									</Col>
								</Row>
							</Col>
							<Col span={8}>
								<Row type="flex" justify="center">
									<Col style={{ paddingTop: "40px" }}>
										Name <br /> <b>{this.state.name}</b>
									</Col>
									<Col style={{ paddingTop: "40px", marginLeft: "60px" }}>
										Email <br /> <b>{this.state.email}</b>
									</Col>
								</Row>
							</Col>
							<Col span={8}>
								<Row type="flex" justify="center" style={{ paddingTop: "45px" }}>
									<Col>
										<Button className='submit' type="primary" onClick={this.showModal} style={{ border: 0, width: 150, height: 50, marginRight: "20px" }}><b>PASSWORD</b></Button>
									</Col>
									<Col>
										<Button className='submit' type="danger" onClick={this.logout} style={{ border: 0, width: 150, height: 50, marginLeft: "20px" }}><b>LOG OUT</b></Button>
									</Col>
								</Row>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
			<br />
			<Row style={{ display: this.state.update }} type="flex" justify="center">
				<br />
				<Col span={24}>
					<Card title={<h3>UPDATE PROFILE</h3>} className="product-card">
						<Form layout="horizontal">
							<Row type="flex" justify="center" gutter={[32, 32]}>
								<Col span={12}>
									<Form.Item >
										<div className='form-control'>
											<span className='form-control-title'>Name</span>
											{getFieldDecorator('name', {
												rules: [{ required: true, message: "Name is Required" }]
											})(
												<Input className='form-input-main' size="large" style={{ width: '100%' }} type="text" />
											)}
										</div>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item>
										<div className='form-control'>
											<span className='form-control-title'>Email</span>
											{getFieldDecorator('email', {
												rules: [{ required: true, message: "Email is Required" }]
											})(
												<Input className='form-input-main' size="large" style={{ width: '100%' }} type="text" />
											)}
										</div>
									</Form.Item>
								</Col>
							</Row>
							<Row type="flex" justify="center">
								<Button className="submit" style={{ border: 0 }} onClick={this.updateProfile} type="primary" loading={this.state.uloading}><b>UPDATE</b></Button>
							</Row>
						</Form>
					</Card>
				</Col>
			</Row>
		</>)
	}
}

const ProfileForm = Form.create({ name: 'profileForm' })(Profile);

export default ProfileForm;
