import React from "react";
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, Button, notification } from "antd";

import config from '../config';

class LoginClass extends React.Component {
	handleSubmit = e => {
		e.preventDefault();
		e.stopPropagation();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				fetch(`${config.api.url}/admin/sign-in`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(values),
				}).then((response) => response.json()).then((data) => {
					if (data.user) {
						localStorage.setItem('jwt', data.token);
						this.props.history.push('/dashboard');
					} else {
						notification['error']({
							message: data.message
						});
					}
				}).catch((error) => {
					console.error('Error:', error);
				});
			}
		});
	};

	render() {
		const { getFieldDecorator } = this.props.form;
		return (
            <Form onSubmit={this.handleSubmit} className="login-form">
				<Form.Item>
					{getFieldDecorator("email", {
						rules: [{ required: true, message: "Provide your email" }]
					})(
						<Input
							prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
							placeholder="Email"
						/>
					)}
				</Form.Item>
				<Form.Item>
					{getFieldDecorator("password", {
						rules: [{ required: true, message: "Provide your Password!" }]
					})(
						<Input
							prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
							type="password"
							placeholder="Password"
						/>
					)}
				</Form.Item>
				<Form.Item>
					<Button
						type="primary"
						htmlType="submit"
						className="login-form-button"
					>
						Login
          </Button>
				</Form.Item>
				<Form.Item>
					<p style={{ float: "left" }}>Account Options</p>
					<p style={{ float: "right" }}>
						<a style={{ float: "left" }} href="/forgot">Forgot</a>
					</p>
				</Form.Item>
			</Form>
        );
	}
}

const Login = Form.create({ name: "login" })(LoginClass);

export default Login;
