import React from 'react';
import { Row, Col, Dropdown, Menu, Avatar, Tabs, Card, Button, notification, Drawer, Select, Input } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import config from '../config';

const { TabPane } = Tabs;
const { Option } = Select;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const sgrid = 20;

const getsliderstyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: sgrid * 2,
  margin: `0 0 ${sgrid}px 0`,
  border: '2px solid #eaeaea',
  borderRadius: '10px',
  background: isDragging ? "white" : "white",
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "white" : "white",
  padding: sgrid,
  width: '100%'
});

const grid = 10;

const getsliderstyle2 = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  border: '2px solid #eaeaea',
  borderRadius: '10px',
  background: isDragging ? "white" : "white",
  ...draggableStyle
});

const getListStyle2 = isDraggingOver => ({
  background: isDraggingOver ? "white" : "white",
  padding: grid,
  width: '100%'
});

export default class HomePage extends React.Component {

  constructor() {
    super();
    this.state = {
      tab: 'Menu',
      sliders: [],
      sections: [],
      secondaryCats: [],
      discoverCats: [],
      allSlides: [],
      categories: []
    }
  }

  componentDidMount = () => {
    this.getSliders();
    this.getCategories();
    this.getHomeSliders();
    this.getHomeSections();
    this.getHomeCategories();
  }

  tabChange = (e) => {
    if (e === '2') {
      this.setState({ tab: 'Slider' });
    } else if (e === '3') {
      this.setState({ tab: 'Sections' });
    } else {
      this.setState({ tab: 'Menu' });
    }
  }

  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const sliders = reorder(
      this.state.sliders,
      result.source.index,
      result.destination.index
    );

    this.setState({
      sliders
    });
  }

  onDragEnd2 = (result) => {
    if (!result.destination) {
      return;
    }

    const sections = reorder(
      this.state.sections,
      result.source.index,
      result.destination.index
    );

    this.setState({
      sections
    });
  }

  deletSlide = (id) => {
    this.setState({
      sliders: this.state.sliders.filter(x => x.slideId !== id)
    })
  }

  deleteSection = (id) => {
    this.setState({
      sections: this.state.sections.filter(x => x.sectionId !== id)
    })
  }

  getHomeSliders = () => {
    fetch(`${config.api.url}/homepage/get/?sliders=true`).then((response) => response.json()).then((data) => {
      if (data.suc) {
        if (JSON.parse(data.dat.sliders).length > 0) {
          var sliders = JSON.parse(data.dat.sliders);
          this.getSliders().then(() => {
            sliders.forEach((slider, index) => {
              var slider = this.state.allSlides.find(x => x.id === slider.slideId);
              let obj = {
                id: `slider-${slider.id}`,
                slideId: slider.id,
                slideTag: slider.tagName,
                slideTitle: slider.title,
                slideButton: slider.buttonText,
                slideUrl: slider.url,
                slideProduct: slider.productId,
                slideColor: slider.color,
                slideImage: slider.image,
                content:
                  <Row type='flex' justify='space-between'>
                    <Col style={{ display: 'flex' }}>
                      <div style={{ margin: 'auto' }}>
                        <h2>{slider.name}</h2>
                        <p><b>Tag:</b> {slider.tagName}</p>
                        <p><b>Title:</b> {slider.title}</p>
                        <p><b>Button:</b> {slider.buttonText}</p>
                        <br />
                        <Button className='submit' type="primary" style={{ width: 'fit-content', height: 50, border: 0 }} onClick={() => { this.deletSlide(slider.id) }}><b>DELETE SLIDE</b></Button>
                      </div>
                    </Col>
                    <Col span={16} style={{ position: 'relative' }}>
                      <img src={slider.image} style={{ maxHeight: '100%', minWidth: '100%', borderRadius: 10, position: 'absolute' }} />
                    </Col>
                  </Row>
              }
              if (!this.state.sliders.find(x => x.slideId === slider.id)) {
                this.setState({
                  sliders: [...this.state.sliders, obj]
                })
              }
            });
          });
        }
      } else {
        notification['error']({
          message: data.msg
        });
      }
    }).catch((error) => {
      console.error('Error:', error);
    });
  }

  getHomeSections = () => {
    fetch(`${config.api.url}/homepage/get/?sections=true`).then((response) => response.json()).then((data) => {
      if (data.suc) {
        if (JSON.parse(data.dat.sections).length > 0) {
          JSON.parse(data.dat.sections).forEach((section, index) => {
            if (section.sectionType === 'product') {
              let obj = {
                id: `section-${section.sectionId}`,
                sectionId: section.sectionId,
                sectionTitle: section.sectionTitle,
                sectionSubTitle: section.sectionSubTitle,
                sectionCategory: section.sectionCategory,
                sectionCategorySlug: section.sectionCategorySlug,
                sectionType: 'product',
                content:
                  <>
                    <h2>Product Section</h2><br />
                    <Row type='flex' justify='space-between'>
                      <Col span={20}>
                        <Row type='flex' style={{ margin: 'auto' }} gutter={32} justifyContent='space-between'>
                          <Col span={8}>
                            <div className='form-control'>
                              <span className='form-control-title'>Section Title</span>
                              <Input defaultValue={section.sectionTitle} style={{ minHeight: '50px', minWidth: '100%' }} className='form-input-main' placeholder='Section Title' onChange={(e) => { this.setTitle(e.target.value, section.sectionId) }} />
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className='form-control'>
                              <span className='form-control-title'>Section SubTitle</span>
                              <Input defaultValue={section.sectionSubTitle} style={{ minHeight: '50px', minWidth: '100%' }} className='form-input-main' placeholder='Section Title' onChange={(e) => { this.setSubTitle(e.target.value, section.sectionId) }} />
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className='form-control'>
                              <span className='form-control-title'>Section Category</span>
                              <Select
                                showSearch
                                className='form-input'
                                style={{ minWidth: '100%', padding: '0px', paddingTop: '10px', paddingBottom: '10px' }}
                                placeholder="Category"
                                defaultValue={section.sectionCategory}
                                onChange={(r) => { this.setCategory(r, section.sectionId) }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {this.state.categories.map((category, index) => {
                                  return (
                                    <Option key={index} value={category.id}>{category.name}</Option>
                                  );
                                })}
                              </Select>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col style={{ display: 'flex' }}>
                        <a onClick={() => { this.deleteSection(section.sectionId) }} style={{ margin: 'auto', cursor: 'pointer' }}>delete</a>
                      </Col>
                    </Row>
                  </>
              }
              this.setState({
                sections: [...this.state.sections, obj]
              })
            } else {
              let obj = {
                id: `section-${section.sectionId}`,
                sectionId: section.sectionId,
                sectionTitle: section.sectionTitle,
                sectionSubTitle: section.sectionSubTitle,
                sectionCategory: section.sectionCategory,
                sectionCategorySlug: section.sectionCategorySlug,
                sectionType: 'category',
                content:
                  <>
                    <h2>Category Section</h2><br />
                    <Row type='flex' justify='space-between'>
                      <Col span={20}>
                        <Row type='flex' style={{ margin: 'auto' }} gutter={32} justifyContent='space-between'>
                          <Col span={8}>
                            <div className='form-control'>
                              <span className='form-control-title'>Section Title</span>
                              <Input defaultValue={section.sectionTitle} style={{ minHeight: '50px', minWidth: '100%' }} className='form-input-main' placeholder='Section Title' onChange={(e) => { this.setTitle(e.target.value, section.sectionId) }} />
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className='form-control'>
                              <span className='form-control-title'>Section SubTitle</span>
                              <Input defaultValue={section.sectionSubTitle} style={{ minHeight: '50px', minWidth: '100%' }} className='form-input-main' placeholder='Section Title' onChange={(e) => { this.setSubTitle(e.target.value, section.sectionId) }} />
                            </div>
                          </Col>
                          <Col span={8}>
                            <div className='form-control'>
                              <span className='form-control-title'>Section Category</span>
                              <Select
                                showSearch
                                className='form-input'
                                style={{ minWidth: '100%', padding: '0px', paddingTop: '10px', paddingBottom: '10px' }}
                                placeholder="Category"
                                mode='multiple'
                                defaultValue={section.sectionCategory}
                                onChange={(r) => { this.setCategory(r, section.sectionId) }}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {this.state.categories.map((category, index) => {
                                  return (
                                    <Option key={index} value={category.id}>{category.name}</Option>
                                  );
                                })}
                              </Select>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col style={{ display: 'flex' }}>
                        <a onClick={() => { this.deleteSection(section.sectionId) }} style={{ margin: 'auto', cursor: 'pointer' }}>delete</a>
                      </Col>
                    </Row>
                  </>
              }
              this.setState({
                sections: [...this.state.sections, obj]
              })
            }
          });
        }
      } else {
        notification['error']({
          message: data.msg
        });
      }
    }).catch((error) => {
      console.error('Error:', error);
    });
  }

  getSliders = () => {
    return new Promise((resolve, reject) => {
      fetch(`${config.api.url}/slider/get/?feat=true`).then((response) => response.json()).then((data) => {
        if (data.suc) {
          this.setState({ allSlides: data.dat.sliders }, () => { resolve(); });
        } else {
          notification['error']({
            message: data.msg
          });
        }
      }).catch((error) => {
        console.error('Error:', error);
      });
    })
  }

  getCategories = () => {
    fetch(`${config.api.url}/category/get?featured=true`).then((response) => response.json()).then((data) => {
      if (data.suc) {
        this.setState({ categories: data.dat.categories });
      } else {
        notification['error']({
          message: data.msg
        });
      }
    }).catch((error) => {
      console.error('Error:', error);
    });
  }

  getHomeCategories = () => {
    fetch(`${config.api.url}/homepage/get/?categories=true`).then((response) => response.json()).then((data) => {
      if (data.suc) {
        this.setState({ secondaryCats: JSON.parse(data.dat.secondaryCats), discoverCats: JSON.parse(data.dat.discoverCats) });
      } else {
        notification['error']({
          message: data.msg
        });
      }
    }).catch((error) => {
      console.error('Error:', error);
    });
  }

  pushSlide = () => {
    if (this.state.selectedSlide) {
      var slider = this.state.allSlides.find(x => x.id === this.state.selectedSlide);
      let obj = {
        id: `slider-${slider.id}`,
        slideId: slider.id,
        slideTag: slider.tagName,
        slideTitle: slider.title,
        slideButton: slider.buttonText,
        slideUrl: slider.url,
        slideProduct: slider.productId,
        slideColor: slider.color,
        slideImage: slider.image,
        content:
          <Row type='flex' justify='space-between'>
            <Col style={{ display: 'flex' }}>
              <div style={{ margin: 'auto' }}>
                <h2>{slider.name}</h2>
                <p><b>Tag:</b> {slider.tagName}</p>
                <p><b>Title:</b> {slider.title}</p>
                <p><b>Button:</b> {slider.buttonText}</p>
                <br />
                <Button className='submit' type="primary" style={{ width: 'fit-content', height: 50, border: 0 }} onClick={() => { this.deletSlide(slider.id) }}><b>DELETE SLIDE</b></Button>
              </div>
            </Col>
            <Col span={16} style={{ position: 'relative' }}>
              <img src={slider.image} style={{ maxHeight: '100%', minWidth: '100%', borderRadius: 10, position: 'absolute' }} />
            </Col>
          </Row>
      }
      if (!this.state.sliders.find(x => x.slideId === slider.id)) {
        this.setState({
          sliders: [...this.state.sliders, obj]
        })
      }
    }
  }

  setTitle = (v, id) => {
    //console.log(v, id)
    this.state.sections.find(x => x.sectionId === id).sectionTitle = v;
  }

  setSubTitle = (v, id) => {
    //console.log(v, id);
    this.state.sections.find(x => x.sectionId === id).sectionSubTitle = v;
  }

  setCategory = (v, id) => {
    //console.log(v, id);
    this.state.sections.find(x => x.sectionId === id).sectionCategory = v;
    if(!Array.isArray(v)) {
      this.state.sections.find(x => x.sectionId === id).sectionCategorySlug = this.state.categories.find(x => x.id === v).slug
    }
  }

  pushProduct = () => {
    var random = Math.random();
    let obj = {
      id: `section-${random}`,
      sectionId: random,
      sectionTitle: null,
      sectionSubTitle: null,
      sectionCategory: null,
      sectionCategorySlug: null,
      sectionType: 'product',
      content:
        <>
          <h2>Product Section</h2><br />
          <Row type='flex' justify='space-between'>
            <Col span={20}>
              <Row type='flex' style={{ margin: 'auto' }} gutter={32} justifyContent='space-between'>
                <Col span={8}>
                  <div className='form-control'>
                    <span className='form-control-title'>Section Title</span>
                    <Input style={{ minHeight: '50px', minWidth: '100%' }} className='form-input-main' placeholder='Section Title' onChange={(e) => { this.setTitle(e.target.value, random) }} />
                  </div>
                </Col>
                <Col span={8}>
                  <div className='form-control'>
                    <span className='form-control-title'>Section SubTitle</span>
                    <Input style={{ minHeight: '50px', minWidth: '100%' }} className='form-input-main' placeholder='Section Title' onChange={(e) => { this.setSubTitle(e.target.value, random) }} />
                  </div>
                </Col>
                <Col span={8}>
                  <div className='form-control'>
                    <span className='form-control-title'>Section Category</span>
                    <Select
                      showSearch
                      className='form-input'
                      style={{ minWidth: '100%', padding: '0px', paddingTop: '10px', paddingBottom: '10px' }}
                      placeholder="Category"
                      onChange={(r) => { this.setCategory(r, random) }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.categories.map((category, index) => {
                        return (
                          <Option key={index} value={category.id}>{category.name}</Option>
                        );
                      })}
                    </Select>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col style={{ display: 'flex' }}>
              <a onClick={() => { this.deleteSection(random) }} style={{ margin: 'auto', cursor: 'pointer' }}>delete</a>
            </Col>
          </Row>
        </>
    }
    this.setState({
      sections: [...this.state.sections, obj]
    })
  }

  pushCategory = () => {
    var random = Math.random();
    let obj = {
      id: `section-${random}`,
      sectionId: random,
      sectionTitle: null,
      sectionSubTitle: null,
      sectionCategory: null,
      sectionCategorySlug: null,
      sectionType: 'category',
      content:
        <>
          <h2>Category Section</h2><br />
          <Row type='flex' justify='space-between'>
            <Col span={20}>
              <Row type='flex' style={{ margin: 'auto' }} gutter={32} justifyContent='space-between'>
                <Col span={8}>
                  <div className='form-control'>
                    <span className='form-control-title'>Section Title</span>
                    <Input style={{ minHeight: '50px', minWidth: '100%' }} className='form-input-main' placeholder='Section Title' onChange={(e) => { this.setTitle(e.target.value, random) }} />
                  </div>
                </Col>
                <Col span={8}>
                  <div className='form-control'>
                    <span className='form-control-title'>Section SubTitle</span>
                    <Input style={{ minHeight: '50px', minWidth: '100%' }} className='form-input-main' placeholder='Section Title' onChange={(e) => { this.setSubTitle(e.target.value, random) }} />
                  </div>
                </Col>
                <Col span={8}>
                  <div className='form-control'>
                    <span className='form-control-title'>Section Category</span>
                    <Select
                      showSearch
                      className='form-input'
                      style={{ minWidth: '100%', padding: '0px', paddingTop: '10px', paddingBottom: '10px' }}
                      placeholder="Category"
                      mode='multiple'
                      onChange={(r) => { this.setCategory(r, random) }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.categories.map((category, index) => {
                        return (
                          <Option key={index} value={category.id}>{category.name}</Option>
                        );
                      })}
                    </Select>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col style={{ display: 'flex' }}>
              <a onClick={() => { this.deleteSection(random) }} style={{ margin: 'auto', cursor: 'pointer' }}>delete</a>
            </Col>
          </Row>
        </>
    }
    this.setState({
      sections: [...this.state.sections, obj]
    })
  }

  saveSliders = () => {
    var payload = {
      sliders: JSON.stringify(this.state.sliders)
    }
    fetch(`${config.api.url}/homepage/put`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }).then((response) => response.json()).then((data) => {
      if (data.suc) {
        notification['success']({
          message: data.msg
        });
      } else {
        notification['error']({
          message: data.msg
        });
      }
    }).catch((error) => {
      console.error('Error:', error);
    });
  }

  saveSections = () => {
    var payload = {
      sections: JSON.stringify(this.state.sections)
    }
    fetch(`${config.api.url}/homepage/put`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }).then((response) => response.json()).then((data) => {
      if (data.suc) {
        notification['success']({
          message: data.msg
        });
      } else {
        notification['error']({
          message: data.msg
        });
      }
    }).catch((error) => {
      console.error('Error:', error);
    });
  }

  setSecondary = (e) => {
    this.setState({secondaryCats: e});
  }

  setDiscover = (e) => {
    this.setState({discoverCats: e});
  }

  saveCategories = () => {
    var secCats = JSON.stringify([])
    if (this.state.secondaryCats.length > 0) {
      secCats = JSON.stringify(this.state.secondaryCats)
    }
    var payload = {
      secondaryCats: secCats,
      discoverCats: JSON.stringify(this.state.discoverCats)
    }
    fetch(`${config.api.url}/homepage/put`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    }).then((response) => response.json()).then((data) => {
      if (data.suc) {
        notification['success']({
          message: data.msg
        });
      } else {
        notification['error']({
          message: data.msg
        });
      }
    }).catch((error) => {
      console.error('Error:', error);
    });
  }

  render = () => {
    return (
      <>
        <Row type="flex" justify="space-between">
          <Col>
            <h1 style={{ fontWeight: "bold" }}>Manage Home</h1>
          </Col>
          <Col>
            <Dropdown overlay={
              <Menu>
                <Menu.Item key="0" onClick={this.logout}>
                  <a>Log Out</a>
                </Menu.Item>
              </Menu>
            } trigger={['click']}>
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                <Avatar size={32} icon={<UserOutlined style={{ marginRight: 0 }} />} />
              </a>
            </Dropdown>
          </Col>
        </Row>
        <br />
        <Row type="flex" justify="center">
          <Col span={24}>
            <Card className="product-card" title={<Row justify='space-between'>
              <h3 style={{ fontSize: '17px' }}
              >{this.state.tab} Options</h3>
              {this.state.tab === 'Slider' && (
                <Row>
                  <div className='form-control'>
                    <span className='form-control-title'>Sliders</span>
                    <Select
                      showSearch
                      className='form-input'
                      style={{ width: '300px', padding: '0px', paddingTop: '10px', paddingBottom: '10px' }}
                      placeholder="Sliders"
                      onChange={(r) => { this.setState({ selectedSlide: r }) }}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.allSlides.map((slide, index) => {
                        return (
                          <Option key={index} value={slide.id}>{slide.name}</Option>
                        );
                      })}
                    </Select>
                  </div>
                  <Button onClick={() => { this.pushSlide() }} className='submit' type="primary" style={{ width: 'fit-content', height: 50, border: 0, marginLeft: 20 }}
                  ><b>Add Slide</b></Button>
                  <Button className='submit' type="primary" style={{ width: 'fit-content', height: 50, border: 0, marginLeft: 20 }} onClick={this.saveSliders}><b>Save Layout</b></Button>
                </Row>)}
              {this.state.tab === 'Sections' && (<Row>
                <Button className='submit' type="primary" style={{ width: 'fit-content', height: 50, border: 0 }} onClick={this.pushProduct}><b>Add Product Section</b></Button>
                <Button className='submit' type="primary" style={{ width: 'fit-content', height: 50, border: 0, marginLeft: 20 }} onClick={this.pushCategory}><b>Add Category Section</b></Button>
                <Button className='submit' type="primary" style={{ width: 'fit-content', height: 50, border: 0, marginLeft: 20 }} onClick={this.saveSections}><b>Save Layout</b></Button>
              </Row>
              )}
              {this.state.tab === 'Infinite' && (<Row>
                <Button className='submit' type="primary" style={{ width: 'fit-content', height: 50, border: 0 }} onClick={this.showModal}><b>Choose Categories</b></Button>
              </Row>
              )}
              {this.state.tab === 'Menu' && (<Row>
                <Button className='submit' type="primary" style={{ width: 'fit-content', height: 50, border: 0 }} onClick={this.saveCategories}><b>Save Menus</b></Button>
              </Row>
              )}
            </Row>} style={{ padding: '30px', minHeight: '80vh', height: 'fit-content' }}>
              <Tabs onChange={this.tabChange} tabPosition={'left'}>
                <TabPane tab="Menu" key="1">
                  <>
                  <br/>
                    <Row type='flex' justify='space-between'>
                      <Col span={24}>
                        <Row type='flex' style={{ margin: 'auto' }} gutter={32} justifyContent='space-between'>
                          <Col span={12}>
                            <div className='form-control'>
                              <span className='form-control-title'>Secondary Menu</span>
                              <Select
                                showSearch
                                mode='multiple'
                                className='form-input'
                                style={{ minWidth: '100%', padding: '0px', paddingTop: '10px', paddingBottom: '10px' }}
                                placeholder="Category"
                                onChange={this.setSecondary}
                                value={this.state.secondaryCats}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {this.state.categories.map((category, index) => {
                                  return (
                                    <Option key={index} value={category.id}>{category.name}</Option>
                                  );
                                })}
                              </Select>
                            </div>
                          </Col>
                          <Col span={12}>
                            <div className='form-control'>
                              <span className='form-control-title'>Discover Menu</span>
                              <Select
                                showSearch
                                mode='multiple'
                                className='form-input'
                                style={{ minWidth: '100%', padding: '0px', paddingTop: '10px', paddingBottom: '10px' }}
                                placeholder="Category"
                                value={this.state.discoverCats}
                                onChange={this.setDiscover}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {this.state.categories.map((category, index) => {
                                  return (
                                    <Option key={index} value={category.id}>{category.name}</Option>
                                  );
                                })}
                              </Select>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                </TabPane>
                <TabPane tab="Slider" key="2">
                  <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {this.state.sliders.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getsliderstyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  {item.content}
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </TabPane>
                <TabPane tab="Sections" key="3">
                  <DragDropContext onDragEnd={this.onDragEnd2}>
                    <Droppable droppableId="droppable2">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle2(snapshot.isDraggingOver)}
                        >
                          {this.state.sections.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getsliderstyle2(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  {item.content}
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </>
    );
  }

}
