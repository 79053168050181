import React from 'react';
import { ChartCard, Field, MiniArea, MiniBar, MiniProgress, Pie, Bar } from 'ant-design-pro/lib/Charts';
import Trend from 'ant-design-pro/lib/Trend';
import NumberInfo from 'ant-design-pro/lib/NumberInfo';
import { Row, Col, Icon, Tooltip, notification, Tabs, Card, DatePicker, Table } from 'antd';
import numeral from 'numeral';
import moment from 'moment';
import config from '../config';

var saleData = [];
var visitData = [];
var userData = [];
var inquiryData = [];

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

var beginDay = moment().subtract(7, 'days') //new Date().getTime();

for (let i = 0; i < 8; i += 1) {
    saleData.push({
        x: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format('YYYY-MM-DD'),
        y: 100,
    });
}

for (let i = 0; i < 8; i += 1) {
    visitData.push({
        x: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format('YYYY-MM-DD'),
        y: 1,
    });
}

for (let i = 0; i < 8; i += 1) {
    userData.push({
        x: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format('YYYY-MM-DD'),
        y: 1,
    });
}

for (let i = 0; i < 8; i += 1) {
    inquiryData.push({
        x: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format('YYYY-MM-DD'),
        y: Math.floor(Math.random() * 1000),
    });
}

var salesPieData = [];

var AmPieData = [];
var BbPieData = [];
var OtPieData = [];

export default class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            todaySales: 0,
            todayVisits: 0,
            totalSales: 0,
            totalVisits: 0,
            todayUsers: 0,
            totalUsers: 0,
            todayInquiries: 0,
            totalInquiries: 0,
            amProData: [],
            bbProData: [],
            otProData: []
        }
    }

    componentDidMount = () => {
        this.fetchViews();
        this.fetchSales();
        this.fetchUsers();
        this.fetchRetailers();
        this.fetchRetailersAm();
        this.fetchRetailersBb();
        this.fetchRetailersOt();
        this.fetchInquiries();
        this.fetchProbyAm();
        this.fetchProbyBb();
        this.fetchProbyOt();
    }

    componentWillUnmount = () => {
        salesPieData = [];
        AmPieData = [];
        BbPieData = [];
        OtPieData = [];
    }

    fetchViews = () => {
        fetch(`${config.api.url}/views/get/`).then((response) => response.json()).then((data) => {
            if (data.suc) {
                data.dat.views.forEach(async (view, index) => {
                    let found = await visitData.find(x => x.x === moment(new Date(view.date)).format('YYYY-MM-DD'));
                    if (found) {
                        found.y = view.count;
                        this.forceUpdate();
                    }
                    if (new Date(view.date).getDate() === new Date().getDate()) {
                        this.setState({ todayVisits: view.count }, () => {
                            this.forceUpdate();
                        })
                    }
                });
                this.setState({ totalVisits: data.dat.total })
            } else {
                notification['error']({
                    message: data.msg
                });
            }
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    fetchInquiries = () => {
        fetch(`${config.api.url}/views/getInquiries/`).then((response) => response.json()).then((data) => {
            if (data.suc) {
                data.dat.inquiries.forEach(async (inquiry, index) => {
                    let found = await inquiryData.find(x => x.x === moment(new Date(inquiry.date)).format('YYYY-MM-DD'));
                    /* if (found) {
                        found.y = inquiry.count;
                        this.forceUpdate();
                    } */
                    if (new Date(inquiry.date).getDate() === new Date().getDate()) {
                        this.setState({ todayInquiries: inquiry.count }, () => {
                            this.forceUpdate();
                        })
                    }
                });
                this.setState({ totalInquiries: data.dat.total })
            } else {
                notification['error']({
                    message: data.msg
                });
            }
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    fetchSales = () => {
        fetch(`${config.api.url}/sales/get/`).then((response) => response.json()).then((data) => {
            if (data.suc) {
                data.dat.sales.forEach(async (sale, index) => {
                    let found = await saleData.find(x => x.x === moment(new Date(sale.date)).format('YYYY-MM-DD'));
                    if (found) {
                        found.y = sale.count;
                        this.forceUpdate();
                    }
                    if (new Date(sale.date).getDate() === new Date().getDate()) {
                        this.setState({ todaySales: sale.amount }, () => {
                            this.forceUpdate();
                        })
                    }
                });
                this.setState({ totalSales: data.dat.total })
            } else {
                notification['error']({
                    message: data.msg
                });
            }
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    fetchUsers = () => {
        fetch(`${config.api.url}/views/getUsers/`).then((response) => response.json()).then((data) => {
            if (data.suc) {
                data.dat.users.forEach(async (user, index) => {
                    let found = await userData.find(x => x.x === moment(new Date(user.date)).format('YYYY-MM-DD'));
                    if (found) {
                        found.y = user.count;
                        this.forceUpdate();
                    }
                    if (new Date(user.date).getDate() === new Date().getDate()) {
                        this.setState({ todayUsers: user.count }, () => {
                            this.forceUpdate();
                        })
                    }
                });
                this.setState({ totalUsers: data.dat.total })
            } else {
                notification['error']({
                    message: data.msg
                });
            }
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    fetchRetailers = () => {
        fetch(`${config.api.url}/sales/getbyret/`).then((response) => response.json()).then((data) => {
            if (data.suc) {
                data.dat.retailers.forEach(async (retailer, index) => {
                    salesPieData.push({
                        x: retailer.name,
                        y: retailer.amount
                    })
                    if (index + 1 === data.dat.retailers.length) this.forceUpdate();
                });
            } else {
                notification['error']({
                    message: data.msg
                });
            }
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    fetchRetailersAm = () => {
        fetch(`${config.api.url}/sales/getbyamazon/`).then((response) => response.json()).then((data) => {
            if (data.suc) {
                data.dat.retailers.forEach(async (retailer, index) => {
                    AmPieData.push({
                        x: retailer.name,
                        y: retailer.amount
                    })
                    if (index + 1 === data.dat.retailers.length) this.forceUpdate();
                });
            } else {
                notification['error']({
                    message: data.msg
                });
            }
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    fetchRetailersBb = () => {
        fetch(`${config.api.url}/sales/getbybestbuy/`).then((response) => response.json()).then((data) => {
            if (data.suc) {
                data.dat.retailers.forEach(async (retailer, index) => {
                    BbPieData.push({
                        x: retailer.name,
                        y: retailer.amount
                    })
                    if (index + 1 === data.dat.retailers.length) this.forceUpdate();
                });
            } else {
                notification['error']({
                    message: data.msg
                });
            }
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    fetchRetailersOt = () => {
        fetch(`${config.api.url}/sales/getbyothers/`).then((response) => response.json()).then((data) => {
            if (data.suc) {
                data.dat.retailers.forEach(async (retailer, index) => {
                    OtPieData.push({
                        x: retailer.name,
                        y: retailer.amount
                    })
                    if (index + 1 === data.dat.retailers.length) this.forceUpdate();
                });
            } else {
                notification['error']({
                    message: data.msg
                });
            }
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    fetchProbyAm = () => {
        fetch(`${config.api.url}/sales/getprobyam/`).then((response) => response.json()).then((data) => {
            if (data.suc) {
                this.setState({amProData: data.dat.products})
            } else {
                notification['error']({
                    message: data.msg
                });
            }
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    fetchProbyBb = () => {
        fetch(`${config.api.url}/sales/getprobybb/`).then((response) => response.json()).then((data) => {
            if (data.suc) {
                this.setState({bbProData: data.dat.products})
            } else {
                notification['error']({
                    message: data.msg
                });
            }
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    fetchProbyOt = () => {
        fetch(`${config.api.url}/sales/getprobyot/`).then((response) => response.json()).then((data) => {
            if (data.suc) {
                this.setState({otProData: data.dat.products})
            } else {
                notification['error']({
                    message: data.msg
                });
            }
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    onDateChange = (e) => {
        var diff = e[1].diff(e[0], 'days') + 1;
        beginDay = e[0];
        visitData = [];
        saleData = [];
        for (let i = 0; i < diff; i += 1) {
            saleData.push({
                x: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format('YYYY-MM-DD'),
                y: 100,
            });
        }

        for (let i = 0; i < diff; i += 1) {
            visitData.push({
                x: moment(new Date(beginDay + 1000 * 60 * 60 * 24 * i)).format('YYYY-MM-DD'),
                y: 1,
            });
        }

        setTimeout(() => {
            this.fetchSales();
            this.fetchViews();
        }, 500);

    }

    columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: text => text
        },
        {
            title: 'Actions',
            dataIndex: 'slug',
            key: 'slug',
            render: text => <a target='_blank' href={`https://deals.oscod.dev/product/${text}`}>View</a>
        },
    ];

    render = () => {
        return (
            <>
                <Row gutter={32}>
                    <Col span={6}>
                        <ChartCard title={<b>Site Visits Today</b>}
                            style={{ borderRadius: 10 }}
                            total={numeral(this.state.todayVisits).format('0,0')}
                            contentHeight={150}>
                            <NumberInfo
                                subTitle={<span><b>All Time Visits</b></span>}
                                total={numeral(this.state.totalVisits).format('0,0')}
                            />
                            <br />
                            <MiniBar line height={50} data={visitData} />
                        </ChartCard>
                    </Col>
                    <Col span={6}>
                        <ChartCard title={<b>Product Inquiries Today</b>}
                            style={{ borderRadius: 10 }}
                            total={'$ ' + numeral(this.state.todayInquiries).format('0,0')}
                            contentHeight={150}>
                            <NumberInfo
                                subTitle={<span><b>All Time Inquiries</b></span>}
                                total={'$ ' + numeral(this.state.totalInquiries).format('0,0')}
                            />
                            <br />
                            <MiniArea line height={50} data={inquiryData} />
                        </ChartCard>
                    </Col>
                    <Col span={6}>
                        <ChartCard
                            style={{ borderRadius: 10 }}
                            title={<b>Sales Today</b>}
                            total={<span dangerouslySetInnerHTML={{ __html: '$ ' + numeral(this.state.todaySales).format('0,0') }} />}
                            contentHeight={150}
                        >
                            <NumberInfo
                                subTitle={<span><b>All Time Sales</b></span>}
                                total={<span dangerouslySetInnerHTML={{ __html: '$ ' + numeral(this.state.totalSales).format('0,0') }} />}
                            />
                            <br />
                            <MiniBar line height={50} data={saleData} />
                        </ChartCard>
                    </Col>
                    <Col span={6}>
                        <ChartCard title={<b>New Signups Today</b>}
                            style={{ borderRadius: 10 }}
                            total={numeral(this.state.todayUsers).format('0,0')}
                            contentHeight={150}>
                            <NumberInfo
                                subTitle={<span><b>All Time Users</b></span>}
                                total={numeral(this.state.totalUsers).format('0,0')}
                            />
                            <br />
                            <MiniBar line height={50} data={userData} />
                        </ChartCard>
                    </Col>
                    <Col span={6}>
                    </Col>
                </Row>
                <br />
                <Row gutter={32}>
                    <Col span={12}>
                        <Tabs tabBarExtraContent={<Row>
                            <b>Top Selling Products</b>
                        </Row>} style={{ background: 'white', minHeight: '100%', padding: '20px 30px 20px 30px', borderRadius: 10 }} defaultActiveKey="1">
                            <TabPane style={{maxHeight: '1px'}} tab={<b>Amazon</b>} key="1">
                                <Table size='small' style={{maxHeight: '100%'}} columns={this.columns} dataSource={this.state.amProData} />
                                {/* <Bar height={300} data={saleData} /> */}
                            </TabPane>
                            <TabPane style={{maxHeight: '1px'}} tab={<b>Best Buy</b>} key="2">
                                <Table size='small' style={{maxHeight: '100%'}} columns={this.columns} dataSource={this.state.bbProData} />
                                {/* <Bar height={300} data={visitData} /> */}
                            </TabPane>
                            <TabPane style={{maxHeight: '1px'}} tab={<b>Others</b>} key="3">
                                <Table size='small' style={{maxHeight: '100%'}} columns={this.columns} dataSource={this.state.otProData} />
                                {/* <Bar height={300} data={visitData} /> */}
                            </TabPane>
                        </Tabs>
                    </Col>
                    <Col span={12}>
                        <ChartCard title={<b>Sales by Retailers</b>}
                            style={{ borderRadius: 10 }}
                            contentHeight={340}>
                            <Row justify='center' gutter={32}>
                                <Col span={8}>
                                    <Pie
                                        colors={'rgba(38, 210 , 110, 0.5)'}
                                        total={() => (
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: '$ ' + Math.floor(AmPieData.reduce((pre, now) => now.y + pre, 0)) + '<br/>Amazon',
                                                }}
                                            />
                                        )}
                                        data={AmPieData}
                                        valueFormat={val => <span dangerouslySetInnerHTML={{ __html: '$' + numeral(val) }} />}
                                        height={330}
                                    />,
                            </Col>
                                <Col span={8}>
                                    <Pie
                                        colors={'rgba(38, 230 , 216, 0.5)'}
                                        total={() => (
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: '$ ' + Math.floor(BbPieData.reduce((pre, now) => now.y + pre, 0)) + '<br/>Best Buy',
                                                }}
                                            />
                                        )}
                                        data={BbPieData}
                                        valueFormat={val => <span dangerouslySetInnerHTML={{ __html: '$' + numeral(val) }} />}
                                        height={330}
                                    />,
                            </Col>
                                <Col span={8}>
                                    <Pie
                                        total={() => (
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: '$ ' + Math.floor(OtPieData.reduce((pre, now) => now.y + pre, 0)) + '<br/>Others',
                                                }}
                                            />
                                        )}
                                        data={OtPieData}
                                        valueFormat={val => <span dangerouslySetInnerHTML={{ __html: '$' + numeral(val) }} />}
                                        height={330}
                                    />,
                            </Col>
                            </Row>
                        </ChartCard>
                    </Col>
                </Row>
                <br />
                <Row gutter={32}>
                    <Col span={24}>
                        <Tabs tabBarExtraContent={<Row>
                            <RangePicker format={'YYYY-MM-DD'} onChange={this.onDateChange} defaultValue={[beginDay, moment(new Date(beginDay + 1000 * 60 * 60 * 24 * 7))]} />
                        </Row>} style={{ background: 'white', padding: '20px 30px 20px 30px', borderRadius: 10 }} defaultActiveKey="1">
                            <TabPane tab={<b>Sales</b>} key="1">
                                <Bar height={300} data={saleData} />
                            </TabPane>
                            <TabPane tab={<b>Visits</b>} key="2">
                                <Bar height={300} data={visitData} />
                            </TabPane>
                        </Tabs>
                    </Col>
                    {/* <Col span={6}>
                        <ChartCard title={<b>Sales by Retailers</b>}
                            style={{ borderRadius: 10 }}
                            contentHeight={340}>
                            <br />
                            <Pie
                                title={<b>Total Sales</b>}
                                total={() => (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: '$ ' + Math.floor(salesPieData.reduce((pre, now) => now.y + pre, 0)),
                                        }}
                                    />
                                )}
                                data={salesPieData}
                                valueFormat={val => <span dangerouslySetInnerHTML={{ __html: '$' + numeral(val) }} />}
                                height={330}
                            />,
                        </ChartCard>
                    </Col> */}
                </Row>
            </>
        );
    }
}

{/* <Col span={6}>
    <ChartCard title="Sales by Retailers"
        style={{ borderRadius: 10 }}
        contentHeight={195}>
        <br />
        <Pie
            title="Sales by Retailer"
            total={() => (
                <span
                    dangerouslySetInnerHTML={{
                        __html: 'Rs. ' + Math.floor(salesPieData.reduce((pre, now) => now.y + pre, 0)),
                    }}
                />
            )}
            data={salesPieData}
            valueFormat={val => <span dangerouslySetInnerHTML={{ __html: 'Rs.' + numeral(val) }} />}
            height={200}
        />,
    </ChartCard>
</Col> */}
